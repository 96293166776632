.folder {
  &__head {
    margin-bottom: 60px;
    @media(max-width: 991px) {
      margin-bottom: 50px;
    }
    h1 {
      padding-left: 14.28%;
      @media(max-width: 991px) {
        @include font-size(34);
        line-height: 1.18;
        letter-spacing: 0.12px;
        padding: 0;
      }
    }
    .breadcrumbs {
      margin-top: -32px;
      margin-bottom: 36px;
      @media(max-width: 991px) {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
}
