.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  @media (max-width: 992px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

// CORE ELEMENTS

// Brand
$brandYellow1: #fff5c5;
$brandYellow2: #ffe66f;
$brandYellow3: #ffdd00;

$brandBrown1: #e8e0da;
$brandBrown2: #c5b3a3;
$brandBrown3: #897d72;

$brandGreen1: #cdd9d4;
$brandGreen2: #829f93;
$brandGreen3: #5b6f67;

$brandWheat1: #f8efe6;
$brandWheat2: #efd7c2;
$brandWheat3: #bfac9b;

$brandBlue1: #dee8ee;
$brandBlue2: #acc5d5;
$brandBlue3: #788995;

// Brand dark
$brandBrownDark: #383735;
$brandGreenDark: #36453f;
$brandWheatDark: #534a42;
$brandBlueDark: #3a4349;

// Text
$textDark1: #3b3b3b;
$textDark2: #2b2b2c;
$textDark3: #3b3b3b;

$gray1: #b0b0b4;
$gray2: #393b45;
$gray3: #2d2e36;

$turquoise: #22B3D2;


// UI colors (redesign 2023)
$gray-800: #373737;
$gray-600: #676767;
$gray-400: #878787;
$gray-200: #DCDCDC;
$gray-100: #EFEFEF;
$gray-50: #F7F7F7;
$white: #FFFFFF;

// Glava Isolasjon Colors (redesign 2023)
$glava-yellow: #FFDD00; // same as $brandYellow3
$glava-black: #1B1B1B;
$glava-yellow-600: #FFEB66;
$glava-green-200: #E0E7E4;
$glava-green-100: #EFF3F1;

// Weber Colors (redesign 2023)
$weber-yellow: #FFEB00;
$weber-black: #131213;
$weber-black-50: #F3F3F3;
$weber-anthracite-500: #3A3C44;
$weber-anthracite-700: #23242B;

// Gyproc Colors (redesign 2023)
$gyproc-blue: #005EB8;
$gyproc-black: #23242B;
$gyproc-gray: #AAAAAA;
$gyproc-blue-750: #4086CA;
$gyproc-aqua-300: #D5E2EA;
$gyproc-sand-300: #F7EBE0;

body {
  color: $glava-black;

  &.weber {
    color: $weber-black;
  }

  &.gyproc {
    color: $gyproc-black;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit;
  }
}

a{
  &:hover{
    color: inherit;
  }
}

*[data-portal-component-type="part"] p a,
*[data-portal-component-type="part"] ol li a,
*[data-portal-component-type="part"] ul:not(.dropdown-menu) li a,
*[data-portal-component-type="part"] table a,
*[data-portal-component-type="layout"] p a,
*[data-portal-component-type="layout"] ol li a,
*[data-portal-component-type="layout"] ul:not(.dropdown-menu) li a,
*[data-portal-component-type="layout"] table a {
  border-bottom: 2px solid #6E6E6E;
  text-decoration: none;

  @include site(gyproc){
    border-bottom-color: $gyproc-blue;
  }

  &:hover{
    border-bottom: 2px solid $glava-black;
    color: inherit;
    text-decoration: none;

    @include site(weber){
      border-bottom-color: $weber-black;
    }

    @include site(gyproc){
      border-bottom-color: $gyproc-gray;
    }
  }
}

img {
  max-width: 100%;
}

h1 {
  @include font-size(64);
  color: $textDark2;
}

.subtitle {
  @include font-size(24);
  color: $gray2;
}

.btn:focus,
.btn.focus{
  box-shadow: none;
}

.btn{
  &.disabled, &[disabled]{
    pointer-events: none;
    background-color: $gray-100 !important;
    color: $gray-600 !important;

    &::after{
      // Icon color
      filter: saturate(0%) brightness(30%);
    }
  }
}

.breadcrumbs {
  margin-bottom: 40px;
  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
  a {
    @include font-size(14);
    color: #333333;
    line-height: 24px;
    @media (max-width: 992px) {
      @include font-size(12);
      line-height: 16px;
    }
    &::before {
      @include background-image("icons/arrow-right.svg", 5, 8);
      margin: 0 10px 0 9px;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
  }

  // New breadcrumbs style
  &--slash{
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 992px) {
      margin-bottom: 12px;
    }

    li{
      list-style: none;

      &:hover{
        .item{
          span{
            color: #121212;
          }
        }
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &:last-child{
        .item{
          span{
            font-weight: 600;
            color: #121212;
          }
        }
      }

      &::before {
        content: "/";
        margin: 0 8px 0 7px;
        color: transparentize(#121212, 0.3);
        font-weight: 500;
        background-image: none;
        width: auto;
        height: auto;
      }

      .item{
        border: none !important;
        text-decoration: none;

        span{
          color: transparentize(#121212, 0.3);
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          letter-spacing: 0.005em;

          @media (max-width: 992px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.tags {

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar{
    height: 5px;
    opacity: .4;
  }

  h2 {
    @include font-size(16);
    line-height: 24px;
    margin-bottom: 20px;
    color: #2a2a2a;
    @media (max-width: 992px) {
      @include font-size(13px);
      line-height: 20px;
      margin-bottom: 12px;
    }
  }

  &__wrapper {
    width: 100%;
    // min-height: 62px;
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
    &::-moz-scrollbars {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
    &::-o-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
    &::-google-ms-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
    &::-khtml-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
    scrollbar-width: none!important;
    -ms-overflow-style: -ms-autohiding-scrollbar!important;

    &--content {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        flex-wrap: nowrap;
      }
      .tag {
        text-decoration: none;
        background-color: #dee8ee;
        color: $brandBlueDark;
        display: inline-block;
        padding: 14px 28px;
        width: auto;
        white-space: nowrap;
        margin-bottom: 20px;
        @include font-size(18);
        line-height: 24px;
        font-weight: 500;
        margin-right: 20px;
        @media (max-width: 992px) {
          padding: 14px 20px;
          @include font-size(16);
          line-height: 20px;
          margin-right: 12px;
          margin-bottom: 12px;
        }
        &.yellow {
          background: #f7f0d2;
          color: #424033;
        }
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
        supported by Chrome, Opera and Firefox */
        &.disableBtn{
          pointer-events: none;
        }
      }
    }
  }
}

select,
textarea,
input {
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  padding: 20px 32px;
  font-family: $font-medium;
  @include font-size(17);
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
}

select {
  option {
    max-height: calc(1.2em + 24px);
    height: calc(1.2em + 24px);
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
}

.sidebar__menu {
  *{
    word-break: break-all;
  }
  @media (max-width: 992px) {
    display: none;
  }
  h6 {
    @include font-size(20);
    line-height: 36px;
    color: #2a2a2a;
  }
  a,
  span {
    display: block;
    color: $brandBlue3;
    text-decoration: underline;
    font-family: $font-regular;
    font-weight: 500;
    font-style: normal;
    @include font-size(22);
    line-height: 40px;
  }
}

.back--button {
  display: none;
  margin-bottom: 30px;
  padding-left: 20px;
  position: relative;
  &:before {
    @include background-image("icons/arrow-back.svg", 5.06, 8.71);
    position: absolute;
    left: 2px;
    top: 9px;
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;
    color: #333333;
  }
  @media (max-width: 992px) {
    display: block;
    margin-bottom: 15px;
  }
}

.back--button-big {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.12px;
  color: #2a2a2a;
  &::before {
    @include background-image("icons/arrow-hero.svg", 35, 25);
    transform: rotate(-180deg);
    margin-right: 20px;
    position: relative;
    @media (max-width: 992px) {
      top: 5px;
    }
  }
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 44px;
  }
  &:hover {
    color: #2a2a2a;
  }
}

.container__404 {
  @media (min-width: 1086px) {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    > .row {
      height: 100%;
    }
  }
}

.page__404 {
  background-image: url("../../assets/images/404.png");
  background-repeat: no-repeat;
  background-position: center left 80%;
  background-size: 850px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transition: all 250ms;

  @media (max-width: 1270px) {
    background-size: 750px;
    background-position: center left 80%;
  }
  @media (max-width: 1085px) {
    height: auto;
    background: none !important;
    text-align: center;
    &--text {
      margin: 0 auto;
    }
  }
  @media (max-height: 680px) {
    background-size: 700px;
    background-position: center left 65%;
  }
  @media (max-height: 630px) {
    background-size: auto 90%;

    min-height: 560px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 88px;
    letter-spacing: -1.26px;
    color: #000000;
    margin: -50px 0 35px 0;
    @media (max-width: 992px) {
      margin: 0 0 10px 0;
      font-size: 44px;
      line-height: 48px;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #333333;
    margin-bottom: 45px;
    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
  a {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.12px;
    color: #2a2a2a;
    &::before {
      @include background-image("icons/arrow-hero.svg", 35, 25);
      transform: rotate(-180deg);
      margin-right: 20px;
    }
    @media (max-width: 992px) {
      font-size: 24px;
      line-height: 44px;
    }
  }

  .image__404 {
    display: none;
    @media (max-width: 1085px) {
      display: block;
      margin: 35px auto 0 auto;
      figure {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.download {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.24px;
  color: #333333;
  display: flex;
  a {
    color: #4c80a4;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
    padding-left: 25px;
    position: relative;
    white-space: nowrap;
    &:before {
      @include background-image("icons/PDF-icon.svg", 16, 22);
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  span {
    color: #333333;
  }
  small {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    margin-left: 5px;
    color: #333333;
    white-space: nowrap;
  }
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  color: #333333;
  &.btn-yellow {
    background-color: $glava-yellow-600;
    border-radius: 24px;
    padding: 12px 40px;

    @include site(weber){
      background-color: $weber-yellow;
    }

    @include site(gyproc){
      background-color: $gyproc-blue;
      color: $white;
    }

    &:hover, &:focus-visible{
      box-shadow:0px 0px 0px 2px $glava-yellow;

      @include site(weber){
        box-shadow:0px 0px 0px 2px #D7C204;
      }

      @include site(gyproc){
        box-shadow:0px 0px 0px 2px $gyproc-blue-750;
      }
    }
  }
  &.btn-grey {
    background-color: #f5f5f5;
    border-radius: 24px;
    padding: 12px 40px;
  }

  &.btn-arrow{
    height: 56px;
    max-width: 205px;
    width: 100%;
    padding: 19px 32px;
    border-radius: 50px;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 991px){
      padding: 14px 20px;
      max-width: 145px;
      height: 44px;
      font-size: 13px;
    }

    // &::after{
    //   position: absolute;
    //   height: 10px;
    //   width: 12px;
    //   right: 23px;
    //   @include background-image("./icons/arrowItemMenuDropDown-Black.svg", 20.13px, 27.83px);
    // }
    i{
      font-style: normal;
    }
    span{
      height: 10px;
      width: 12px;
      background: url("../images/./icons/arrowItemMenuDropDown-Black.svg") no-repeat center center;
      background-size: 100%;
      display: inline-block;
    }
  }

  &.btn-default {
    background-color: #637886;
    border-radius: 50px;
    padding: 19px 32px;
    color: #FFFFFF;
    i{
      color: #ffffff;
    }
    &.btn-arrow{
      // &::after{
      //   @include background-image("./icons/arrowItemMenuDropDown.svg", 20.13px, 27.83px);
      // }
      span{
        background: url("../images/./icons/arrowItemMenuDropDown.svg") no-repeat center center;
        background-size: 100%;
      }
    }
  }
  &--arrow-sm {
    // styled only for mobile, desktop cam be added
    @include font-size(16);
    line-height: 1.31;
    letter-spacing: 0.01em;
    color: #ffff;
    padding: 12px 40px 11px 20px;
    border: none;
    border-radius: 100px;
    background: url("../images/./icons/arrow-right-16-w.svg")  no-repeat center right 18px/16px #3A3C45;
    width: fit-content;
  }
  &.btn--dark {
    color: #fff;
    background: #3A3C45;
    border: none;
  }
  &--round {
    border-radius: 100px;
    padding: 12px 40px;
    width: fit-content;
    @media (max-width: 991px) {
      border-radius: 50px;
      padding: 12px 30px 11px;
    }
  }
}
.link-arrow-left-lg {
  @include font-size(21);
  line-height: 1.33;
  letter-spacing: -0.005em;
  font-weight: 600;
  padding: 0 0 0 32px;
  background-image: url("../images/icons/arrow-right-24.svg");
  background-repeat: no-repeat;
  background-position: top 2px left;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
.page__title {
  font-style: normal;
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: 0.12px;
  color: #333333;
}
h2 {
  &.page__title {
    font-size: 48px;
    line-height: 52px;
  }
}
h3 {
  &.page__title {
    font-size: 36px;
    line-height: 44px;
    color: #2a2a2a;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
.separator {
  border: 0;
  border-bottom: 2px solid #3a3c45;
}

.filter__tags {
  margin-bottom: 40px;
  margin-top: -55px;
  min-height: 20px;
  @media (max-width: 992px) {
    margin-top: 0;
    min-height: 0;
  }
  &--item {
    height: 60px;
    background-color: #f6f6f6;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.24px;
    color: #333333;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    padding: 14px 56px 14px 20px;
    position: relative;
    .removeIcon{
      background: red;
      display: inline-block;
      height: 16px;
      width: 0;
      &:after {
        right: 20px;
        position: absolute;
        @include background-image("icons/icon-plus.svg", 16, 16);
        transform: rotate(45deg);
        cursor: pointer;
      }
    }
    @media (max-width: 992px) {
      margin-top: 0;
      font-size: 16px;
      height: 50px;
      margin-right: 10px;
    }
  }
}

.btnShowMore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  @media (max-width: 1200px) {
    margin-top: 45px;
  }
  a {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    @include font-size(18);
    line-height: 28px;
    letter-spacing: 0.24px;
    color: #181818;
    position: relative;
    padding-left: 30px;
    @media (max-width: 992px) {
    }
    &:before {
      @include background-image("icons/showMoreIcon.svg", 16, 16);
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -7px;
    }
  }
  &.active {
    a {
      &:before {
        @include background-image("icons/showLessIcon.svg", 16, 16);
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
      }
    }
  }
}

.page__title {
  font-style: normal;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: 0.12px;
  color: #333333;
}

.contactUs__person--item {
  .employee__item--position {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.24px;
    color: #3a4349;
    display: none;
    @media (max-width: 992px) {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 17px;
      margin-top: 10px;
    }
  }
  .employee__item--email,
  .employee__item--phone {
    display: none;
    margin-bottom: 0px;
    line-height: 100%;
    a {
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.24px;
      color: #3a4349;
      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
  .employee__item--phone {
    margin-top: 18px;
    margin-bottom: 0;
    @media (max-width: 992px) {
      margin-top: 10px;
    }
  }
  &-button {
    display: block;
    line-height: 12px;
    margin-top: 10px;
    font-size: 24px;
    &:hover {
      text-decoration: none;
      color: #000000;
    }
    &:focus {
      outline: 0;
    }
  }
}


.download-link {
  color: #4c80a4;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
  position: relative;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  &:before {
    @include background-image("icons/PDF-icon.svg", 16, 22);
    top: 0;
    left: 0;
    position: absolute;
  }
  &+small {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    margin-left: 5px;
    color: #333333;
    @media (max-width: 992px) {
      flex-basis: 10px;
      display: block;
    }
  }
}

.import-wrapper {
  display: flex;
  flex-direction: row;

  @media(max-width: 667px) {
    flex-direction: column;
  }
}


.importPage{
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  p{
    margin: 30px 0 50px 0;
  }
  @media(max-width: 768px){
    h1{
      font-size: 28px;
    }
  }
}

.btn__import{
  background-color: #000000;
  color: #FFFFFF;
  height: 70px;
  border-radius: 0;
  font-size: 22px;
  margin: 0 auto;
  display: block;
  min-width: 280px;
  &:hover{
    color: #CCCCCC;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.import__status{
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: left;
  border: 1px solid #000000;
  @media(max-width: 991px){
    margin: 50px 10px;
  }
  h2{
    margin: 0 0 20px 0;
    font-size: 22px;
  }
  .bar{
    margin-bottom: 15px;
    display: flex;
    align-items: flex-end;
    display: block;
    label{
      flex-basis: 100%;
    }
    .progress{
      flex-basis: 100%;
      margin-left: 0;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app-cookie-line{
  z-index: 15!important;
}

.app-cookie-line.mod-light *{
  color: #222222!important;
}

.app-cookie-line.mod-dark *{
  color: #ffffff!important;
}

.firecase-beregningsprogram {
  @media(max-width: 1308px){height: 1400px!important;}
  @media(max-width: 991px){height: 970px!important;}
  @media(max-width: 767px){height: 1400px!important;}
  @media(max-width: 455px){height: 2020px!important;}
}


.documentation-layout{
  margin-bottom: 125px;
  .breadcrumbs{
    display: none;
    @media(max-width: 991px){
      display: block;
    }
  }
  h2{
    font-family: $font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 40px;
    color: #2a2a2a;
    @media(max-width: 991px){
      font-size: 32px;
      line-height: 42px;
    }
  }
  .more-link{
    font-family: $font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #898A8C;
    display: inline-block;
    cursor: pointer;
    @media(max-width: 991px){
      font-size: 13px;
    }
  }
}

#documents{
  width: 100%;
  @media(max-width: 991px){
    padding: 0;
  }
  .documents__wrapper{
    .document-list{
      margin-top: 80px;
      &:first-of-type{
        margin-top: 0;
      }

      @media(max-width: 991px){
        margin-top: 40px;
        &:first-of-type{
          margin-top: 0;
        }
      }
      h3{
        margin-bottom: 24px;
        font-family: $font-regular;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.12px;
        color: #2A2A2A;
        @media(max-width: 991px){
          font-size: 24px;
        }
      }
      &__item{
        height: 84px;
        display: flex;
        align-items: center;
        border: 1px solid #D2D3D5;
        border-bottom: 0;
        padding: 0 28px;

        @media(max-width: 1360px){
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        @media(max-width: 991px){
          flex-wrap: wrap;
          padding: 16px;
          height: auto;
        }

        &:last-of-type{
          border: 1px solid #D2D3D5;
          margin-bottom: 24px;
        }
        img{
          width: 20px;
          height: auto;
          margin-right: 28px;
          @media(max-width: 991px){
            display: none;
          }
        }
        &--name{
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.24px;
          color: #2B2B2C;
          flex-basis: calc(60% - 30px);
          margin-right: 30px;
          word-break: break-word;
          @media(max-width: 1200px){
            font-size: 16px;
          }
          @media(max-width: 1024px){
            flex-basis: 50%;
          }
          @media(max-width: 991px){
            font-size: 13px;
          }
          @media(max-width: 991px){
            flex-basis: 100%;
            margin-right: 0;
          }
        }
        &--file{
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          letter-spacing: 0.24px;
          color: #2B2B2C;
          margin: 0 24px;
          margin-left: auto;
          min-width: 110px;
          @media(max-width: 991px){
            font-size: 13px;
            flex-basis: 40%;
            text-align: right;
            margin: 0;
          }
          @media(max-width: 576px){
            flex-basis: 100%;
            text-align: left;
          }
        }
        &--actions{
          flex-basis: 20%;
          display: flex;
          justify-content: flex-end;

          @media(max-width: 991px){
            flex-basis: 100%;
            display: flex;
            margin-top: 8px;
            justify-content: space-between;
          }
          a{
            width: 108px;
            height: 36px;
            border-radius: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.16px;
            color: #333333;

            @media(max-width: 991px){
              width: 100%;
              font-size: 13px;
            }

            &.del{
              background: #F5F5F5;
            }
            &.last{
              background: #FFFFFF;
              border: 1px solid $glava-black;

              &:hover{
                text-decoration: none;
                box-shadow:0px 0px 0px 1px $glava-black;

                @include site(weber){
                  box-shadow:0px 0px 0px 1px $weber-black;
                }

                @include site(gyproc){
                  box-shadow:0px 0px 0px 1px $gyproc-black;
                }
              }
            }
            &.copy{
              width: 226px;
              height: 36px;
              background: #333333;
              color: #FFFFFF;
              border-radius: 0;
              text-decoration: none;
              &:hover{
                text-decoration: none;
              }
              @media(max-width: 991px){
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.sidebarFilters{
  @media(max-width: 991px){
    margin-bottom: 50px;
  }
  h5{
    font-family: $font-regular;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }
}

.filterTree{

  &__item{
    &.filterTree-group{
      .checkbox-group{
        label{
          &:after{
            @include background-image("icons/arrow-down-select.svg", 12, 8);
            margin-left: 5px;
            position: relative;
            top: -2px;
          }
        }
        input[type=checkbox]{
          &:checked{
            &+.check{
              &+label{
                &:after{
                  @include background-image("icons/arrow-down-select.svg", 12, 8);
                  margin-left: 5px;
                  transform: rotate(180deg);
                  position: relative;
                  top: -2px;
                }
              }
            }
          }
        }
      }
    }
    .filterTree__list{
      border-left: 1px solid #2a2a2d;
      padding: 0 0 0 23px;
      margin: 24px 0 24px 12px;
      display: none;
      .checkbox-group{
        label{
          @media(max-width: 1400px){
            font-size: 15px;
            line-height: 20px;
          }
          &:after{
            display: none !important;
          }
        }

      }
    }
  }
}
.slick-slide > a {
  &:hover {
    text-decoration: none;
  }
}
// Utilities
.md-125{
  margin-bottom: 125px;
}

.mt-80{
  margin-top: 80px;
}
.sm-only {
  @media(min-width: 992px){
    display: none;
  }
}
.mb-80{
  margin-bottom: 80px;
}
.hidden {
  visibility: hidden;
}
.body-block {
  overflow: hidden;
  pointer-events: none;
}

.body-scroll-block{
  overflow: hidden;
}

.div-line {
  border-bottom: 3px solid $glava-black;
  width: 80px;
  margin-bottom: 37px;

  @media(max-width: 991px) {
    width: 72px;
    border-width: 2px;
    margin-bottom: 16px;
  }

  @include site(weber){
    border-bottom-color: $weber-yellow;
  }

  @include site(gyproc){
    border-bottom-color: $gyproc-black;
  }
}
.div-line-white {
  border-bottom: 3px solid #fff;
  width: 80px;
  margin-bottom: 37px;
  @media(max-width: 991px) {
    width: 72px;
    border-width: 2px;
    margin-bottom: 16px;
  }
}
.section-title {
  @include font-size(32);
  line-height: 1.25;
  margin-bottom: 40px;
  letter-spacing: -0.005em;
  @media(max-width: 991px) {
    @include font-size(24);
    line-height: 1.167;
    margin-bottom: 20px;
  }
}
.mob-order--1 {
  @media(max-width: 991px) {
    order: -1;
  }
}
.hide-mobile {
  @media(max-width: 991px) {
    display: none!important;
  }
}
.mob-only {
  @media(min-width: 992px) {
    display: none!important;
  }
}

.row-lg-inverted {
  flex-direction: row-reverse;
}


[data-portal-component-type="part"] {
  overflow: hidden;

  &:has(.glava-calculator) {
    overflow: visible;
  }
}
