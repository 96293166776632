.pagination{
  margin-bottom: 80px;

  @media(max-width: 992px){
    margin-bottom: 40px;
  }

  nav{
    display: flex;
    align-items: center;
    justify-content: center;

    ul{
      display: flex;
      flex-direction: row;
      list-style: none;

      li{
        display: flex;
        align-items: center;
        margin-right: 8px;

        @media(max-width: 992px){
          margin-right: 6px;
        }

        &:last-child{
          margin-right: 0;
        }

        button{
          min-width: 28px;
          height: 28px;
          padding: 0 5px;
          background-color: transparent;
          border: none;
          color: transparentize(#121212, 0.7);
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;

          @media(max-width: 992px){
            min-width: 20px;
            height: 20px;
            font-size: 13px;
            line-height: 20px;
          }

          &:hover{
            color: #121212;
          }

          &.active{
            background-color: #3A3C45;
            color: white;
          }
        }

        &.arrow{
          button{
            display: flex;
            align-items: center;
            padding: 0;

            .arrow-icon{
              display: block;
              width: 10px;
              height: 10px;
              background-image: url("../images/./icons/arrow-back.svg");
              background-repeat: no-repeat;

              @media(max-width: 992px){
                width: 8px;
                height: 8px;
              }
            }
          }

          &--prev{
            button{
              justify-content: flex-end;
              margin-right: 3px;
            }
          }

          &--next{
            button{
              justify-content: flex-start;
              margin-left: 3px;

              .arrow-icon{
                -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
              }
            }
          }
        }
      }
    }
  }
}
