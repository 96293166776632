.solution-page {
  &__header {
    margin-bottom: 40px;
    h1 {
      font-size: 4rem;
      line-height: 1.125;
      margin-bottom: 64px;
      @media (max-width: 991px) {
        font-size: 2.125rem;
        line-height: 1.177;
        margin-bottom: 32px;
      }
    }
    .solution-page__contact{
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  &__img{
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }
    .img {
      @media (max-width: 991px) {
        border: 1px solid #E5E6E8;
        padding-top: 100%;
      }
    }
    figure{
      img{
        max-width: 100%;
        height: auto;
        @media (max-width: 991px) {
          max-height: 270px;
        }
      }
    }
    figcaption {
      color: #939399;
      font-size: .9375rem;
      line-height: 1.467;
      width: 100%;
      color: #939399;
      width: calc(87% - 15px);
      margin-top: 20px;
      margin-left: calc(13% + 15px);
      @media (max-width: 991px) {
        float: none;
        margin-left: 0;
        max-width: 100%;
        margin-bottom: 40px;
        margin-right: 0;
      }
    }
  }
  &__description{

    p {
      text-transform: uppercase;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        font-size: .75rem;
        line-height: 1.334;
        margin-bottom: 8px;
      }
    }
    .wrapper {
      padding: 32px 36px;
      min-height: 126px;
      border: 1px solid #E5E6E8;
      margin-bottom: 32px;
      width: 100%;

      display: flex;
      justify-content: center;
      align-content: center;

      @media (max-width: 991px) {
        padding: 16px 16px;
        margin-bottom: 0;
        min-height: unset;
        width: 91px;
      }
      &-brand {
        display: flex;
        width: 100%;
        @media (max-width: 991px) {
          justify-content: flex-start;
          width: auto;
        }
        img {
          width: auto;
          max-width: 100%;
          max-height: 100%;
          @media (max-width: 991px) {
            max-height: 20px;
            height: 20px;
          }
        }
        .wrapper {
          flex: 1;
          padding: 16px;
          min-height: 64px;
          height: 64px;
          @media (max-width: 991px) {
            width: auto;
            flex: unset;
            min-height: unset;
            height: auto;
            width: 91px;
            + .wrapper{
              border-left: none;
            }
          }
          @media (min-width: 992px) {
            + .wrapper{
              margin-left: 16px;
            }
            &:first-of-type:last-of-type {
              padding: 40px;
              height: 200px;
            }
            &:first-of-type:nth-last-of-type(2),
            &:nth-of-type(2):last-of-type {
              padding: 28px;
              height: 96px;
            }
          }
        }
      }
    }
  }
  &__contact {
    p {
      text-transform: uppercase;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        font-size: .75rem;
        line-height: 1.334;
        margin-bottom: 8px;
      }
    }
    .wrapper {
      padding: 32px 36px;
      border: 1px solid #E5E6E8;
      @media (max-width: 991px) {
        padding: 24px 24px 28px;
      }
    }
    .btn {
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.556;
      font-weight: 500;
      background-color: $glava-black;
      padding: 18px;
      border-radius: 0;
      width: 100%;
      justify-content: center;
      @media (max-width: 991px) {
        font-size: 1rem;
        line-height: 1.313;
        padding: 12px 20px;
        margin: 0 auto;
        width: max-content;
      }
      &:hover, &:focus-visible {
        color: #fff;
        box-shadow:0px 0px 0px 2px $gray-600;
      }
      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background:  center / contain no-repeat url("../images/./icons/arrow-right-white.svg");
        margin-left: 8px;
        @media (max-width: 991px) {
          width: 16px;
          height: 16px;
          margin-left: 6px;
          background-image: url("../images/./icons/arrowItemMenuDropDown.svg");
        }
      }

      @include site(weber){
        background-color: $weber-black;
      }

      @include site(gyproc){
        background-color: $gyproc-black;
      }
    }
  }
  &__content {
    .content-block {
      ul {
        list-style: none;
        li {
            &:before {
                content: "\2022";
                color: #f0ce29;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
      }
    }
    .deduction-table {
      .table-scroller{
        overflow: auto;
      }
      .deduction-table__header {
        h2 {
          @media (max-width: 991px) {
            font-size: 1.25rem;
          }
        }
      }
      .table-wrapper .table-scroller table {
        @media (max-width: 991px) {
          font-size: 1rem;
          .header-row {
            font-size: .875rem;
          }
        }
      }
    }
    .accordionMultipleMargin {
      margin-top: 40px;
      margin-bottom: 53px;
      @media (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 32px;
      }
      .accordionMultiple {
        &.employee {
          margin-top: 0px;
          margin-bottom: 0px;
          + .accordion--no-accordion {
            margin-top: 80px;
            @media (max-width: 991px) {
              margin-top: 40px;
            }
          }
          &:not(.accordion--no-accordion) + .accordion--no-accordion  {
            position: relative;
            &::before {
              top: -80px;
              content: '';
              border-top: 4px solid #393b45;
              width: 100%;
              display: block;
              position: absolute;
              @media (max-width: 991px) {
                top: -40px;
                border-top: 2px solid #393b45;
              }
            }
          }
        }
        &.accordion--no-accordion + .accordionMultiple:not(.accordion--no-accordion) {
          + .accordion--no-accordion {
            margin-top: 80px;
            @media (max-width: 991px) {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
  .accordion {
    &.employee {
      margin-top: 40px;
      margin-bottom: 53px;
      @media (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 32px;
      }
    }
    .row {
      @media (max-width: 991px) {
        margin: 0 !important;
      }
      .col-lg-11.offset-lg-1,
      .col-xl-5.col-lg-7.offset-lg-1,
      .col-xl-9.col-lg-9 {
        @media (max-width: 991px) {
          padding: 0 !important;
        }
      }
    }
  }
  .contact-box-mobile {
    margin-bottom: 62px;
    @media (min-width: 993px) {
      display: none;
    }
  }
}
.card-box {
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #E5E6E8;
  padding: 24px;
  display: block;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    padding: 12px 10px 10px;
    margin-bottom: 16px;
  }
  &:hover{
    text-decoration: none;
  }
  &__img {
    margin-bottom: 12px;
    @media (max-width: 991px) {
      margin-bottom: 4px;
    }
  }
  &__content {
    > *{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__title {
    font-size: 1.0625rem;
    line-height: 1.412;
    text-align: center;
    @media (max-width: 991px) {
      font-size: .75rem;
      line-height: 1.334;
      min-height: 2rem;
      margin-bottom: 16px;
    }
  }
  &-group {
    margin-right: -40px;
    margin-bottom: -40px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      margin-right: -16px;
      margin-bottom: -16px;
    }
    .card-box {
      flex: 0 0 calc(25% - 40px);
      max-width: calc(25% - 40px);
      margin-right: 40px;
      @media (max-width: 991px) {
        flex: 0 0 calc(50% - 16px);
        max-width: calc(50% - 16px);
        margin-right: 16px;
      }
    }
  }
}
.btn-contact {
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.556;
  font-weight: 500;
  background-color: #3A3C45;
  padding: 18px;
  border-radius: 0;
  width: 100%;
  justify-content: center;
  @media (max-width: 991px) {
    font-size: 1rem;
    line-height: 1.313;
    padding: 12px 20px;
  }
  &:hover {
    color: #fff;
  }
  &::after {
    content: '';
    width: 24px;
    height: 24px;
    background:  center / contain no-repeat url("../images/./icons/arrow-right-white.svg");
    margin-left: 8px;
    @media (max-width: 991px) {
      width: 16px;
      height: 16px;
      margin-left: 6px;
      background-image: url("../images/./icons/arrowItemMenuDropDown.svg");
    }
  }
}
