.searchResults__page {
  .searchbar__wrapper {
    margin-bottom: 78px;
  }
  .back--button-big {
    margin-bottom: 100px;
    display: inline-block;
  }
  .searchbar {
    margin-bottom: 10px;
  }
}

.searchResults {
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 125px;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #333333;
    small {
      margin-left: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.24px;
      color: #333333;
      opacity: 0.5;
    }
  }
  .searchResults__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d9dbe3;
    padding: 20px 0;
    align-items: center;

    &:first-of-type {
      border-top: 1px solid #d9dbe3;
      margin-top: 25px;
    }
    &:last-child {
      margin-bottom: 40px;
    }
    .searchResults__item--image {
      margin-right: 32px;
      flex-basis: 93px;
      img {
        max-width: 100%;
      }
      @media (max-width: 992px) {
        margin-right: 10px;
      }
    }
    .searchResults__item--title {
      flex-basis: calc(100% - 93px);
      h4 {
        a {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.24px;
          color: #333333;
        }
      }
    }

    &.searchResults__docs {
      justify-content: space-between;
      h4 {
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.24px;
          color: #333333;
        }
      }
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
