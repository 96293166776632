.featured-jobs {
  margin: 80px 0;
  @media (max-width: 991px) {
    margin: 50px 0;
  }
  h2 {
    @include font-size(28);
    line-height: 1.14;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      @include font-size(22);
      line-height: 1.45;
      margin-bottom: 24px;
    }
  }
  &__more-btn {
    margin-top: 40px;
    margin-bottom: 1px;
    // padding: 11px 29px;
    // border: 0.5px solid #3A3C45;
    // border-radius: 0;
    // width: fit-content;
    @media (max-width: 991px) {
      width: 100%;
      justify-content: center;
      // padding: 13px;
    }
  }
}
