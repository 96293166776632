.link-list {

  margin-bottom: 80px;
  @media(max-width: 991px){
    margin-bottom: 48px;
  }
  &__title {
    @include font-size(14);
    line-height: 1.715;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__wrapper {
    display: contents;
    &--hidden {
      .col-lg-4:nth-child(n + 7) {
        @media(max-width: 991px){
          display: none;
        }
      }
    }
  }
  &__item {
    border-top: 1px solid $glava-black;
    width: 100%;

    @include site(weber){
      border-top-color: $weber-black;
    }

    @include site(Gyproc){
      border-top-color: $gyproc-black;
    }

    @media(max-width: 991px){
      border-width: 1px;
    }
    a {
      @include font-size(21);
      line-height: 1.334;
      letter-spacing: -0.005em;
      text-decoration: none;
      position: relative;
      padding: 28px 44px 28px 24px;
      display: block;
      @media(max-width: 991px){
        @include font-size(18);
        padding: 22px 44px 22px 18px;
      }
      &:after {
        content: '';
        @include background-image("icons/arrowShort-right-24.svg", 24, 24);
        position: absolute;
        right: 16px;
        top: 30px;
        @media(max-width: 991px){
          right: 20px;
          top: 22px;
        }
      }
      &:hover {
        font-weight: 600;
        background-color: #E4EEF3;
        &:after {
          background-image: url("../images/icons/arrowTail-right-24.svg");
          right: 21px;
          @media(max-width: 991px){
            right: 20px;
          }
        }
      }
    }
  }
  &__btn {
    display: none;
    @media(max-width: 991px) {
      display: block;
      border-top: 1px solid #1B1A1A;
      padding-top: 48px;
      text-align: center;
      .btn {
        @include font-size(16);
        line-height: 1.313;
        letter-spacing: 0.01em;
        padding: 12px 20px 11px;
        border: 1px solid #3A3C45;
        border-radius: 22px;
        margin: 0 auto;
      }
    }
  }
  &--col-6 {
    .col-lg-4 {
      @media(min-width: 992px){
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        &:nth-child(odd) {
          margin-left: 8.33333%;
        }
        &:nth-child(2) {
          .link-list__item {
            border-top: 1px solid $glava-black;

            @include site(weber){
              border-color: $weber-black;
            }

            @include site(Gyproc){
              border-color: $gyproc-black;
            }
          }
        }
      }
      &:first-child {
        .link-list__item {
          border-top: 1px solid $glava-black;

          @include site(weber){
            border-top-color: $weber-black;
          }

          @include site(Gyproc){
            border-top-color: $gyproc-black;
          }
        }
      }
    }
    .link-list__item {
      font-weight: 600;
      border-bottom: 1px solid $glava-black;
      border-top: none;

      @include site(weber){
        border-bottom-color: $weber-black;
      }

      @include site(Gyproc){
        border-bottom-color: $gyproc-black;
      }

      a {
        @include font-size(20);
        line-height: 1.4;
        position: relative;
        @media(max-width: 991px){
          @include font-size(18);
          line-height: 1.334;
        }
      }
    }
    .link-list__title {
      @include font-size(28);
      line-height: 1.143;
      font-weight: 600;
      text-transform: none;
      margin-bottom: 28px;
      margin-left: 8.33333%;
      @media (max-width: 991px) {
        @include font-size(22);
        line-height: 1.273;
        margin-bottom: 31px;
        margin-left: 0;
      }
    }
    .link-list__btn {
      @media (max-width: 991px) {
        border: none;
      }
    }
  }

  &--secondary{
    padding: 90px 0 86px;
    background-color: $glava-green-200;

    @include site(weber){
      background-color: $weber-black-50;
    }

    @include site(gyproc){
      background-color: $gyproc-sand-300;
    }

    @media(max-width: 991px){
      padding: 52px 0 56px;
    }

    &.link-list--col-6{
      padding: 80px 0;

      @media(max-width: 991px){
        padding: 52px 0 56px;
      }
    }
  }
}
