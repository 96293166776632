.articleWrapper {
    &__header {
        .col-lg-4.text-right {
            padding-bottom: 80px;
            @media (max-width: 992px) {
                padding-bottom: 0px;
            }
        }
        .logo__partner {
            margin-right: 28px;
            margin-top: 15px;
            @media (max-width: 992px) {
                margin: 15px 15px 15px 0;
            }
        }
        &--buttonBack {
            margin-bottom: 57px;
            padding-left: 20px;
            position: relative;
            @media (max-width: 992px) {
                margin-bottom: 20px;
                padding-left: 15px;
            }
            &:before {
                @include background-image("icons/arrow-back.svg", 5.06, 8.71);
                position: absolute;
                left: 2px;
                top: 8px;
                @media (max-width: 992px) {
                    top: 9px;
                    left: 0;
                }
            }
            a,
            span {
                font-weight: 500;
                @include font-size(14);
                line-height: 24px;
                letter-spacing: 0.24px;
                color: #333333;
                display: inline-block;
                text-decoration: none;
                @media (max-width: 992px) {
                    @include font-size(12);
                    line-height: 16px;
                }
            }
        }
        &--title {
            font-weight: 600;
            @include font-size(80);
            line-height: 88px;
            color: #121212;
            opacity: 0.9;
            margin-bottom: 100px;
            @media (max-width: 992px) {
                @include font-size(25);
                line-height: 32px;
                margin-bottom: 20px;
            }
        }
    }
    &__content {
        margin-bottom: 85px;
        @media (max-width: 992px) {
            margin-bottom: 43px;
            overflow: hidden;
        }
        .container {
            @media (max-width: 992px) {
                padding: 0;
            }
            .row {
                @media (max-width: 992px) {
                    margin: 0 !important;
                }
                .col-lg-11.offset-lg-1,
                .col-xl-5.col-lg-7.offset-lg-1,
                .col-xl-9.col-lg-9 {
                    @media (max-width: 992px) {
                        padding: 0 !important;
                    }
                }
            }
        }
        article {
            // &:last-child{
            //     margin-bottom: 121px;
            //     @media(max-width: 992px) {
            //         margin-bottom: 50px;
            //     }
            // }
            .featuredImage {
                width: 100%;
                max-width: calc( 195px + 48%); // 7 columns
                height: auto;
                float: left;
                margin-left: -9.3%;
                margin-right: 75px;
                margin-bottom: 80px;
                @media (max-width: 992px) {
                    float: none;
                    margin-left: 0;
                    max-width: 100%;
                    margin-bottom: 40px;
                    margin-right: 0;
                }
                &+ .ingress + p,
                &+ p{
                    pointer-events: none;
                    *{
                        pointer-events: all;
                    }
                }
                figcaption{
                    margin-top: 20px;
                    font-family: $font-regular;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: #939399;
                    width: calc(87% - 15px);
                    margin-left: calc(13% + 15px);
                    @media (max-width: 992px) {
                        width: auto;
                        margin-left: 0;
                        padding-right: 15px;
                        padding-left: 15px;
                    }
                }
            }
            .ingress {
                padding: 0 10px 0 0;
                @media (max-width: 992px) {
                    padding: 0 20px 0 20px;
                }
                span {
                    font-weight: 500;
                    @include font-size(28);
                    line-height: 44px;
                    color: #3b3b3b;
                    margin-bottom: 44px;
                    display: flex;
                    @media (max-width: 992px) {
                        @include font-size(18);
                        line-height: 32px;
                        margin-bottom: 20px;
                    }
                }
                dl {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    border-top: 1px solid #d9dbe3;
                    margin-bottom: 40px;
                    @media (max-width: 1260px) {
                        width: 100%;
                    }
                    @media (max-width: 992px) {
                        margin-bottom: 0px;
                    }
                    dt,
                    dd {
                        padding: 25px 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 32px;
                        letter-spacing: 0.24px;
                        color: #333333;
                        border-bottom: 1px solid #d9dbe3;
                        a {
                            color: #333333;
                            text-decoration: underline;
                            &.download-link {
                                color: #4C80A9;
                            }
                        }
                        p {
                            letter-spacing: 0.24px;
                            font-size: 20px;
                            line-height: 32px;
                            color: #333333;
                            @media (max-width: 992px) {
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
                        @media (max-width: 992px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                    dt {
                        flex-basis: 180px;
                        font-weight: 600;
                        &:last-of-type {
                            border-bottom: 0;
                        }
                        @media(max-width: 1200px) {
                            padding-bottom: 0;
                            border: 0;
                            flex-basis: 100%;
                        }
                    }
                    dd {
                        padding-right: 0;
                        flex-basis: calc(100% - 180px);
                        &:last-of-type {
                            border-bottom: 0;
                        }
                        @media(max-width: 1200px) {
                            flex-basis: 100%;
                            padding-top: 10px;
                        }
                        // &:last-child{
                        //     text-align: right;
                        //     @media(max-width: 1260px) {
                        //         text-align: left;
                        //     }
                        // }
                    }
                }
                .download-manual {
                    padding: 35px 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 100%;
                    letter-spacing: 0.24px;
                    color: #333333;
                    border-bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    a {
                        color: #4c80a4;
                        text-decoration: underline;
                        font-size: 18px;
                        font-weight: 600;
                        padding-left: 25px;
                        position: relative;
                        &:before {
                            @include background-image("icons/PDF-icon.svg", 16, 22);
                            top: 0;
                            left: 0;
                            position: absolute;
                        }
                    }
                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 32px;
                        letter-spacing: 0.24px;
                        color: #333333;
                    }
                    small {
                        font-size: 14px;
                        line-height: 32px;
                        font-weight: 500;
                        margin-left: 5px;
                        color: #333333;
                    }
                }
            }
            .imageWrapper {
                width: 100%;
                margin-bottom: 30px;
                @media (max-width: 992px) {
                    margin-bottom: 20px;
                    padding: 0 20px;
                }
                figure{
                    padding: 50px 0;
                    @media (max-width: 768px) {
                        padding: 25px 0;
                    }
                    figcaption {
                        margin-top: 20px;
                        font-family: $font-regular;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: #939399;
                        width: 100%;
                    }
                }
                figure.editor-align-left {
                    margin-right: 30px;
                }
                figure.editor-align-right {
                    margin-left: 30px;
                }
                figure.editor-align-left,
                figure.editor-align-right {
                    width: 50%;
                    padding: 0;
                    @media (max-width: 768px) {
                        float: none !important;
                        width: 100%!important;
                        margin: 0;
                        padding: 25px 0;
                        img {
                            width: 100%;
                        }
                    }
                    figcaption {
                        margin-top: 10px;
                        width: 100%;
                    }
                }
                figure.editor-align-justify {
                    width: 100%;
                    padding: 50px 0;
                    @media (max-width: 768px) {
                        padding: 25px 0;
                    }
                    img {
                        width: 100%;
                    }
                    figcaption {
                        width: 100%;
                    }
                }
                figure.editor-align-center {
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                    figcaption {
                        text-align: center;
                    }
                }
            }
            p {
                display: block;
                font-weight: 500;
                @include font-size(21);
                line-height: 34px;
                letter-spacing: 0.24px;
                color: #121212;
                opacity: 0.9;
                margin-bottom: 35px;
                width: 100%;
                word-break: break-word;
                @media (max-width: 992px) {
                    @include font-size(15);
                    line-height: 25px;
                    margin-bottom: 20px;
                    padding: 0 20px;
                }
            }
            .videoWrapper{
                @media (max-width: 992px) {
                    margin: 0 20px;
                }
            }
            ol,
            ul {
                padding-left: 22px;
                margin-bottom: 35px;
                @media (max-width: 992px) {
                    @include font-size(15);
                    margin-bottom: 20px;
                    padding: 0 20px 0 42px;
                }
                li {
                    font-weight: 500;
                    @include font-size(21);
                    line-height: 34px;
                    letter-spacing: 0.24px;
                    color: #121212;
                    opacity: 0.9;
                    margin-bottom: 5px;
                    @media (max-width: 992px) {
                        @include font-size(15);
                        line-height: 25px;
                    }
                    p{
                        margin-bottom: 0;
                        padding: 0;
                        display: inline;
                        @media (max-width: 992px){
                            padding: 0;
                        }
                    }
                }
            }
            ul{
                list-style: none;
                li{
                    &:before {
                        content: "\2022";
                        color: #f0ce29;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
            pre {
                display: block;
                white-space: pre-wrap;
                margin-bottom: 35px;
                @include font-size(21);
                line-height: 34px;
                @media (max-width: 992px) {
                    margin-bottom: 20px;
                    @include font-size(15);
                    line-height: 25px;
                    padding: 0 20px;
                }
            }
            code {
                @include font-size(21);
                line-height: 34px;
                @media (max-width: 992px) {
                    @include font-size(15);
                    line-height: 25px;
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 600;
                @include font-size(28);
                line-height: 44px;
                letter-spacing: 0.24px;
                color: #2b2b2c;
                opacity: 0.9;
                padding: 0 0 28px 0;
                display: block;
                @media (max-width: 992px) {
                    padding: 0 20px 12px 20px;
                    @include font-size(20);
                    line-height: 28px;
                    color: #2a2a2a;
                    letter-spacing: 0.12px;
                }
            }

            p,
            ol,
            ul,
            pre,
            code,
            .videoWrapper,
            .imageWrapper{
                & + h1,
                & + h2,
                & + h3,
                & + h4,
                & + h5,
                & + h6 {
                    padding-top: 48px;
                    @media (max-width: 992px) {
                        padding-top: 24px;
                    }
                }
            }
            table{
                border-collapse:collapse;
                width: 100%;
                text-align: center;
                border: solid 1px #121212;
                margin-bottom: 35px;
                @media (max-width: 992px) {
                    @include font-size(15);
                    line-height: 25px;
                    margin-bottom: 20px;
                }
                caption {
                    margin-top: 4px;
                    @media (max-width: 992px) {
                        padding: 0 8px;
                    }
                }
                td,th {
                    padding: 8px;
                    p , ul, li, >* {
                        padding: 0;
                        font-size: inherit!important;
                        line-height:  inherit!important;
                        margin-bottom: 0!important;
                    }
                }
                tr, td, th{
                    border: solid 1px #121212;
                }
                th, thead td{
                    background-color:#f1f2f6;
                }
            }

            &.macro {
                width: 80%;
                width: 66%;
                margin-left: calc(8.5% + 30px);
                @media (max-width: 1200px) {
                    width: 77.5%;
                    margin-left: calc(7.5% + 30px);
                }
                @media (max-width: 992px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
        &--quote {
            position: relative;
            width: 100%;
            padding: 87px 0 88px 0;
            @media (max-width: 992px) {
                padding: 20px 0 0 0;
            }
            .contentWrapper {
                position: relative;
                width: 100%;
                height: 100%;
                align-items: center;
                @media (max-width: 992px) {
                    margin-top: 10px;
                    margin-bottom: 122px;
                }
                .content {
                    transform: translateX(-1504px);
                    width: 2500px;
                    display: flex;
                    align-items: center;
                    display: -ms-flexbox;
                    -ms-align-items: center;
                    height: 100%;
                    min-height: 402px;
                    justify-content: flex-end;
                    background-color: $glava-green-200;

                    @include site(weber){
                        background-color: $weber-black-50;
                    }

                    @include site(gyproc){
                        background-color: $gyproc-aqua-300;
                    }

                    @media (max-width: 1380px) {
                        transform: translateX(-1570px);
                    }
                    @media (max-width: 1200px) {
                        transform: translateX(-1745px);
                    }
                    @media (max-width: 992px) {
                        transform: translateX(0);
                        width: 100%;
                        display: block;
                        align-items: unset;
                        justify-content: unset;
                        padding: 51px 20px 68px 20px;
                        min-height: auto;
                    }
                    h2 {
                        width: 705px;
                        font-weight: 600;
                        @include font-size(44);
                        line-height: 64px;
                        padding: 0;
                        margin: 114px 272px 123px 0;
                        position: relative;
                        color: inherit;
                        @media (max-width: 1380px) {
                            @include font-size(36);
                            line-height: 56px;
                            margin: 120px 200px 120px 0;
                        }
                        @media (max-width: 1200px) {
                            @include font-size(30);
                            line-height: 50px;
                            width: 550px;
                            margin: 120px 180px 120px 0;
                        }
                        @media (max-width: 992px) {
                            @include font-size(20);
                            width: 100%;
                            line-height: 32px;
                            margin: 0;
                        }
                        &:before {
                            content: "“";
                            position: absolute;
                            top: -4px;
                            left: -24px;
                            @media (max-width: 992px) {
                                top: -20px;
                                left: 0px;
                                font-size: 49px;
                            }
                        }
                    }
                    .quoteInfo {
                        z-index: 2;
                        position: absolute;
                        //top: 50%;
                        //margin-top: -65px;
                        right: -235px;
                        //height: 131px;
                        width: 325px;
                        @media (max-width: 1380px) {
                            width: 200px;
                            right: -108px;
                        }
                        @media (max-width: 992px) {
                            top: unset;
                            margin-top: 0;
                            right: unset;
                            width: auto;
                            bottom: -82px;
                        }
                        .image {
                            width: 200px;
                            max-width: 200px;
                            @media (max-width: 992px) {
                                width: 69px;
                                max-width: 69px;
                            }
                        }
                        .info {
                            position: relative;
                            h3 {
                                padding: 0;
                                font-weight: 600;
                                @include font-size(18);
                                line-height: 26px;
                                margin: 26px 0 0 0;
                                color: inherit;
                                @media (max-width: 992px) {
                                    @include font-size(14);
                                    line-height: 24px;
                                    margin: 8px 0 0 0;
                                }
                            }
                            span {
                                display: block;
                                font-weight: 500;
                                @include font-size(16);
                                line-height: 23px;
                                @media (max-width: 1380px) {
                                    margin-top: 6px;
                                }
                                @media (max-width: 992px) {
                                    @include font-size(12);
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &--sidebarMenu {
            padding-top: 60px;
            @media (max-width: 992px) {
                padding-top: 30px;
            }
            &:first-child{
                padding-top: 0;
                @media (max-width: 992px) {
                    padding-top: 0;
                }
            }
            @media (max-width: 992px) {
                margin-bottom: 30px;
                &:first-child{
                    padding-top: 10px;
                    margin-bottom: 0px
                }
            }
            h6 {
                @include font-size(20);
                line-height: 36px;
                padding: 0;
                color: $glava-black;

                @include site(weber){
                  color: $weber-black;
                }

                @include site(gyproc){
                  color: $gyproc-black;
                }
            }
            a,
            span {
                display: block;
                color: #788995;
                @include font-size(22);
                line-height: 40px;
                text-decoration: underline;
                font-family: $font-regular;
                font-weight: 500;
                font-style: normal;
                @media (max-width: 992px) {
                    font-size: 20px;
                }
            }
            a {
                display: inline-block;
            }
        }
        .related__products {
            margin-top: 60px;
            @media(max-width: 992px) {
                margin-top: 0;
            }
            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 34px;
                color: #2a2a2a;
                margin-bottom: 15px;
            }
            &--item {
                display: flex;
                padding: 28px 0;
                border-bottom: 1px solid #d9dbe3;
                text-decoration: none;
                // align-items: center;
                .img {
                    margin-right: 30px;
                    max-width: 93px;
                    min-width: 93px;
                    height: 70px;
                }
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0.24px;
                    color: #333333;
                    word-break: break-word;
                    overflow-wrap: anywhere;
                }
            }
        }

        .accordion {
            &.employee {
                margin-top: 80px;
                margin-bottom: 113px;
            }
        }

        .accordionMultipleMargin {
            margin-top: 80px;
            margin-bottom: 113px;
            .accordionMultiple {
                &.employee {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    &__related {
        margin-bottom: 100px;
        @media (max-width: 992px) {
            margin-bottom: 50px;
        }
        &--content {
            span {
                font-weight: 600;
                @include font-size(20);
                line-height: 32px;
                letter-spacing: 0.24px;
                color: #2b2b2c;
                opacity: 0.9;
                margin-bottom: 16px;
                display: block;
                @media (max-width: 992px) {
                    @include font-size(12);
                    line-height: 29px;
                }
            }
            h2 {
                font-weight: 600;
                @include font-size(36);
                line-height: 56px;
                letter-spacing: 0.44px;
                color: #2b2b2c;
                opacity: 0.9;
                margin-bottom: 16px;
                @media (max-width: 992px) {
                    @include font-size(20);
                    line-height: 30px;
                }
            }
            a {
                font-weight: 500;
                font-size: 20px;
                line-height: 44px;
                color: #618978;
                opacity: 0.9;
                text-decoration: none;
                position: relative;
                @media (max-width: 992px) {
                    @include font-size(15);
                    line-height: 100%;
                }
                &:after {
                    @include background-image("icons/arrow-les-mer.svg", 15, 24);
                    width: 24px;
                    height: 15px;
                    position: absolute;
                    right: -37px;
                    top: 50%;
                    margin-top: -7px;
                    @media (max-width: 992px) {
                        width: 19px;
                        height: 10px;
                        right: -27px;
                        margin-top: -4px;
                        @include background-image("icons/arrow-les-mer.svg", 19, 10);
                    }
                }
            }
        }
    }
    &__lastPublisheds {
        margin-bottom: 200px;
        @media (max-width: 992px) {
            margin-bottom: 60px;
        }
        &--item {
            a {
                text-decoration: none;
            }
            .image {
                position: relative;
                margin-bottom: 28px;
                .macroTag {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 15px 20px;
                    font-weight: 600;
                    @include font-size(12);
                    line-height: 100%;
                    letter-spacing: 0.24px;
                    color: #333333;
                    text-transform: uppercase;
                    @media (max-width: 992px) {
                        padding: 12px 16px;
                    }
                    &.sand {
                        background: #dbd1c9;
                    }
                    &.gress {
                        background: #b6c4be;
                    }
                    &.hav {
                        background: #cfdce4;
                    }
                    &.reference {
                        background: #cdd9d4;
                    }
                    &.solution {
                        background: #cdd9d4;
                    }
                }
            }
            h2 {
                font-weight: 600;
                @include font-size(28);
                line-height: 36px;
                letter-spacing: 0.24px;
                color: #333333;
                margin-bottom: 20px;
                padding-right: 50px;
                @media (max-width: 992px) {
                    @include font-size(20);
                    line-height: 28px;
                    margin-bottom: 8px;
                }
                span{
                    position: relative;
                    // color: red;
                    &::after{
                      @include background-image("./icons/arrow-hero.svg", 24, 18);
                      position: absolute;
                      right: -38px;
                      bottom: 7px;
                      @media (max-width: 1200px){
                        right: -34px;
                        bottom: 3px;
                      }
                      @media (max-width: 992px){
                        @include background-image("./icons/arrow-hero.svg", 22, 16);
                        right: -34px;
                        bottom: 4px;
                      }
                    }
                  }
            }
            p {
                font-weight: 500;
                @include font-size(18);
                line-height: 30px;
                letter-spacing: 0.24px;
                color: #333333;
                @media (max-width: 992px) {
                    @include font-size(16);
                    line-height: 25px;
                    margin-bottom: 40px;
                }
            }
        }
    }
    &.articleSolution {
        .text-right {
            @media (max-width: 992px) {
                text-align: left !important;
            }
        }
        .articleWrapper__header--title {
            color: #2a2a2a;
        }
        ul {
            list-style: none;
            li {
                &:before {
                    content: "\2022";
                    color: #f0ce29;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }

        .contentArticle{
            .ingress{
                dl.description-data{
                    border-top: none;

                    h5{
                        display: block;
                        width: 100%;
                        padding: 0 0 12px 10px;
                        border-bottom: 1px solid #d9dbe3;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.24px;
                    }

                    @media (min-width: 1200px){
                        dt{
                            flex-basis: 50%;
                        }
                    }

                    dd{
                        @media (min-width: 1200px) {
                            display: flex;
                            justify-content: flex-end;
                            flex-basis: 50%;
                        }
                    }
                }
            }
        }

        .articleWrapper__content{
            #related-products-body{
                .products {
                    @media (max-width: 992px) {
                      margin-bottom: 60px;
                    }

                    a{
                      text-decoration: none;

                      &:hover{
                        .img{
                          opacity: .9;
                        }
                      }
                    }

                    &__item {
                      margin-bottom: 80px;
                      text-decoration: none;

                      @media (max-width: 992px) {
                        margin-bottom: 40px;
                      }

                      .wrapper-img{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 13px;
                        height: 360px;
                        width: 100%;

                        @media (max-width: 1200px) {
                          height: 260px;
                        }

                        @media (max-width: 992px) {
                          height: 260px;
                        }

                        @media (max-width: 768px) {
                          height: 158px;
                        }

                        @media (max-width: 375px) {
                          height: 158px;
                        }

                        img{
                          display: block;
                          max-width: 100%;
                          max-height: 100%;
                        }
                      }

                      &--description {
                        &:hover{
                          text-decoration: none !important;
                        }

                        h2 {
                          font-style: normal;
                          font-weight: 600;
                          font-size: 18px;
                          line-height: 28px;
                          letter-spacing: 0.24px;
                          text-decoration: none;
                          color: #26272b;

                          &:hover{
                            text-decoration: none !important;
                          }

                          @media (max-width: 992px) {
                            font-size: 13px;
                            line-height: 20px;
                          }
                        }

                        span {
                          font-style: normal;
                          font-weight: 500;
                          font-size: 18px;
                          line-height: 28px;
                          letter-spacing: 0.24px;
                          text-decoration: none;
                          color: #383940;

                          &:hover{
                            text-decoration: none;
                          }

                          @media (max-width: 992px) {
                            font-size: 12px;
                            line-height: 16px;
                            display: block;
                          }
                        }

                        &:hover{
                          h2, span{
                            text-decoration: none !important;
                          }
                        }
                      }
                    }

                    & + .btnShowMore {
                      margin-top: 0;
                      margin-bottom: 120px;
                    }
                }
            }
        }
    }
    .accordion.employee {
        @media (max-width: 992px) {
            margin-bottom: 40px;
        }
    }
    .contactUs {
        margin-bottom: 140px !important;
        @media (max-width: 992px) {
            padding-bottom: 100px !important;
        }
    }
}
