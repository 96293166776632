.campaign-page-full-image {
  margin-top: -238px;

  @media (max-width: 991px){
    margin-top: -132px;
  }

  @media (max-width: 744px){
    margin-top: -120px;
  }

  &__wrapper {
    position: relative;
  }

  &__bg {
    background: #000;
    position: fixed;
    z-index: -1;
    top: 0;
    height: 100vh;
    will-change: transform;

    &__img{
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      display: block;
      will-change: transform;

      &:first-child{
        opacity: 1;
      }

      &:not(:first-child) {
        position: absolute;
        top: 0;
        display: block;
        opacity: 0;

        &.imgReady{
          opacity: 1;
        }
      }

      &.bgImageFadeIn{
        animation: bgFadeIn 1s;
        animation-fill-mode: forwards;
      }

      &.bgImageFadeOut{
        animation: bgFadeOut 1s;
        animation-fill-mode: forwards;
      }

      &.overlay{
        &.bgImageFadeIn{
          animation: bgFadeInOverlay 1s;
          animation-fill-mode: forwards;
        }

        &.bgImageFadeOut{
          animation: bgFadeOutOverlay 1s;
          animation-fill-mode: forwards;
        }

        &:last-child{
          filter: brightness(40%);
        }
      }
    }

    &--block {
      position: absolute;
      top: inherit;
      bottom: 0;
    }

    &--desktop{
      @media (max-width: 991px){
        display: none;
      }
    }

    &--mobile{
      display: none;

      @media (max-width: 991px){
        display: block;
      }
    }
  }

  &__content {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100vh;

    h2 {
      color: #fff;
      font-size: 72px;
      line-height: 1.1;
      letter-spacing: -0.0065em;

      @media (max-width: 991px){
        @include font-size(32);
        line-height: 1.28;
        letter-spacing: 0.12px;
        margin: 0 auto;
        max-width: 500px;
      }
    }

    p {
      color: #fff;
      @include font-size(24);
      line-height: 1.4;
      letter-spacing: 0.01em;
      margin-top: 28px;

      @media (max-width: 991px){
        @include font-size(18);
        letter-spacing: 1.67;
        margin: 16px auto 0;
        max-width: 500px;
      }
    }

    .btn {
      color: #fff;
      @include font-size(20);
      line-height: 1.2;
      letter-spacing: 0.0125em;
      font-weight: 500;
      padding: 20px 48px;
      border-radius: 100px;
      margin: 40px auto 0;
      background: #1F1F21;
      width: fit-content;
      @media (max-width: 991px){
        @include font-size(18);
        letter-spacing: 1.67;
        padding: 16px 32px;
        margin-top: 24px;
      }
    }

    + .campaign-page-full-image__content{
      margin-top: 50vh; // this value is overwritten by the variable "contentMargin" on campaign-page-full-image.js
    }

    &__text {
      &--white {
        h2, p {
          color: #fff;
        }

        .campaign-page-full-image__scroll-btn{
          button , a{
            @include background-image("icons/arrow-narrow-down-32-w.svg", 60, 60);

            @media (max-width: $mobileWidth) {
              @include background-image("icons/arrow-narrow-down-24-w.svg", 48, 48);
            }
          }
        }
      }

      &--black {
        h2, p {
          color: #1f1f21;
        }
      }
    }
  }

  &__dropzone {
    margin: 124px 0;
    @media (max-width: 991px) {
      margin: 56px 0;
    }
    >* {
      &:first-child {
        margin-top: 0!important;
      }
      &:last-child {
        margin-bottom: 0!important;
        .articleWrapper__content {
          margin-bottom: 0;
        }
      }
    }
    .text-block .articleWrapper__content {
      margin-bottom: 0;
      article.contentArticle{
        color: $textDark1;
        @include font-size(20);
        line-height: 1.5;
        @media (max-width: 991px) {
          @include font-size(16);
          line-height: 1.53;
        }
        > * {
          &:first-child {
            margin-top: 0!important;
            > figure {
              margin-top: 0!important;
            }
          }
          &:last-child {
            margin-bottom: 0!important;
            > figure {
              margin-bottom: 0!important;
            }
          }
        }
        h2 {
          color: inherit;
          @include font-size(24);
          line-height: 1.2;
          letter-spacing: -0.0025em;
          margin-bottom: 24px;
          margin-top: 40px;
          padding: 0;
          @media (max-width: 991px) {
            font-size: rem(20);
          }
        }
        h3, h4, h5, .h3, .h4, .h5 {
          color: inherit;
          @include font-size(20);
          line-height: 1.5;
          margin-top: 32px;
          margin-bottom: 16px;
          padding: 0;
          @media (max-width: 991px) {
            @include font-size(16);
            line-height: 1.53;
          }
        }
        p {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          letter-spacing:  inherit;
          font-weight:  inherit;
          margin-bottom: 24px;
          opacity: 1;
        }
        u {
          text-decoration-line: underline;
        }
        i {
          font-style: italic;
        }
        b, strong {
          font-weight: bolder;
        }
        sup {
          vertical-align: baseline;
          font-size: smaller;
        }
        sub {
          vertical-align: sub;
          font-size: smaller;
        }
        tt {
          font-family: monospace;
        }
        a {
          color: $turquoise;
          text-decoration: underline;
          border: none;
        }
        ul, ol {
          margin-bottom: 24px;
          li {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing:  inherit;
            font-weight:  inherit;
            opacity: 1;
            margin-bottom: 18px;
            p{
              margin-bottom: 8px;
            }
            >:last-child,
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              margin: 0;
            }
          }
        }
        ul {
            list-style: none;
            padding-left: 12px;
            li {
                padding-left: 18px;
                position: relative;
                @media (max-width: 991px) {
                    padding-left: 20px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    border-radius: 4px;
                    height: 5px;
                    width: 5px;
                    margin-top: -2.5px; //0.5 height
                    background-color: $textDark1;
                    vertical-align: middle;
                    left: 0;
                    top: 0.75em;
                }
            }
        }
        ol {
          list-style-type: decimal;
          padding-left: 30px;
        }
        table {
          margin-bottom: 24px;
          display: block;
          max-width: 100%;
          overflow: auto;
          @media (max-width: 991px) {
            margin-bottom: 40px;
          }
          td, th {
            padding: 5px 10px;
            border-bottom: 1px solid $textDark1;
          }
          th {
            font-weight: bolder;
          }
        }
        figure {
          margin-top: 60px;
          margin-bottom: 60px;
          padding: 0!important;
          @media (max-width: 991px) {
            margin-top: 40px;
            margin-bottom: 40px;
          }
          &.editor-align-right,  &.editor-align-left {
              margin-top: 0;
          }
          img {
            margin-top: 0;
            margin-bottom: 0;
            position: static!important;
            right: unset!important;
            max-width: unset!important;
          }
          blockquote {
            font-size: rem(24);
            line-height: 1.4;
            position: relative;
            @media (max-width: 991px) {
              font-size: rem(20);
            }
            &:before, &:after {
              content: '"';
              display: inline;
            }
          }
        }
        figcaption, .figcaption {
          color: #5D5D5F;
          @include font-size(14);
          line-height: 1.4;
          margin-top: 16px!important;
          width: unset!important;
          position: static!important;
          letter-spacing: 0.02em;
          * {
              margin: 0;
          }
          .cite, cite {
            font-style: normal;
            color: #ACACAD;
            display: block;
            margin-top: 8px;
            @media (max-width: 991px) {
              margin-top: 2px;
            }
          }
        }
        img {
          margin-top: 60px;
          margin-bottom: 60px;
          max-width: 100%;
          @media (max-width: 991px) {
            margin-top: 40px;
            margin-bottom: 40px;
          }
        }
      }
    }
    .text-block,
    .carousel {
      margin-bottom: 80px;
      @media (max-width: 991px) {
        margin-bottom: 48px;
      }
    }
  }

  &__scroll-btn{
    position: absolute;
    bottom: 54px;
    left: calc(50% - 30px);

    @media (max-width: $mobileWidth) {
      bottom: 20px;
      left: calc(50% - 24px);
    }

    button , a{
      border: none;
      background-color: transparent;
      @include background-image("icons/arrow-narrow-down-32.svg", 60, 60);

      @media (max-width: $mobileWidth) {
        @include background-image("icons/arrow-narrow-down-24.svg", 48, 48);
      }
    }
  }
}

@keyframes bgFadeIn {
  0% { opacity: 0; filter: brightness(0%); }
  20% { opacity: 0; }
  100% { opacity: 1; filter: brightness(100%);}
}

@keyframes bgFadeOut {
  0% { filter: brightness(100%); }
  20% { opacity: 1; }
  100% { opacity: 0; filter: brightness(0%);}
}

// keyframes for darker images
@keyframes bgFadeInOverlay {
  0% { opacity: 0; filter: brightness(0%); }
  20% { opacity: 0; }
  100% { opacity: 1; filter: brightness(40%);}
}

// keyframes for darker images
@keyframes bgFadeOutOverlay {
  0% { filter: brightness(40%); }
  20% { opacity: 1; }
  100% { opacity: 0; filter: brightness(0%);}
}
