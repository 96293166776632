.blog-posts {
  padding: 100px 0 82px;
  margin: 80px 0;
  @media (max-width: 991px) {
    padding: 56px 0 40px;
    // width: auto;
    // overflow: scroll;
  }
  &__wrapper {
    .col-md-6 .card-blog {
      @media (max-width: 991px) and (min-width: 768px) {
        height: calc(100% - 16px);
      }
    }

    // 3 columns Layout
    .col-lg-4{
      .card-blog{
        &__content{
          @media (min-width: 1200px){
            padding: 40px 48px 44px;
          }
        }

        &__title {
          @media (min-width: 1200px){
            @include font-size(25);
            line-height: 36px;
          }
        }

        &__description {
          @media (min-width: 1200px){
            @include font-size(19);
            line-height: 30px;
          }
        }
      }
    }
  }

  &--primary{
    background-color: $glava-green-200;

    @include site(weber){
      background-color: $weber-black-50;
    }

    @include site(gyproc){
      background-color: $gyproc-aqua-300;
    }
  }

  &--secondary{
    background-color: $glava-black;

    @include site(weber){
      background-color: $weber-anthracite-500;
    }

    @include site(gyproc){
      background-color: $gyproc-sand-300;
    }
  }
}
.card-blog {
  @include font-size(19);
  line-height: 1.579;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  word-break: break-word;
  // pointer-events: none;
  @media (max-width: 991px) {
    margin-bottom: 16px;
    height: auto;
  }
  &:hover {
    text-decoration: none;
    .card-blog__link {
      margin-left: 12px;
      @media (max-width: 991px) {
        margin-left: 8px;
      }
    }
  }
  .img {
    width: 100%;
    // pointer-events: auto;
  }
  &__content {
    padding: 24px 28px;
    height: 240px;
    height: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      padding: 16px 20px 20px;
      height: auto;
    }
  }
  &__title {
    @include font-size(21);
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 16px;
    // pointer-events: auto;
    @media (max-width: 991px) {
      margin-bottom: 8px;
    }
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  &__description {
    @include font-size(16);
    line-height: 28px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 24px;
    color: $gray-800;
    @media (max-width: 991px) {
      margin-bottom: 20px;
      letter-spacing: 0.28px;
    }
  }
  &__link {
    font-weight: 600;
    letter-spacing: 0.005em;
    // pointer-events: auto;
    margin-top: auto;
    transition: .5s ease-in-out;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:before {
      content: '';
      @include background-image("icons/arrow-right-24.svg", 24, 24);
      display: inline-block;
      vertical-align: sub;
      margin-right: 8px;
    }
  }
}
