.boxes{
  margin-bottom: 60px;
  @media (max-width: 1024px){
    margin-bottom: 56px;
  }

  .title-boxes{
    @include font-size(36);
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 40px;
    @media (max-width: 1024px){
      @include font-size(24);
      line-height: 32px;
      margin-bottom: 20px;
    }
  }
  .box {
    margin-bottom: 24px;
    height: auto;
    display: flex;
     // align-items: center;
    min-height: calc(100% - 24px);
    position: relative;
    &__content{
      width: 100%;
      padding: 40px 48px 48px 44px;
      @media (max-width: 1024px){
        padding: 39px 33px;
      }
      h3 {
        position: relative;
        @include font-size(22);
        line-height: 30px;
        margin-bottom: 12px;
        padding-right:33px;
        word-break: break-word;
        @media (max-width: 1024px){
          @include font-size(20);
          line-height: 28px;
          margin-bottom: 10px;
        }
      }

      p {
        @include font-size(19);
        line-height: 30px;
        max-height: 60px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (max-width: 1024px){
          @include font-size(16);
          line-height: 25px;
          max-height: 46px;
        }
      }
    }
    &__link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color: transparent!important;

      &::after {
        position: absolute;
        @include background-image("icons/redesign-2023/arrow-right.svg", 28, 28);
        right: 36px;
        top: 42px;
        @media (max-width: 1024px){
          @include background-image("icons/redesign-2023/arrow-right.svg", 24, 24);
        }
      }
      &.external-link{
        &::after{
          @include background-image("icons/redesign-2023/arrow-up-right.svg", 28, 28);

          @include site(weber){
            @include background-image("icons/redesign-2023/arrow-up-right-weber.svg", 28, 28);

            @media (max-width: 1024px){
              width: 24px;
              height: 24px;
            }
          }

          @include site(gyproc){
            @include background-image("icons/redesign-2023/arrow-up-right-gyproc.svg", 28, 28);

            @media (max-width: 1024px){
              width: 24px;
              height: 24px;
            }
          }

          @media (max-width: 1024px){
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &--primary{
      background-color: $glava-yellow-600;

      @include site(weber){
        background-color: $weber-black-50;
      }

      @include site(gyproc){
        background-color: $gyproc-aqua-300;
      }
    }

    &--secondary{
      background-color: $glava-green-200;

      @include site(weber){
        background-color: $weber-yellow;
      }

      @include site(gyproc){
        background-color: $gyproc-sand-300;
      }
    }

    &--tertiary{
      background-color: $glava-black;
      color: $white;

      @include site(weber){
        background-color: $weber-anthracite-500;
      }

      @include site(gyproc){
        background-color: $gyproc-blue;
      }

      .box__link {
        &::after {
          @include background-image("icons/redesign-2023/arrow-right-w.svg", 28, 28);

          @media (max-width: 1024px){
            @include background-image("icons/redesign-2023/arrow-right-w.svg", 24, 24);
          }
        }
        &.external-link::after{
          @include background-image("icons/redesign-2023/arrow-up-right-w.svg", 28, 28);

          @media (max-width: 1024px){
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    &.no-link{
      .box__link {
        display: none;
      }
    }
    .col-sm-12.col-md-6.col-lx-6.col-xl-4{
      @media (max-width: 992px){
        &:last-child{
          margin-bottom: -20px;
        }
      }
    }
  }
}
