// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;
  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }
  &.aspectRatio--3x2 {
    padding-top: calc((2 / 3) * 100%);
  }
  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
  }
  &.aspectRatio--16x9 {
    padding-top: calc((9.8 / 16) * 100%);
  }
  &.aspectRatio--9x11 {
    padding-top: calc((11 / 9) * 100%);
  }
  &.aspectRatio--16x10 {
    padding-top: calc((10.1 / 16) * 100%);
  }
  &.aspectRatio--87x115{
    padding-top: calc((115/ 87) * 100%);
  }

  &.aspectRatio--59x75 {
    padding-top: calc((75 / 59) * 100%);
  }
  &.aspectRatio--93x61 {
    padding-top: calc((61 / 93) * 100%);
  }
  &.aspectRatio--93x70 {
    padding-top: calc((70 / 93) * 100%);
  }
  &.aspectRatio--320x280 {
    padding-top: calc((280 / 320) * 100%);
  }
  &.aspectRatio--361x240 {
    padding-top: calc((240 / 361) * 100%);
  }
  &.aspectRatio--360x214 {
    padding-top: calc((214 / 360) * 100%);
  }
  &.aspectRatio--320x200 {
    padding-top: calc((200 / 320) * 100%);
  }
  &.aspectRatio--320x216 {
    padding-top: calc((216 / 320) * 100%);
  }
  &.aspectRatio--440x280 {
    padding-top: calc((280 / 440) * 100%);
  }
  &.aspectRatio--349x360 {
    padding-top: calc((360 / 349) * 100%);
  }
  &.aspectRatio--899x569 {
    padding-top: calc((569 / 899) * 100%);
  }
  &.aspectRatio--596x405 {
    padding-top: calc((405 / 596) * 100%);
  }
  &.aspectRatio--680x440 {
    padding-top: calc((440 / 680) * 100%);
  }
  &.aspectRatio--680x464 {
    padding-top: calc((464 / 680) * 100%);
  }
  &.aspectRatio--680x480 {
    padding-top: calc((480 / 680) * 100%);
  }
  &.aspectRatio--236x275 {
    padding-top: calc((275 / 236) * 100%);
  }
  &.aspectRatio--440x600 {
    padding-top: calc((600 / 440) * 100%);
  }
  &.aspectRatio--473x312 {
    padding-top: calc((312 / 473) * 100%);
  }
  &.aspectRatio--953x627 {
    padding-top: calc((627 / 953) * 100%);
  }
  &.aspectRatio--224x174 {
    padding-top: calc((174 / 224) * 100%);
  }
  &.aspectRatio--323x217 {
    padding-top: calc((217 / 323) * 100%);
  }
  &.aspectRatio--448x300 {
    padding-top: calc((300 / 448) * 100%);
  }
  &.aspectRatio--680x464 {
    padding-top: calc((464 / 680) * 100%);
  }
  &.aspectRatio--721x799 {
    padding-top: calc((799 / 721) * 100%);
  }
  &.aspectRatio--800x540 {
    padding-top: calc((540 / 800) * 100%);
  }
  &.aspectRatio--1466x840 {
    padding-top: calc((840 / 1466) * 100%);
  }

  &.aspectRatio--904x567 {
    padding-top: calc((567 / 904) * 100%);
  }
  &.aspectRatio--424x265 {
    padding-top: calc((265 / 424) * 100%);
  }
  &.aspectRatio--692x520 {
    padding-top: calc((692 / 520) * 100%);
  }
  &.aspectRatio--304x190 {
    padding-top: calc((190 / 304) * 100%);
  }
  &.aspectRatio--335x244 {
    padding-top: calc((244 / 335) * 100%);
  }
  &.aspectRatio--320x212 {
    padding-top: calc((212 / 320) * 100%);
  }
  &.aspectRatio--320x405 {
    padding-top: calc((405 / 320) * 100%);
  }
  &.aspectRatio--944x590 {
    padding-top: calc((590 / 944) * 100%);
  }
  &.aspectRatio--320x400 {
    padding-top: calc((400 / 320) * 100%);
  }

  img, .videoWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  &.aspectRatio--87x64 {
    padding-top: calc((64 / 87) * 100%);
  }
}
