.macroTag {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px;
  height: 32px;
  overflow: hidden;
  font-weight: 600;
  display: flex;
  align-items: center;
  @include font-size(10);
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  background-color: $glava-yellow;

  @include site(weber){
    background-color: $weber-yellow;
  }

  @include site(gyproc){
    background-color: $gyproc-blue;
    color: $white;
  }
}
