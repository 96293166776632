// iw = info-window

.gm-style-iw, .gm-style-iw-c, .gm-style-iw-d {
  padding: 0px 15px 0px 0px  !important;
}
.gm-style-iw-d {
  display: contents;
}

.insulation-iw {
  position: relative;
  display: flex;
  max-width: 264px;

  padding: 9px;
  gap: 9px;

  &__img {
    max-width: 115px !important;
    height: 170px;
    border-radius: 4px;
    object-fit: cover;
  }

  &__text {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 11px;
    line-height: 21px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    overflow-wrap: break-word;

  }

  &__link {

    margin-top: auto;
    border-bottom: 1px solid #000;
    width: fit-content;

    img {
      height: 12px;
      width: 15px;
      margin-left: 3px;
      margin-bottom: 2px;
      object-fit: contain;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

// ajust close button from google-maps info-window

.gm-ui-hover-effect {
  top: -4px !important;
  right: -4px !important;
}
