.newsletter__cta{
    margin: 88px 0 30px;
    padding-top: 74px;
    background-color: #F4F4F4;

    @media (max-width: 1466px) {
        padding-top: 60px;
    }

    @media (max-width: 1200px) {
        padding-top: 50px;
    }

    @media (max-width: 992px) {
        margin: 40px 0 30px;
        padding: 20px 20px 0;
    }

    .row{
        @media (max-width: 992px) {
           flex-direction: column-reverse;
        }
        .image-wrapper{
            display: flex;

            figure{
                display: flex;
                align-items: flex-end;
                justify-content: center;

                img{
                    @media (max-width: 992px) {
                        max-width: 60%;
                    }

                    @media (max-width: 768px) {
                        max-width: 80%;
                    }
                }
            }
        }

        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto 74px;
            padding-top: 30px;
            text-align: center;

            @media (max-width: 1466px) {
                padding-top: 0px;
                margin-bottom: 60px;
            }

            @media (max-width: 1200px) {
                padding-top: 10px;
                margin-bottom: 50px;
            }

            @media (max-width: 992px) {
                margin-bottom: 28px;
            }

            h2{
                margin-bottom: 20px;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: 0px;
            }

            p{
                margin-bottom: 28px;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: 0.24px;

                @media (max-width: 768px) {
                    font-size: 1em;
                    line-height: 25px;
                }
            }

            .cta-button{
                background-color: #3A3C45;
                color: white;
                padding: 16px 48px;
                border-radius: 100px;
                outline: none;
                border: none;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.16px;
            }
        }
    }
}

.glava-modal{
    display: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    z-index: 999;

    @media (max-width: 992px) {
        padding: 20px;
    }

    .glava-modal__bg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #0000008c;
        cursor: pointer;
        z-index: -1;
    }

    .wrapper{
        position: relative;
        background-color: white;
        padding: 116px 132px;
        -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);

        @media (max-width: 768px) {
            padding: 40px 20px;
        }

        .form-content{
            min-width: 597px;
            width: 100%;


            @media (max-width: 992px) {
                min-width: inherit;
                width: 100%;
            }

            header{
                margin-bottom: 48px;

                @media (max-width: 768px) {
                    margin-bottom: 20px;
                }

                h2{
                    font-size: 36px;
                    line-height: 44px;
                    letter-spacing: 0.12px;

                    @media (max-width: 768px) {
                        font-size: 26px;
                        line-height: inherit;
                        letter-spacing: 0.12px;
                    }
                }
            }

            form{
                .intertitle{
                    margin-bottom: 24px;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 34px;
                }

                input[type=email] , input[type=text]{
                    width: 100%;
                    margin-bottom: 32px;
                    background-color: #F6F6F6;
                    outline: none;
                    border-bottom: 1px solid #3A3C45;
                    padding: 20px 24px;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0.24px;
                    color: #333333;
                    border-radius: 0;

                    // fix rounded borders on Safari - mobile
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                }

                .styled-checkbox {
                    position: absolute; // take it out of document flow
                    opacity: 0; // hide it

                    & + label {
                      position: relative;
                      display: flex;
                      align-items: center;
                      padding: 0;
                      font-size: 20px;
                      line-height: 28px;
                      letter-spacing: 0.24px;
                      cursor: pointer;
                    }

                    // Box.
                    & + label:before {
                      content: '';
                      margin-right: 20px;
                      display: inline-block;
                      vertical-align: text-top;
                      width: 28px;
                      height: 28px;
                      border: 1px solid #3a3c4552;
                    }

                    // Box hover
                    &:hover + label:before {
                      //background: #f35429;
                    }

                    // Box focus
                    &:focus + label:before {
                      //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
                    }

                    // Box checked
                    &:checked + label:before {
                      //background: #f35429;
                    }

                    // Disabled state label.
                    &:disabled + label {
                      color: #e6e6e6;
                      cursor: auto;
                    }

                    // Disabled box.
                    &:disabled + label:before {
                      box-shadow: none;
                      background: rgb(248, 248, 248);
                    }

                    // Checkmark. Could be replaced with an image
                    &:checked + label:after {
                      @include background-image("icons/x.svg", 12, 12);
                      position: absolute;
                      left: 8px;
                      top: 8px;
                    }

                    &.styled-checkbox--accept + label{
                        font-size: 14px;
                    }
                }
            }

        }

        button.glava-modal__button{
            margin-top: 40px;
            padding: 12px 40px;
            background-color: #FFE66F;
            border-radius: 100px;
            outline: none;
            border: none;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.16px;
            font-weight: 600;

            @media (max-width: 768px) {
                margin-top: 30px;
            }

            &:disabled{
                opacity: 0.5;
            }
        }

        .thank-panel{
            max-width: 597px;
            width: 100%;

            header{
                margin-bottom: 24px;
            }

            p{
                max-width: 500px;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.24px;
            }

            .glava-modal__button{
                margin-top: 140px;

                @media (max-width: 768px) {
                    margin-top: 70px;
                }
            }
        }
    }

    &.glava-modal--simple{
        .wrapper{
            @media (max-width: 768px) {
                margin-top: -100px;
            }
        }
    }

    &.glava-modal--complex{
        align-items: flex-start;

        .wrapper{
            top: 0px;
            padding: 40px 132px 0px;
            overflow: auto;
            height: 100%;

            @media (max-width: 768px) {
                top: 0px;
                width: 100%;
                margin-top: 0;
                padding: 40px 20px 0px;
            }

            &.centered{
                display: flex;
                align-items: center;
            }

            form{
                margin-bottom: 40px;

                .intertitle{
                    &--interests{
                        margin-top: 28px;

                        .optional-text{
                            color: #929292;
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 24px;
                            letter-spacing: 0.24px;
                        }
                    }
                }

                .styled-checkbox {
                    & + label {
                        display: inline-flex;
                        width: 49%;
                        margin-bottom: 30px;

                        @media (max-width: 768px) {
                            display: flex;
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }

                    &.styled-checkbox--accept + label{
                        margin-top: 24px;
                        margin-bottom: 0;
                    }
                }

                .annet-text-input{
                    display: none;
                }
            }
        }
    }
}
