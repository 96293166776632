$mobileWidth: 991px;

header{
  // background-color: #F6F6F6;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  @media (max-width: $mobileWidth){
    margin-bottom: 60px;
    background-color: transparent;
  }
  &.header.active{
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 0!important;
    transition: .3s;
  }
  .mainMenu__wrapper{
    background: $glava-yellow;
    height: auto;
    width: 100%;
    position: relative;
    &.transparent{
      background: transparent;
    }

    @include site(weber){
      background-color: $weber-anthracite-500;
    }

    @include site(gyproc){
      background-color: $gyproc-blue;
    }

    @media (max-width: $mobileWidth) {
      @include site(weber){
        background-color: $white;
      }

      @include site(gyproc){
        background-color: $white;
      }
    }

    .main__menu{

      &.top{
        background-color: $white;

        @media (max-width: $mobileWidth) {
          background-color: $glava-yellow;

          @include site(weber){
            background-color: $white;
            border-bottom: 1px solid $weber-black;
          }

          @include site(gyproc){
            background-color: $white;
            border-bottom: 1px solid $gyproc-black;
          }
        }

        .content{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 13px 0 12px 0;
          transition: all 300ms;

          @media (max-width: $mobileWidth) {
            height: 72px;
          }

          @media (max-width: 744px) {
            height: 60px;
            padding: 0 4px;
          }
        }

        .main__menu--item {
          color: $glava-black;
          @include font-size(14);
          line-height: 20px;
          letter-spacing: 0.02em;
          margin-left: 24px;
          font-weight: 450;

          @include site(weber){
            color: $weber-black;
          }

          @include site(gyproc){
            color: $gyproc-black;
          }

          @media (max-width: $mobileWidth) {
            margin-left: 32px;
          }
          @media (max-width: 744px) {
            margin-left: 28px;
          }
          &:hover,
          &.active{
            letter-spacing: 0.005em;
            font-weight: 600;
          }
        }
        .mobileIcons{
          display: flex;
          align-items: center;
        }
      }

      &.bot{
        padding: 16px;
        background-color: $glava-black;

        @include site(weber){
          background-color: $weber-anthracite-500;
        }

        @include site(gyproc){
          background-color: $gyproc-blue;
        }

        @media (max-width: $mobileWidth) {
          .hide-responsive {
            display: none;
          }
          &.hide-responsive {
            display: none;
          }
        }

        .main__menu--item {
          color: $white;
          @include font-size(16);
          line-height: 20px;
          margin-right: 28px;
          font-weight: 600;

          @include site(weber){
            color: #FFFFFF;
          }

          @include site(gyproc){
            color: #FFFFFF;
          }

          &:hover,
          &.active{
            // letter-spacing: 0.005em;
          }
        }

        >.hide-responsive{
          width: 100%;
        }
      }

      &--logo{

        @media (max-width: $mobileWidth){

        }
        .logo{
          margin-top: -13px;

          @media (max-width: $mobileWidth){
            margin-top: 0;
          }

          @include site(weber){
            margin-top: 0;
          }

          @include site(gyproc){
            margin-top: 0;
          }

          img {
            width: 91px;
            height: auto;
            object-fit: contain;

            @media (max-width: $mobileWidth){
              width: 80px;
              height: auto;
            }
            @media (max-width: 744px){
              height: auto;
            }

            @include site(weber){
              width: 122px;

              @media (max-width: $mobileWidth){
                width: 108px;
              }
            }

            @include site(gyproc){
              width: 124px;

              @media (max-width: $mobileWidth){
                width: 109px;
              }
            }
          }
          .logo-mob {
            @media (max-width: $mobileWidth){
              display: none;
            }
          }
          .logo-desktop {
            @media (min-width: 992px){
              display: none;
            }
          }
          .logo-dark{
            display: block;
           }
           .logo-light{
             display: none;
           }

           &--desktop{
            @include site(glava){
              @media (max-width: $mobileWidth){
                display: none;
              }
            }
           }

           &--mobile{
            display: none;

            @include site(glava){
              @media (max-width: $mobileWidth){
                display: block;
              }
            }
           }
        }
      }
      &--item{
        position: relative;
        color: #ffffff;
        @include font-size (14);
        letter-spacing: 0.02em;
        font-weight: 450;
        // margin-right: 20px;
        text-decoration: none;
        @media (max-width: $mobileWidth) {
          padding: 0;
        }
        &:hover,
        &.active{
          text-decoration: none;
          font-weight: 600;
          // letter-spacing: 0.005em;
        }
        &:last-child{
          margin-right: 0;
        }
        &.dropDown{
          margin-right: 11%;
          @media (max-width: $mobileWidth){
            margin-right: 0;
          }

          &::after{
            @include background-image("./icons/arrow-down-menu.svg", 13, 8);
            position: absolute;
            right: -23px;
            top: 8px;
          }
          &.search{
            &::after{
              display: none;
            }
            @media (max-width: $mobileWidth){
              // margin-right: 30px;
            }
          }
          &.active{
            &::after{
              transform: rotate(180deg);
            }
          }
        }
        &.search{
          @include background-image("./icons/search-icon-light.svg", 21.28, 23.28);
          background-size: 100%;
          display: none;
          @media (max-width: $mobileWidth) {
            @include background-image("./icons/icon-search-autocomplete.svg", 18, 19);
            display: inline-block;
            background-size: contain;
          }
        }
        &.menu-hamburguer{
          // @include background-image("./icons/menu-hamburguer-light.svg", 19.2, 16.8);
          // background-size: 100%;
          // display: none;
          // @media (max-width: $mobileWidth) {
          //   @include background-image("./icons/menu-hamburguer-light.svg", 16, 14);
          //   display: inline-block;
          // }
          width: 21.28px;
          height: 23.28px;
          position: relative;
          display: none;

          @media (max-width: $mobileWidth) {
            width: 16px;
            height: 17px;
            display: inline-block;
          }

          .hamburger-menu {
            z-index:2;
            position: absolute;
            top: 0;
            right: 0;
            width: 21.28px;
            height: 23.28px;
            cursor: pointer;
          }

          .bar,
          .bar:after,
          .bar:before {
            width: 21.28px;
            height: 2px;
          }

          .bar {
            position: relative;
            transform: translateY(8px);
            background: rgba(255, 255, 255, 1);
            transition: all 0ms 300ms;

            @media (max-width: $mobileWidth) {
              background-color: $glava-black;

              @include site(weber){
                background-color: $weber-black;
              }

              @include site(gyproc){
                background-color: $gyproc-black;
              }
            }
          }

          .bar.animate {
            background: rgba(255, 255, 255, 0);
          }

          .bar:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 7px;
            background: rgba(255, 255, 255, 1);
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);

            @media (max-width: $mobileWidth) {
              background-color: $glava-black;

              @include site(weber){
                background-color: $weber-black;
              }

              @include site(gyproc){
                background-color: $gyproc-black;
              }
            }
          }

          .bar:after {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            background: rgba(255, 255, 255, 1);
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);

            @media (max-width: $mobileWidth) {
              background-color: $glava-black;

              @include site(weber){
                background-color: $weber-black;
              }

              @include site(gyproc){
                background-color: $gyproc-black;
              }
            }
          }

          .bar.animate:after {
            top: 0;
            transform: rotate(45deg);
            transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
          }

          .bar.animate:before {
            bottom: 0;
            transform: rotate(-45deg);
            transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
          }

        }
      }
      .hide-responsive{
        @media (max-width: $mobileWidth) {
          display: none;
        }
      }
    }
  }

  // Dark color
  // &.dark{
  //   .mainMenu__wrapper{
  //     background: #2e2f37;
  //     .main__menu{
  //       &--logo{
  //         background: none;
  //         .logo{
  //           .logo-dark{
  //            display: none;
  //           }
  //           .logo-light{
  //             display: block;
  //           }
  //         }
  //       }
  //       &--item{
  //         color: #dddddd;
  //         &:hover,
  //         &.active{
  //           color: #ffffff;
  //         }
  //         &.dropDown{
  //           &::after{
  //             @include background-image("./icons/arrow-down-menu-light.svg", 13, 8);
  //           }
  //           &.search::after{
  //             display: none;
  //           }
  //         }
  //         &.search{
  //           // @include background-image("./icons/search-icon-light.svg", 21.28, 23.28);
  //         }
  //         &.menu-hamburguer{
  //           // @include background-image("./icons/menu-hamburguer-light.svg", 19.2, 16.8);
  //         }
  //       }
  //     }
  //   }
  // }
  // Dark color

  .dropDownMenu{
    display: none;
    background: #2e2f37;
    padding: 50px 0 135px 0;
    position: absolute;
    width: 100%;
    top: 72px;

    @media (max-width: 744px){
      top: 60px;
      padding: 25px 0 45px 0;
    }

    &.active{
      @media (max-width: $mobileWidth){
        display: block;
      }
    }

    .overlayPage{
      width: 100vw;
      height: 100vh;
      background: rgba($color: #2e2f37, $alpha: .6);
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &__wrapper{
      &--item{
        display: block;
        margin-bottom: 25px;
        position: relative;
        padding-right: 50px;
        &:last-child{
          margin-bottom: 0;
        }
        &.see-all{
          &:last-child{
            margin-bottom: 30px;
          }
        }
        a{
          color: #ffffff;
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;
          letter-spacing: 0.24px;
          text-decoration: none;
          position: relative;

          &:hover{
            &::after{
              position: absolute;
              height: 20.13px;
              width: 27.83px;
              right: -45px;
              bottom: 5px;
              @include background-image("./icons/arrowItemMenuDropDown.svg", 20.13px, 27.83px);
            }
          }

        }
      }
    }

    .search{
      &__wrapper{
        @media (max-width: $mobileWidth){
          padding: 0 5px;
        }
        .searchbar {
          border-bottom: 2px solid $textDark2;
          ::-webkit-input-placeholder {color: #ffffff;}
          :-ms-input-placeholder {color: #ffffff;}
          ::placeholder {color: #ffffff;}
          @media (max-width: $mobileWidth){
            margin: 60px 0;
          }
          input {
            background-color: #343437;
            background-image: url($path+"icons/search-icon-light.svg");
            background-position: 25px center;
            background-repeat: no-repeat;
            color: #ffffff;
            font-weight: 500;
            height: 100px;
            @media (max-width: $mobileWidth){
              padding: 25px 25px 25px 58px;
              @include font-size(18);
              border-bottom: 2px solid #ffffff;
              height: 70px;
              background-size: 16px 17px;
              background-position: 21px center;
            }

            &:active {
              outline: none;
              box-shadow: none;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          button {
            border: 0;
            height: 94px;
            @media (max-width: $mobileWidth){
              height: 68px;
            }
            &.arrow-button {
              background-color: transparent;
              width: auto;
              position: absolute;
              right: 15px;
              width: 70px;
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#343437+0,343437+100&0+0,1+17 */
              background: -moz-linear-gradient(left,  rgba(52,52,55,0) 0%, rgba(52,52,55,1) 17%, rgba(52,52,55,1) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(left,  rgba(52,52,55,0) 0%,rgba(52,52,55,1) 17%,rgba(52,52,55,1) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to right,  rgba(52,52,55,0) 0%,rgba(52,52,55,1) 17%,rgba(52,52,55,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00343437', endColorstr='#343437',GradientType=1 ); /* IE6-9 */

              &::after {
                display: none;
                position: relative;
              }
              &:active {
                outline: none;
                border: none;
                box-shadow: none;
              }
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
            img {
              @media (max-width: $mobileWidth){
                width: 20px;
                height: auto;
              }
            }
          }
        }
      }
    }

    .dropDownItems,
    .search{
      display: none;
      &.active{
        display: block;
      }
    }
  }

  .search, .searchbar{
    &__wrapper, &__wrapper.searchbar-autocomplete {
      .searchbar {
        position: relative;
        display: flex;
        margin: 0;
        input {
          background-color: #fff;
          padding: 14px 32px 14px 44px;
          width: 100%;
          height: 48px;
          border: none;
          border-radius: 0!important;
          background-image: url($path+"icons/search-icon.svg");
          background-position: 16px center;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          @include font-size(16);
          font-weight: 600;
          line-height: 100%;
          color: #1b1b1b;
          letter-spacing: normal;
          &::placeholder {
            color:$gray-600;
            font-weight: 500;
            @include font-size(16);
          }

          @include site(weber){
            @media (min-width: 992px){
              border: 1px solid $gray-200;
            }
          }
        }
        .result-autocomplete {
          top: 100%;
        }
      }
    }
  }

  .sideBarMenu{
    position: relative;
    position: fixed;
    bottom: 0;
    left: 100%;
    width: 100vw;
    height: calc(100% - 72px);
    z-index: 0;
    transition: all 300ms;

    @media (max-width: 744px){
      height: calc(100% - 60px);
    }

    &.active{
      left: 0;
      z-index: 30;
      @media (min-width: 992px){
        display: none;
      }
      .overlayPage{
        left: 0;
        @media (min-width: 992px){
          display: none;
        }
      }
    }

    .overlayPage{
      width: 100%;
      height: calc(100% - 72px);
      background: rgba($color: #2e2f37, $alpha: .6);
      position: fixed;
      bottom: 0;
      left: 100%;
      z-index: -1;
      transition: all 300ms;
      @media (max-width: 744px){
        height: calc(100% - 60px);
      }
    }

    &__wrapper{
      z-index: 1;
      background:$glava-black;
      width: 100%;
      max-width: 50%;
      position: absolute;
      right: 0;
      height: 100%;

      @include site(weber){
        background: $weber-anthracite-700;
      }

      @include site(gyproc){
        background: $gyproc-blue;
      }

      @media (max-width: 744px){
        max-width: 100%;
      }

      &:after{
        content: "";
        position: absolute;
        height: 80px;
        width: 100%;
        bottom: 0;
        left: 0;
        pointer-events: none!important;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2f37+0,2a2a2d+100&0+0,1+33 */
        background: -moz-linear-gradient(top,  rgba(46,48,55, 0) 0%, rgba(46,48,55, 1) 33%, rgba(46,48,55, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(46,48,55, 0) 0%,rgba(46,48,55, 1) 33%,rgba(46,48,55, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(46,48,55, 0) 0%,rgba(46,48,55, 1) 33%,rgba(46,48,55, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002a2a2d', endColorstr='#2e2f37',GradientType=0 ); /* IE6-9 */

        @media (max-width: $mobileWidth){
          height: 60px;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1b1b1b+0,1b1b1b+100&0+0,1+33 */
          background: -moz-linear-gradient(top,  rgba(27,27,27,0) 0%, rgba(27,27,27,1) 33%, rgba(27,27,27,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(27,27,27,0) 0%,rgba(27,27,27,1) 33%,rgba(27,27,27,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(27,27,27,0) 0%,rgba(27,27,27,1) 33%,rgba(27,27,27,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001b1b1b', endColorstr='#1b1b1b',GradientType=0 ); /* IE6-9 */

          @include site(weber){
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#23242b+0,23242b+100&0+0,1+33 */
            background: -moz-linear-gradient(top,  rgba(35,36,43,0) 0%, rgba(35,36,43,1) 33%, rgba(35,36,43,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(35,36,43,0) 0%,rgba(35,36,43,1) 33%,rgba(35,36,43,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(35,36,43,0) 0%,rgba(35,36,43,1) 33%,rgba(35,36,43,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0023242b', endColorstr='#23242b',GradientType=0 ); /* IE6-9 */
          }

          @include site(gyproc){
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#005eb8+0,005eb8+100&0+0,1+33 */
            background: -moz-linear-gradient(top,  rgba(0,94,184,0) 0%, rgba(0,94,184,1) 33%, rgba(0,94,184,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,94,184,0) 0%,rgba(0,94,184,1) 33%,rgba(0,94,184,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,94,184,0) 0%,rgba(0,94,184,1) 33%,rgba(0,94,184,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00005eb8', endColorstr='#005eb8',GradientType=0 ); /* IE6-9 */
          }
        }

      }



      &--headerSideBar{
        position: absolute;
        width: 100%;
        height: 120px;
        top: 0;
        left: 0;
        z-index: 1;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2f37+0,2a2a2d+100&1+62,0+100 */
        background: -moz-linear-gradient(top,  rgba(46,48,55, 1) 0%, rgba(46,48,55, 1) 62%, rgba(46,48,55, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(46,48,55, 1) 0%,rgba(46,48,55, 1) 62%,rgba(46,48,55, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(46,48,55, 1) 0%,rgba(46,48,55, 1) 62%,rgba(46,48,55, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2f37', endColorstr='#002a2a2d',GradientType=0 ); /* IE6-9 */

        @media (max-width: $mobileWidth){
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2f37+0,2a2a2d+100&1+82,0+98 */
          background: -moz-linear-gradient(top,  rgba(46,48,55, 1) 0%, rgba(46,48,55, 1) 82%, rgba(46,48,55, 0) 98%, rgba(42, 42, 45, 0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(46,48,55, 1) 0%,rgba(46,48,55, 1) 82%,rgba(46,48,55, 0) 98%,rgba(46,48,55, 0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(46,48,55, 1) 0%,rgba(46,48,55, 1) 82%,rgba(46,48,55, 0) 98%,rgba(46,48,55, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2f37', endColorstr='#002a2a2d',GradientType=0 ); /* IE6-9 */
        }

        .logoWrapper{
          position: absolute;
          top: 0;
          left: 70px;
          display: flex;
          background: $brandYellow2;
          justify-content: center;
          align-items: flex-end;
          width: 168px;
          height: 100px;
          display: none;
          @media (max-width: $mobileWidth){
            display: flex;
            width: 124px;
            height: 77px;
            left: 20px;
          }
          .logo{
            width: 68px;
            height: 16px;
            margin-bottom: 30px;
            display: block;
            img {
              width: 68px;
              height: 16px;
            }
          }
        }

        .btnClose{
          position: absolute;
          top: 55px;
          right: 80px;
          background: none;
          border: none;
          @media (max-width: $mobileWidth){
            top: 35px;
            right: 21px;
          }
          &:active,
          &:focus{
            outline: none;
          }
        }
      }
      &--content{
        padding: 0 0 0 0;
        overflow-Y: auto;
        height: 100%;

        &::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #2b2b2c;
        }

        &::-webkit-scrollbar
        {
          width: 10px;
          background-color: #2b2b2c;
        }

        &::-webkit-scrollbar-thumb
        {
          background-color: #000000;
          border: 2px solid #2b2b2c;
        }

        @media (max-width: 575px){
          max-width: 100%;
        }

        .items{
          padding-bottom: 100px;
          @media (max-width: $mobileWidth){
            padding-bottom:70px;
          }
          span{
            display: block;
            padding: 20px 28px;
            border-bottom: 1px solid $gray-800;

            @include site(gyproc){
              border-bottom-color: $white;
            }

            .item{
              display: inline-block;
              color: #ffffff;
              text-decoration: none;
              letter-spacing: 0.0175em;
              font-weight: 450;
              position: relative;
              @include font-size(18);
              line-height: 24px;

              @media(max-width: 744px){
                @include font-size(16);
                line-height: 20px;
              }

              &:hover{
                text-decoration: underline;
                // &:after{
                //   position: absolute;
                //   height: 16px;
                //   width: 22px;
                //   right: -38px;
                //   top: 8px;
                //   @include background-image("./icons/arrowItemMenuDropDown.svg", 16px, 22px);
                // }
              }
              &.hiddenMobile{
                display: none;
                @media (max-width: $mobileWidth){
                  display: inline-block;
                }
              }
            }
          }

          .dropDown{
            border-bottom: none;
            margin-bottom: 0px;
            padding-bottom: 0px;
            //display: none;
            display: block;
            padding-right: 0;
            border-bottom: 1px solid rgba($color: #3a3c44, $alpha: 1);

            .dropDownTitle{
              color: #ffffff;
              text-decoration: none;
              //@include font-size(24);
              line-height: 28px;
              letter-spacing: 0.24px;
              font-weight: 600;
              position: relative;
              display: block;
              border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 1);
              font-weight: 450;
              @include font-size(18);
              line-height: 24px;
              @media(min-width: 1279px){
                @include font-size(24);
                border-bottom: 0;
              }

              @media(max-width: $mobileWidth){
                &:after{
                  position: absolute;
                  height: 6px;
                  width: 12px;
                  right: 0;
                  top: 13px;
                  @include background-image("./icons/arrow-down-menu-light.svg", 12px, 6px);
                }
              }

              &.active{
                margin-bottom: 0;

                @media(max-width: $mobileWidth){
                  &:after{
                    transform: rotate(180deg);
                  }
                }

                @media(min-width: 1279px){
                  &:after{
                    transform: rotate(90deg);
                    position: absolute;
                    height: 16px;
                    width: 22px;
                    right: -38px;
                    top: 8px;
                    @include background-image("./icons/arrowItemMenuDropDown.svg", 16px, 22px);
                  }
                }
              }

            }
            .noDropDownTitle{
              color: #ffffff;
              text-decoration: none;
              @include font-size(24);
              line-height: 28px;
              letter-spacing: 0.24px;
              font-weight: 450;
              position: relative;
              display: block;
              // padding-bottom: 16px;
              // border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: .3);
              // margin-bottom: 16px;
              @include font-size(20);
              @media(min-width: 1279px){
                border-bottom: 0;
                @include font-size(24);
              }
            }
            .dropDownItems{
              display: none;
              padding-bottom: 0!important;
              &.active{
                display: block;
              }
              .dropDownItem{
                display: block;
                border-bottom: none;
                margin-bottom: 20px;
                padding-bottom: 0;
                &:last-child{
                  margin-bottom: 40px;
                }
                &:first-child{
                  margin-top: 25px;
                }
                a{
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 100%;
                  letter-spacing: 0.24px;
                  color: #FFFFFF;
                }
              }
            }

            a{
              display: inline-block !important;

              @media(max-width: $mobileWidth){
                display: block !important;
              }

              @media(min-width: $mobileWidth){
                &:hover{
                  text-decoration: underline;
                  &:after{
                    position: absolute;
                    height: 16px;
                    width: 22px;
                    right: -38px;
                    top: 8px;
                    @include background-image("./icons/arrowItemMenuDropDown.svg", 16px, 22px);
                  }
                }
              }

              @media(min-width: 1279px){
                @include font-size(24);
              }
            }
          }

        }
        // .subItems{
        //   overflow: hidden;
        //   border-top: 1px solid #696973;
        //   padding-top: 24px;
        //   margin-bottom: 20px;

        //   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        //     &:last-child{
        //       margin-bottom: 100px;
        //     }
        //   }

        //   @media (max-width: $mobileWidth){
        //     padding-top: 20px;
        //   }

        //   span{
        //     display: block;
        //     width: 50%;
        //     float: left;
        //     .item{
        //       display: inline-block;
        //       color: #ffffff;
        //       text-decoration: none;
        //       font-weight: 500;
        //       @include font-size(18);
        //       line-height: 28px;
        //       letter-spacing: 0.24px;
        //       margin-bottom: 24px;
        //       @media (max-width: $mobileWidth){
        //         @include font-size(16);
        //         letter-spacing: 0.24px;
        //         margin-bottom: 15px;
        //       }
        //       &:hover{
        //         text-decoration: underline;
        //       }
        //     }
        //   }
        // }
      }
    }
  }

  .header__bottom {
    background-color: $brandYellow3;
    padding: 12px 0;

    @include site(weber){
      background-color: $weber-black-50;
    }

    @include site(gyproc){
      background-color: $gyproc-blue-750;
    }

    @media (max-width: 1920px){
      // padding: 0 4.7% 24px;
    }
    @media (max-width: $mobileWidth){
      display: none;
    }
  }
}

.mainNav.fixed{
  padding-bottom: 0;
  @media(max-width: $mobileWidth) {
    padding-bottom: 0;
  }

  header{
    position: fixed;
    width: 100%;
    top: -142px;
    transition:All 300ms ease-in-out;
    -webkit-transition:All 300ms ease-in-out;
    -moz-transition:All 300ms ease-in-out;
    -o-transition:All 300ms ease-in-out;

    -webkit-box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.1);

    .mainMenu__wrapper{
      position: relative;
      height: 74px;

      @include site(glava){
        background-color: $glava-black;

        @media (max-width: $mobileWidth) {
          background-color: $glava-yellow;
        }
      }

      .container,.row{
        height: 100%;
      }

      @media (max-width: $mobileWidth) {
        height: 72px;
      }

      @media (max-width: 744px) {
        height: 60px;
      }

      .main__menu {
        height: 100%;
        &.bot {
          order: unset;
          display: flex;
          align-items: center;
          padding: 0;

          .main__menu--item{

          }
        }
        &.top {
          display: none;
          @media (max-width: $mobileWidth){
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: none;
          }
          .main__menu--logo{
            display: block;
          }
        }
        &.bot {
          @media (max-width: $mobileWidth){
            display: none;
          }
        }
        &--logo {
          display: none;
        }
      }
      .header__bottom {
        position: absolute;
        width: 36%;
        right: 0;
        margin: 0 15px;
        top: 13px;
        // transform: translateY(-50%);
        padding: 0;
        transition: .35s;
        &:focus-within {
          width: calc(100% - 30px);
        }
      }
    }

    .dropDownMenu{
      top: 120px;
      @media (max-width: $mobileWidth){
        top: 70px;
      }
      @media (max-width: 744px){
        top: 58px;
      }
    }

  }

  &.active{
    header{
      top: 0;
    }
  }
}
