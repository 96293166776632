.faq {
  margin: 50px 0 100px;
  @media (max-width: 991px) {
    margin: 60px 0 40px;
  }
  .breadcrumbs{
    margin-bottom: 84px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }
  &__header {
    margin-bottom: 56px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
      .faq__date {
        display: none;
      }
    }
    h1 {
      @include font-size(52);
      line-height: 1.15;
      margin-bottom: 0;
      @media (max-width: 991px) {
        @include font-size(28);
        line-height: 1.43;
      }
    }
  }
  &__date {
    color: #8E8E8E;
    @include font-size(14);
    line-height: 1.71;
    letter-spacing: 0.005em;
    margin-top: 24px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
      margin-top: 0;
    }
  }
  &__content {
    .img {
      margin-bottom: 60px;
      @media (max-width: 991px) {
        margin-bottom: 24px;
      }
      @media (max-width: 991px) {
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        padding-top: calc((464 / 608) * 100vw);
      }
      + .faq__date {
        @media (max-width: 991px) {
          margin-top: -16px;
        }
      }
    }
    .faq__date {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }
    .styled-text {
      @include font-size(18);
      line-height: 1.89;
      letter-spacing: 0.005em;
      @media (max-width: 991px) {
        @include font-size(17);
        line-height: 1.65;
      }
      >* {
        &:last-child {
          margin-bottom: 0!important;
        }
      }
      p {
        margin-bottom: 16px;
        @media (max-width: 991px) {
          margin-bottom: 24px;
        }
      }
      ul {
        list-style: none;
        padding-left: 28px;
        margin-bottom: 16px;
        @media (max-width: 991px) {
          margin-bottom: 24px;
        }

        li {
          margin-bottom: 6px;
          &:last-child, >*:last-child {
            margin-bottom: 0;
          }
          &:before {
            content: '';
            background-color: #222222;
            border-radius: 2px;
            width: 4px;
            height: 4px;
            margin-left: -16px;
            margin-right: 12px;
            vertical-align: middle;
            display: inline-block;
          }
        }
      }
    }
  }
  &__side {
    @include font-size(18);
    line-height: 1.56;
    letter-spacing: 0.005em;
    background: #E7F3FA;
    padding: 84px 48px 67px 44px;
    max-width: 466px;
    background-image: url("../images/icons/wrench.svg");
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 40px 44px;
    @media (max-width: 991px) {
      @include font-size(17);
      line-height: 1.65;
      padding: 60px 17px 60px 31px;
      margin-top: 30px;
      background-position: 27px 31px;
      margin-top: 30px;
      max-width: 100%;
    }
    >* {
      &:last-child {
        margin-bottom: 0;
      }
    }
    h2 {
      @include font-size(22);
      line-height: 1.41;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        line-height: 1.18;
        margin-bottom: 18px;
      }
    }
    ul {
      list-style: none;
      padding-left: 28px;
      @media (max-width: 991px) {
        padding-left: 26px;
      }
      li {
        margin-bottom: 6px;
        &:last-child, >*:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: '';
          background-color: #222222;
          border-radius: 2px;
          width: 4px;
          height: 4px;
          margin-left: -16px;
          margin-right: 12px;
          vertical-align: middle;
          display: inline-block;
        }
      }
    }
  }
  &__feedback {
    @include font-size(14);
    line-height: 1.71;
    margin-top: 60px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin-top: 52px;
    }
    &__yes, &__no {
      font-size: 0.001px;
      color: transparent;
      background: #FFFFFF;
      border-radius: 0;
      border: 1px solid #222222;
      width: 48px;
      height: 48px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 12px;
        left: 12px;
        @include background-image("icons/arrowShort-right-24.svg", 24, 24);
        transform: rotate(90deg);
      }
    }
    &__no {
      margin-left: -1px;
      &::after {
        transform: rotate(270deg);
      }
    }
    &__label {
      font-weight: 600;
      margin-left: 18px;
      order: 3;
    }

  }
}
