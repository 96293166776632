.jobs-link-list {
  border-top: 2px solid $glava-black;

  @include site(weber){
    border-top-color: $weber-black
  }

  @include site(gyproc){
    border-top-color: $gyproc-black
  }

  &__item {
    @include font-size(20);
    line-height: 1.4;
    padding: 28px 44px 28px 24px;
    border-bottom: 1px solid $gray-400;
    display: flex;
    position: relative;
    @media (max-width: 991px) {
      @include font-size(19);
      padding: 24px 40px 24px 18px;
      line-height: 1.33;
      flex-direction: column;
      background-position: right 18px center;
    }
    &:after, &:before {
      @include background-image("icons/arrow-right-28-no-tail.svg",28, 28);
      position: absolute;
      right: 16px;
      top: calc(50% - 14px);
      @media (max-width: 991px) {
        right: 20px;
      }
    }
    &:after {
      background-image: url("../images/icons/arrow-right-28.svg");
      opacity: 0;
    }
    &:hover {
      text-decoration: none;
      @media (min-width: 992px) {
        background-color: #3a3c4508;
        &:after {
          opacity: 1;
        }
        &:before {
          opacity: 0;
        }
      }
    }
    &__title {
      font-weight: 600;
      // padding-right: 10px;
      flex: 3;
      @media (max-width: 991px) {
        margin-bottom: 6px;
      }
    }
    &__info {
      flex: 2;
      padding-left: 10px;
      @media (max-width: 991px) {
        padding: 0;
      }
    }
  }
}
.select-jobs-link-list  {
  width: 320px!important;
  @media (max-width: 991px) {
    width: 125px!important;
  }
  &.bootstrap-select > .dropdown-toggle {
    padding: 16px 16px 16px 22px;
    border-radius: 0px;
    border: 2px solid #3A3C45;
    background-color: #fff;
    max-width: 100%;
    @media (max-width: 991px) {
      border-width: 1px;
      padding: 11px 17px 10px 19px;
    }
    &::after {
      border: none;
      @include background-image("icons/arrow-down-24.svg",24, 24);
      background-size: contain;
      background-position: center;
      @media (max-width: 991px) {
        @include background-image("icons/arrow-down-16.svg",16, 16);
      }
    }
    &:focus:not(:focus-visible) {
      outline: 0!important;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      border: 2px solid #3A3C45;
      background-color: #fff;
    }
    .filter-option-inner-inner {
      @include font-size(17);
      line-height: 1.65;
      text-overflow: ellipsis;
      @media (max-width: 991px) {
        @include font-size(16);
        line-height: 1.31;
      }
    }
  }
  .dropdown-menu {
    border-radius: 0;
    transform: unset!important;
    top: 100%!important;
    max-width: calc(100vw - 40px) !important;
    width: max-content !important;
    margin: 0;
    .dropdown-item {
      @include font-size(16);
      padding: 13px 23px;
      white-space: normal;
      &.active, &:active {
        background-color: #f8f9fa;
      }
    }
  }
}
