.table__container {
    overflow-x: auto;
    h2 {
        margin-bottom: 40px;
    }
    @media (max-width: 992px) {
        overflow: auto;
        width: 100%;
    }
    .table {
        th,
        td {
            padding-top: 24px;
            padding-bottom: 24px;
            @media (max-width: 992px) {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
        thead {
            tr {
                th {
                    @media (max-width: 992px) {
                        font-size: 10px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

.table__glava {
    thead {
        tr {
            &:first-child {
                th {
                    border-top: 0;
                    font-size: 15px;
                    @media (max-width: 992px) {
                        font-size: 10px;
                        line-height: 16px;
                    }
                    &:first-child {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 24px;
                        padding-left: 0;
                        letter-spacing: 0.24px;
                        color: #222222;
                        vertical-align: middle;
                        text-align: left;
                        // border-bottom: 2px solid #3a3c45;
                        border-bottom-color: #3a3c45;
                        @media (max-width: 992px) {
                            font-size: 10px;
                            line-height: 16px;
                            border-bottom: 1px solid rgba(58, 61, 69, 0.33);
                        }
                    }
                }
                @media (max-width: 992px) {
                    min-width: 150px;
                }
            }
            th {
                min-width: 120px;
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.36px;
                color: #222222;
                @media (max-width: 1440px) {
                    min-width: 100px;
                }
                @media (max-width: 992px) {
                    min-width: 150px;
                }
            }
            &:last-child {
                //border-bottom: 2px solid #3a3c45;
            }
            &:last-child {
                //border-bottom: 2px solid #3a3c45;
                @media (max-width: 992px) {
                    //border-bottom: 1px solid #3a3c45;
                }
            }
        }
    }
    tbody {
        border-top: 2px solid #3a3c45;
        td {
            text-align: center;
            min-width: 120px;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #222222;
            &:first-child {
                min-width: 150px;
                padding-left: 0;
                font-weight: 600;
                text-align: left;
            }
            @media (max-width: 1440px) {
                min-width: 100px;
            }
            @media (max-width: 992px) {
                min-width: 150px;
            }
        }
    }
    tr {
        th,
        td {
            border-right: 1px solid rgba(58, 61, 69, 0.33);
            border-bottom: 1px solid rgba(58, 61, 69, 0.33);
            border-color: rgba(58, 61, 69, 0.33);
            &:last-child {
                border-right: 0;
            }
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
    }
}

.table__gyproc {
    //border-bottom: 4px solid $textDark2;
    table-layout: fixed;
    width: auto;
    @media (max-width: 992px) {
        table-layout: auto;
    }
    thead {
        tr {
            th {
                @include font-size(15);
                border-top: 0;
                //border-bottom: 2px solid $textDark2;
                vertical-align: baseline;
                padding: 24px;
                padding-top: 0 !important;
                color: #222222;
                border-right: 1px solid rgba(58, 60, 69, 0.33);
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.24px;
                color: #222222;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
                small {
                    font-size: 13px;
                    font-weight: 600;
                    @media (max-width: 992px) {
                        font-size: 10px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 24px;
                border-color: rgba(58, 60, 69, 0.33);
                @include font-size(15);
                min-width: 159px;
                max-width: 159px;
                border-right: 1px solid rgba(58, 60, 69, 0.33);
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.24px;
                color: #222222;
                vertical-align: middle;
                strong {
                    color: #222222;
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    text-align: center;
                    border-right: 0;
                    padding: 0;
                }
                button {
                    display: block;
                    width: 100%;
                    min-height: 76px;
                }
            }
            &[data-refer] {
                display: none;
                td {
                    border: 0;
                    white-space: nowrap;
                    vertical-align: baseline;
                }
            }
            &.moreInfo{
                td {
                    &:last-child {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.table__weber {
    thead {
        tr {
            th {
                border-top: 0;
                border-bottom: 2px solid #3a3c45;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.24px;
                color: #222222;
                width: 50%;
                padding-right: 0;
                padding-left: 0;
                @media (max-width: 992px) {
                    font-size: 15px !important;
                    line-height: 24px !important;
                }
            }
        }
    }
    tbody {
        td {
            border-bottom: 1px solid rgba(58, 60, 69, 0.33);
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #222222;
            width: 50%;
            &:first-child {
                font-weight: 600;
                padding-left: 0;
                border-right: 1px solid rgba(58, 60, 69, 0.33);
            }
        }
    }
}

.table__u__verdi{
    border-right: 1px solid rgba(58, 61, 69, 0.33);
    tbody{
        border-top: 0;
        tr{
            &:first-child{
                td{
                    border-top: 0;
                    &:first-child{
                        max-width: 258px;
                        font-weight: 500;
                    }
                }
            }
            td{
                min-width: 120px;
                &:first-of-type{
                    text-align: left;
                    padding-left: 0;
                    font-weight: 500;
                    min-width: 120px;
                }

            }
            &:nth-child(2), &:nth-child(3){
                td{
                    padding: 11px 12px;
                    &:first-of-type{
                        font-weight: 500;
                        text-align: center;
                        padding-left: 12px;
                    }
                }
            }
        }
    }

}

.data__table {
    dl {
        dt {
            width: auto;
            display: inline-block;
            margin-right: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #222222;
            &:first-child {
                width: 100%;
            }
            @media(max-width: 992px) {
                margin-right: 0;
            }
        }
        dd {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #222222;
            &:first-child {
                width: 100%;
            }
            &:nth-child(5) {
                font-weight: 600;
            }
            display: inline-block;
            width: auto;
            margin-bottom: 30px;
        }
    }
}

.btn__invisible {
    border: 0;
    line-height: 23px;
    font-size: 23px;
    background: transparent;
    &.rotate {
        transform: rotate(45deg);
    }
    &:focus {
        outline: none;
    }
}
