.video-carousel {
  margin: 100px 0 80px;
  padding: 80px 0 155px;
  background: $glava-black;
  overflow: hidden;

  @include site(weber){
    background: $weber-anthracite-500;
  }

  @include site(gyproc){
    background: $gyproc-aqua-300;
  }

  @media (max-width: 991px) {
    padding: 54px 0 68px;
    margin: 50px 0 52px;
  }

  .div-line-white{
    @include site (weber){
      border-bottom-color: $weber-yellow;
    }

    @include site (gyproc){
      border-bottom-color: $gyproc-black;
    }
  }

  .button-wrapper{
    display: none;
    align-items: center;

    @media (max-width: 991px) {
      display: flex;
    }

    .button {
      margin: 0 auto;
    }
  }
  &__header {
    margin-bottom: 40px;
    display: flex;
    h2 {
      color: #fff;
      @include font-size(32);
      line-height: 1.25;
      margin: 0;

      @include site(gyproc){
        color: $gyproc-black;
      }

      @media (max-width: 991px) {
        @include font-size(24);
        line-height: 1.17;
        letter-spacing: 0.12px;
      }
    }
    a {
      color: #fff;
      @include font-size(16);
      line-height: 1.81;
      letter-spacing: -0.005em;
      padding-right: 26px;
      padding-left: 16px;
      display: block;
      min-width: max-content;
      margin-left: auto;

      @include site(gyproc){
        color: $gyproc-black;
      }

      @media (max-width: 991px) {
        line-height: 1.31;
        letter-spacing: 0.01em;
        display: none;
      }
      &:hover {
        color: #fff;
        text-decoration: none;

        @include site(gyproc){
          color: $gyproc-black;
        }
      }
      &:after {
        content: '';
        @include background-image("icons/arrow-right-20-w.svg", 20, 20);
        display: inline-block;
        margin-right: -26px;
        margin-left: 6px;
        vertical-align: sub;

        @include site(gyproc){
          @include background-image("icons/arrow-right-20.svg", 20, 20);
        }
      }
    }
  }
  &__slick {
    flex-wrap: nowrap;
    @media (max-width: 991px) {
      margin: 0 -3px;
      max-width: 82vw;
    }
    .slick-arrow {
      background: $gray-800;
      border-radius: 40px;
      width: 80px;
      height: 80px;
      margin-top: calc(10.6% - 10px);
      top: 0;
      z-index: 2;

      @include site(weber){
        background: $weber-anthracite-700;
      }

      @include site(gyproc){
        background: $gyproc-blue;
      }

      @media (max-width: 991px) {
        margin-top: calc((70 / 440) * 100%);
        margin-left: -15px;
        margin-right: -15px;
      }
      @media (max-width: 767px) {
        margin-top: calc((140 / 440) * 100%);
        width: 60px;
        height: 60px;
        margin-left: -5px;
        margin-right: -5px;
      }
      &::before {
        content: '';
        @include background-image("icons/arrow-right-24-w.svg", 24, 24);
        opacity: 1;
      }
      &:hover, &:focus {
        background: $gray-800;

        @include site(weber){
          background: $weber-anthracite-700;
        }

        @include site(gyproc){
          background: $gyproc-blue;
        }
      }
      &.slick-prev{
        @media (max-width: 575px) {
          left: -10px;
        }
        &:before {
          transform: rotate(180deg);
        }
      }

      &.slick-disabled {
        display: none!important;
      }
    }
    .slick-next {
      @media (max-width: 616px) {
        left: 480px;
        right: unset;
      }
      @media (max-width: 575px) {
        left: calc(100vw - 74px);
      }
    }
    .slick-list {
      @media (max-width: 991px) {
        overflow: visible;
      }
    }
    .slick-track{
      margin-left: 0;
    }
  }
  &__item {
    display: block;
    max-width: unset;
    &:first-child {
      width: 33.334%;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    @media (max-width: 991px) {
      padding: 0 3px;
      margin-bottom: 64px;
    }
    .img {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        margin-bottom: 6px;
      }
      .videoWrapper, img {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
    &__title {
      display: block;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
      &:after {
        content: '';
        @include background-image("icons/arrow-right-24-w.svg", 24, 24);
        display: inline-block;
        margin-left: -24px;
        vertical-align: middle;

        @include site(gyproc){
          @include background-image("icons/arrow-right-24.svg", 24, 24);
        }
      }
      span {
        color: #fff;
        @include font-size(21);
        line-height: 1.33;
        letter-spacing: -0.005em;
        margin-right: 30px;
        vertical-align: middle;

        @include site(gyproc){
          color: $weber-black;
        }
      }
    }

    .video-poster {
      .play-button {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &::after{
          left: 50%;
          margin: -42px -16px;

          @media (max-width: 991px) {
            margin: -30px -11px;
          }
        }
      }
    }
  }
}
