.gallery {
  margin-bottom: 80px;
  overflow: hidden;
  @media (max-width: 992px) {
    margin-bottom: 62px;
  }
  &__item {
    .img {
      margin-bottom: 20px;
      @media (max-width: 992px) {
        margin-bottom: 6px;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    h2 {
      @include font-size(24);
      line-height: 1.167;
      letter-spacing: -0.005em;
      @media (max-width: 992px) {
        @include font-size(22);
        line-height: 1.273;
      }
      span {
        position: relative;
        // color: red;
        &::after{
          @include background-image("./icons/arrow-right-24.svg", 24, 24);
          position: absolute;
          right: -34px;
          top: 3px;
          background-size: contain;
        }
      }
    }
  }
  &__carousel {
    width: 100%;
    @media (min-width: 993px) {
      width: calc(100% + 30px);
      margin: 0 -15px;
      overflow: hidden;
    }
    &__wrapper {
      .slick-list{
          overflow: visible;
      }
      .slick-slide {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          @media (max-width: 992px){
            padding-left: 0;
          }
      }
      .slick-slider {
        * {
            outline: none !important;
        }
      }
    }
    .carouselControl{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      padding-top: calc((120.5 / 323) * 100%);

      @media (max-width: 1200px){
          padding-top: calc((118 / 323) * 100%);
      }

      @media (max-width: 992px){
          padding-top: calc((114 / 323) * 100%);
      }

      @media (max-width: 768px){
          padding-top: calc((175 / 323) * 100%);
      }

      .slick-prev,
      .slick-next {
        pointer-events: all;
        width: 80px;
        height: 80px;
        z-index: 1;
        background-color: #fff;
        border-radius: 40px;
        margin-top: calc(16% - 9px);
        top: 0;
        @media (max-width: 992px){
          width: 40px;
          height: 40px;
          margin-top: -20px;
        }
        &.slick-disabled{
            display: none!important;
        }
        &::before{
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -12px;
          background-size: contain!important;
          font-size: 1px;
          opacity: 1;
          @include background-image("./icons/arrow-right-24.svg", 24, 24);
        }
      }

      .slick-prev{
        left: -25px;
        @media (max-width: 992px){
          display: none!important;
        }
        &:before {
          transform: rotateY(180deg);
        }
      }

      .slick-next {
        right:  -25px;
        @media (max-width: 992px){
          display: none!important;
        }
      }
    }
    &.macro{
        .slick-slider {
            margin-left: calc(13% - 15px);
            @media (max-width: 768px){
                margin-left: auto;
            }
        }
        .carouselSlioder{
            @media (max-width: 768px){
                width: calc(78.7% + 15px);
            }
            .item{
                .captions{
                    width: 100%;
                }
            }
        }
    }
  }
}
