.contactUs {
  // margin-top: 135px;
  margin-top: 0px;
  margin-bottom: 135px;
  @media (max-width: 992px) {
    margin-top: 40px;
    margin-bottom: 115px;
  }
  h2 {
    font-family: $font-regular;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0.12px;
    color: #2a2a2a;
    opacity: 0.9;
    margin: 0;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 100%;
      margin: 0 0 15px 0;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.24px;
    color: #333333;
    opacity: 0.9;
    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  .contactUs__person {
    &--item {
      *{
        word-break: break-all;
      }
      margin-top: 80px;
      @media (max-width: 992px) {
        margin-top: 25px;
      }
      h3 {
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.24px;
        color: #333333;
        margin-top: 20px;
        @media (max-width: 992px) {
          line-height: 100%;
        }
        // &:after {
        //   margin-top: 10px;
        //   @include background-image("icons/icon-plus.svg", 12, 12);
        //   display: block;
        // }
      }
      &-wrapper{
        display: none;
        >span{
          display: block !important;
        }
      }
    }
    @media (max-width: 992px) {
      display: none;
    }
    &.contactUs__person--mobile {
      display: none;
      padding-right: 0;
      @media (max-width: 992px) {
        display: block;
      }

      .person__slider {
        *{
          &:focus{
            outline: none;
          }
        }
        margin: 0 auto;
        overflow: hidden;
        .slick-list {
          overflow: visible;
          @media (max-width: 992px) {
            padding: 0px 33% 0 0 !important;
          }
          @media (max-width: 768px) {
            padding: 0px 33% 0 0 !important;
          }
          @media (max-width: 480px) {
            padding: 0px 120px 0 0 !important;
          }

          .slick-slide {
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
