// new part horizontal-ruler
[data-portal-component-type="part"].listArticleWrapper{
  .listArticleWrapper__list{
    .line{
      height: 1px;
      background: #D9DBE3;
    }
  }
}

.listArticleWrapper {
  &__list {
    margin-bottom: 125px;
    @media (max-width: 1200px) {
      margin-bottom: 80px;
    }
    .line {
      width: 100%;
      height: 2px;
      background: #3a3c45;
      margin-bottom: 88px;
      display: block;
      @media (max-width: 1200px) {
        margin-bottom: 48px;
      }
    }

    &--item {
      display: none;
      margin-bottom: 100px;
      @media (max-width: 1200px) {
        margin-bottom: 40px;
      }
      a {
        text-decoration: none;
      }
      .image {
        position: relative;
        margin-bottom: 20px;
      }
      h2 {
        font-weight: 600;
        @include font-size(24);
        line-height: 34px;
        letter-spacing: -0.005em;
        margin-bottom: 16px;
        padding-right: 65px;
        @media (max-width: 1200px) {
          @include font-size(20);
          line-height: 28px;
          margin-bottom: 8px;
          letter-spacing: 0.12px;
        }
        span{
          position: relative;
          // color: red;
          &::after{
            @include background-image("./icons/redesign-2023/arrow-right.svg", 24, 24);
            position: absolute;
            right: -32px;
            bottom: 0px;

            @include site(weber){
              @include background-image("./icons/redesign-2023/arrow-right-weber.svg", 24, 24);
            }

            @include site(gyproc){
              @include background-image("./icons/redesign-2023/arrow-right-gyproc.svg", 24, 24);
            }

            @media (max-width: 992px){
              @include background-image("./icons/redesign-2023/arrow-right.svg", 22, 22);

              @include site(weber){
                @include background-image("./icons/redesign-2023/arrow-right-weber.svg", 22, 22);
              }

              @include site(gyproc){
                @include background-image("./icons/redesign-2023/arrow-right-gyproc.svg", 22, 22);
              }
            }
          }
        }
      }
      p {
        font-weight: 500;
        @include font-size(19);
        line-height: 30px;
        letter-spacing: 0.01em;
        color: $gray-800;
        @media (max-width: 992px) {
          @include font-size(16);
          line-height: 25px;
        }
      }
    }
  }
  &--newsListing {
    .listArticleWrapper__list {
      margin-bottom: 60px;
      .line {
        height: 1px;
        background-color: #D9DBE3;
        margin-bottom: 100px;
      }
      .btnShowMore {
        margin-bottom: 100px;
      }
    }
  }
}

