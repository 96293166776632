.ss {
  padding-bottom: 100px;

  @media (min-width: 1200px) {
    margin: 0 140px;
  }



  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }

  &__wrapper {
    max-width: 50%;


    @media (max-width: 768px) {
      max-width: 100%;
    }

  }

  &__text {
    margin: 110px 0 60px 0;

    @media (max-width: 1200px) {
      margin: 38px 0;
    }
  }

  &__title {
    font-size: 64px;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      font-size: 36px;
      margin-bottom: 8px;
    }
  }

  &__your-contribution {
    display: flex;
    flex-direction: column;
    padding: 50px 45px;
    background-color: #fd0;
    color: black;
    border-radius: 0;
    margin-bottom: 50px;
    gap: 30px;

    @media (max-width: 1200px) {
      padding: 30px 25px;
      margin-bottom: 30px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: 75%;
    }
  }

  &__subtitle {
    font-size: 32px;


    @media (max-width: 1200px) {
      font-size: 20px;
    }
  }

  &__percentage {
    font-size: 210px;
    font-weight: 700;
    margin-top: -75px;

    @media (max-width: 1200px) {
      font-size: 120px;
      margin-top: -55px;
    }
  }

  &__social-media {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 12px;

    @media (max-width: 1200px) {
      font-size: 12px;
      gap: 10px;
    }
  }

  &__confirmation-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 50px;

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 30px;
    }
  }

  &__confirmation {
    font-size: 32px;
    max-width: 70%;

    @media (max-width: 1200px) {
      font-size: 20px;
      max-width: 70%;
    }
  }

  &__more-information{
    font-size: 16px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  &__map-text {
    font-size: 16px;
    margin-bottom: 50px;

    @media (max-width: 1200px) {
      font-size: 12px;
      margin-bottom: 30px;
    }


  }

  &__new-contribution {

    padding: 50px 45px;
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    border-radius: 0;
    gap: 30px;


    @media (max-width: 1200px) {
      padding: 30px 25px;
      gap: 20px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: 75%;
    }

  }

  &__register {
    font-size: 32px;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    border-bottom: 2px solid #fff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 1200px) {

      font-size: 20px;
    }
  }

  &__contribution-text {

    font-size: 18px;

    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  &__unsubscribe {

    font-size: 18px;
    padding: 50px 45px;

    @media (max-width: 1200px) {
      padding: 20px 25px;
      font-size: 14px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: 75%;
    }
  }

  &__icon {

    margin-left: 10px;

    &--media {
      height: 30px;
      width: 30px;

      @media (max-width: 1200px) {

        width: 20px;
        height: 20px;
      }
    }

    &--ext {
      margin-left: 15px;
      height: 30px;
      width: 30px;

      @media (max-width: 1200px) {
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
