.card-side-img {
  margin: 80px 0;
  @media (max-width: 991px) {
    margin: 48px 0;
    padding: 34px 0 56px;
  }
  .img {
    display: block;
    height: 100%;
    @media (max-width: 991px) {
      height: initial;
      margin-bottom: 6px;
    }

    img{
      height: 100%;
      object-fit: cover;
      @media (max-width: 991px) {
        height: initial;
        object-fit: initial;
      }
    }
  }
  .button {
    margin: 32px auto 0;
    @media(min-width: 992px) {
      display: none;
    }
  }
  &__head {
    display: flex;
    margin-bottom: 40px;
    align-items: bottom;
    width: 100%;
    @media(max-width: 991px) {
      margin-bottom: 24px;
    }
    h2 {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      flex: 1;
      @media(max-width: 991px) {
        @include font-size(24);
        line-height: 1.17;
        letter-spacing: 0.12px;
        display: block;
      }
    }
    a {
      @include font-size(16);
      line-height: 1.81;
      letter-spacing: -0.005em;
      font-weight: 600;
      margin-left: auto;
      @media(max-width: 991px) {
        display: none;
      }
      &:hover {
        text-decoration: none;
        color: inherit;
      }
      &:after {
        content: '';
        margin-left: 6px;
        @include background-image("icons/arrow-right-20.svg", 20, 20);
        vertical-align: sub;
      }
    }
  }
  &__item {
    + .card-side-img__item{
      margin-top: 30px;
      @media (max-width: 991px) {
        margin-top: 24px;
      }
    }

    &--primary{
      .card-side-img__content{
        background-color: $glava-yellow-600;

        @include site(weber){
          background-color: $weber-black-50;
        }

        @include site(gyproc){
          background-color: $gyproc-aqua-300;
        }
      }
    }

    &--secondary{
      .card-side-img__content{
        background-color: $glava-green-200;

        @include site(weber){
          background-color: $weber-yellow;
        }

        @include site(gyproc){
          background-color: $gyproc-sand-300;
        }
      }
    }

    &--tertiary{
      .card-side-img__content{
        background-color: $glava-black;
        color: $white;

        @include site(weber){
          background-color: $weber-anthracite-500;
        }

        @include site(gyproc){
          background-color: $gyproc-blue;
        }

        p{
          color: $white;
        }

        .link-arrow-left-lg{
          background-image: url("../images/icons/arrow-right-20-w.svg");
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 11% 17.6%;
    min-height: 100%;
    @media (max-width: 991px) {
      padding: 0;
    }
    &:hover {
      text-decoration: none;
    }
    h2 {
      @include font-size(40);
      line-height: 1.2;
      margin-bottom: 0;
      @media (max-width: 1199px) {
        @include font-size(20);
        line-height: 1.4;
      }
    }
    p {
      @include font-size(19);
      letter-spacing: 1.68;
      letter-spacing: 0.01em;
      margin-top: 20px;
      color: $gray-800;
      @media (max-width: 1199px) {
        @include font-size(16);
        margin-top: 16px;
      }
    }
    .link-arrow-left-lg {
      margin-top: 32px;
      display: inline-block;
      min-height: 1.33em;
      @media (max-width: 1199px) {
        @include font-size(16);
        margin-top: 24px;
        background-position: top -1px left;
      }
    }
  }

  &--vertical {
    @media (max-width: 991px) {
      padding: 0;
    }
    .card-side-img {
      &__wrapper {
        margin-bottom: -30px;
        @media (max-width: 991px) {
          margin-bottom: -36px;
        }
      }
      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        @media (max-width: 991px) {
          margin-bottom: 36px;
        }
        @media (max-width: 767px) {
          flex-direction: column;
          width: 100vw;
          margin-left: calc(-50vw + 50%);
        }
        .img {
          img {
            min-height: 100%;
            object-fit: cover;
          }
        }
        &.row-lg-inverted {
          .card-side-img__img {
            @media (max-width: 767px) {
              margin-left: auto;
              margin-right: 0;
            }
          }
          .card-side-img__content {
            @media (max-width: 767px) {
              margin-left: 0;
              margin-right: auto;
            }
            @media (max-width: 991px) and (min-width: 768px) {
              padding: 30px 30px 30px 70px;
            }
          }
        }

        &--tertiary{
          .card-side-img__content{
            .link-arrow-left-lg{
              background-image: url("../images/icons/arrow-right-20-w.svg");
            }
          }
        }
      }
      &__img {
        width: calc(50% - 15px);
        @media (max-width: 767px) {
          margin: 0 auto -120px 0;
          width: calc(75% + 20px);
          max-width: 400px;
        }
        .img {
          margin-bottom: 0;
        }
      }
      &__content {
        text-align: center;
        padding: 4% 8.6%;
        width: calc(50% + 15px);
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 1199px) {
          padding: 20px;
        }
        @media (max-width: 991px) {
          text-align: left;
          align-items: flex-start;
          padding: 30px 70px 30px 30px;
        }
        @media (max-width: 767px) {
          margin-left: auto;
          padding: 20px 20px 36px;
          width: calc(75% + 20px);
          z-index: 2;
          display: block;
          min-height: 120px;
        }
        p {
          font-weight: 600;
          @include font-size(25);
          line-height: 1.52;
          margin: 0;
          color: inherit;
          @media (max-width: 1199px) {
            @include font-size(24);
            line-height: 1.17;
            display: block;
          }
        }
        .link-arrow-left-lg {
          @include font-size(14);
          line-height: 1.43;
          letter-spacing: 0.01em;
          margin-top: 22px;
          padding: 0 25px 0 0;
          background-image: url("../images/icons/arrow-right-20.svg");
          background-position: bottom right;
          @media (max-width: 1199px) {
            display: inline-block;
            margin-top: 20px;
          }
        }
      }
    }
  }

  // Standard variation only:
  &:not(.card-side-img--vertical){
    .card-side-img__item{
      @media (max-width: 991px) {
        width: 100vw;
        margin-left: calc((100vw - 690px)/ -2);
        padding-top: 20px;
        padding-bottom: 20px;

        > * {
          max-width: 720px;
          margin: 0 auto;
        }

        &--primary{
          background-color: $glava-yellow-600;

          @include site(weber){
            background-color: $weber-black-50;
          }

          @include site(gyproc){
            background-color: $gyproc-aqua-300;
          }
        }

        &--secondary{
          background-color: $glava-green-200;

          @include site(weber){
            background-color: $weber-yellow;
          }

          @include site(gyproc){
            background-color: $gyproc-sand-300;
          }
        }

        &--tertiary{
          background-color: $glava-black;

          @include site(weber){
            background-color: $weber-anthracite-500;
          }

          @include site(gyproc){
            background-color: $gyproc-blue;
          }
        }
      }

      @media (max-width: 767px) {
        margin-left: calc((100vw - 510px)/ -2);

        > * {
          max-width: 540px;
        }
      }

      @media (max-width: 574px) {
        margin-left: -15px;

        > * {
          max-width: 100%;
        }
      }

      & + .card-side-img__item{
        @media (max-width: 991px) {
          margin-top: 0px;
        }
      }
    }
  }
}
