.product-block-dynamic {
  margin: 80px 0;
  @media(max-width: 991px) {
    margin: 58px 0;
  }
  .button {
    margin: 48px auto 0;
    display: none;
    @media(max-width: 991px) {
      display: inline-flex;
    }
  }
  &__head {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    width: 100%;
    @media(max-width: 991px) {
      margin-bottom: 32px;
    }
    h2 {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      @media(max-width: 991px) {
        @include font-size(24);
        line-height: 1.17;
        letter-spacing: 0.12px;
        margin-left: 0;
        display: block;
      }
    }
    &__type {
      display: inline-block;
      color: #C4C4C4;
      @include font-size(32);
      line-height: 1.25;
      font-weight: 600;
      vertical-align: middle;
      @media(max-width: 991px) {
        @include font-size(24);
        line-height: 1.17;
        margin-top: 8px;
      }
    }
    .bootstrap-select {
      // display: none;
      width: auto!important;
      max-width: 100%;
      > .dropdown-toggle {
        color: #C4C4C4;
        background-color: transparent!important;

        padding: 0 26px 0 0;
        width: auto!important;
        max-width: 100%;

        position: relative;
        @media(max-width: 991px) {
          padding-right: 22px;
        }
        &:after{
          content:'';
          width: 8px;
          height: 8px;
          border: solid #C4C4C4;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          margin-top: -4px;
          position: absolute;
          right: 0;
          top: 50%;
        }
        .filter-option-inner-inner {
          color: #C4C4C4;
          @include font-size(32);
          line-height: 1.25;
          font-weight: 600;
          text-decoration: underline;
          text-decoration-skip-ink: none;
          text-decoration-thickness: 2px;
          text-underline-position: from-font;
          text-overflow: ellipsis;
          @media(max-width: 991px) {
            text-decoration-thickness: 1px;
            text-underline-offset: 1px;
            @include font-size(24);
            line-height: 1.17;
          }
        }
      }
      .dropdown-menu {
        border-radius: 0 !important;
        top: -3px !important;
        width: max-content !important;
        max-width: calc(100vw - 40px) !important;
        &.inner {
          max-width: 100% !important;
        }
        .dropdown-item {
          padding: 13px 1.5rem;
          line-height: 100%;
          white-space: normal;
          &.active,
          &:active {
            background-color: transparent;
          }
        }
      }
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        // background-image: url("../images/icons/arrow-down.svg");
        background-repeat: no-repeat;
        font-weight: 600;
        @include font-size(17px);
        line-height: 100%;
        padding: 17px 8px;
        color: #333333;
        &:focus {
          outline: none !important;
        }
      }
      option {
        font-weight: 600;
        @include font-size(17px);
        line-height: 24px;
        letter-spacing: 0.24px;
        color: #333333;
      }
      &:focus {
        outline: none;
      }
    }

    &__left {
      flex: 1;
      max-width: 100%;
    }
    &__link {
      @include font-size(16);
      line-height: 1.81;
      letter-spacing: -0.005em;
      font-weight: 600;
      margin-left: auto;
      @media(max-width: 991px) {
        display: none;
      }
      &:hover {
        color: inherit;
        text-decoration: none;
      }
      &:after {
        content: '';
        margin-left: 6px;
        @include background-image("icons/arrow-right-20.svg", 20, 20);
        vertical-align: middle;
      }
    }
  }
  &__wrapper {
    @media(max-width: 991px) {
      margin: 0 -3px -16px;
      > * {
        padding: 0 8px;
      }
    }
  }
  &__item {
    @include font-size(17);
    line-height: 1.41;
    font-weight: 600;
    border: 1px solid $gray-200;
    padding: 24px 24px 40px;
    text-align: center;
    margin-bottom: 30px;
    min-height: calc(100% - 30px);
    display: block;
    @media(max-width: 991px) {
      @include font-size(12);
      line-height: 1.33;
      padding: 12px;
      margin-bottom: 16px;
      min-height: calc(100% - 16px);
    }
    &:hover {
      text-decoration: none;
    }
    .img {
      margin-bottom: 12px;
      @media(max-width: 991px) {
        margin-bottom: 8px;
      }
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
