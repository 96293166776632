.logo-line {
  margin: 60px 0 80px;
  @media (max-width: 991px) {
    margin: 60px 0 62px;
  }
  h2 {
    @include font-size(28);
    line-height: 1.14;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      @include font-size(24);
      line-height: 1.33;
      letter-spacing: 0.12px;
      margin-bottom: 32px;
    }
  }
  &__wrapper {
    display: flex;
  }
  &__item {
    border: 1px solid $gray-400;
    background-color: #fff;
    flex: 1;
    display: block;
    + .logo-line__item{
      margin-left: 40px;
      @media (max-width: 991px) {
        margin-left: -1px;
      }
    }
    img {
      max-width: 59.2%;
      max-height: 39.25%;
      margin: auto;
      object-fit: contain;
      @media (max-width: 991px) {
        max-width: 70.6%;
        max-height: 35%;
      }
    }
    .img {
      @media (max-width: 991px) {
        padding-top: calc((80 / 109) * 100%);
      }

    }
  }
}
