
.video-poster {
  .play-button {
    position: absolute;
    background-color: transparent;
    border: none;
    z-index: 1;
    width: 48px;
    height: 84px;

    &::after{
      content: "";
      position: relative;
      display: block;
      width: 48px;
      border: solid transparent;
      border-width: 42px 0 42px 48px;
      border-left-color: #fff;
      cursor: pointer;
      z-index: 1;
      @media (max-width: 991px) {
        width: 34px;
        border-width: 30px 0 30px 34px;
      }
    }
  }
  iframe {
    border: none;
  }
  .videoWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  &--active {
    .play-button{
      display: none;
    }
    .video-poster__img {
      cursor: unset;
      z-index: -1;
    }
  }
}
