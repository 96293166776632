.box-text-yellow {
  padding: 32px 40px;
  background: #FFF5B3;
  width: auto;
  margin-bottom: 40px;
  width: calc(166.66% + 20px);

  .articleWrapper & {
    width: 100%;

    @media (max-width: 1199px) {
      width: calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
    }

    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin-left: 0;
    // width: calc(133.33% + 10px);
    // margin-left: calc( -11.11% - 3px);
  }
  @media (max-width: 991px) {
    @include font-size(16);
    line-height: 1.5;
    padding: 20px 24px;
    margin-bottom: 24px;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p, ul, ol, div {
    @include font-size(18);
    line-height: 1.56;
    letter-spacing: 0.005em;
    @media (max-width: 991px) {
      @include font-size(16);
      line-height: 1.5;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
      @media (max-width: 991px) {
        margin-bottom: 8px;
      }
    }
  }
}
