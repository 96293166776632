//page container class

.layout-list-blocks-solutions{

  #solution__filter-top{
    height: 1px;
  }
  #solution__filter{
    &.solution__filter--sticky{
      position: sticky;
      top: 0;
      padding: 16px 0 0px;
      background: white;
      border-bottom: 1px solid #d6d6d6;
      z-index: 99;

      &.has-header{
        border-top: 1px solid #d6d6d6;
        transition: All 300ms ease-in-out;
        -webkit-transition: All 300ms ease-in-out;
        -moz-transition: All 300ms ease-in-out;
        -o-transition: All 300ms ease-in-out;
      }

      .filterOptions{
        @media(max-width: 992px){
          margin-bottom: 16px;
        }

        .filterBox{
          padding-bottom: 0;
        }

        .filterOptions__buttons{
          .clearFilter{
            margin-bottom: 16px;

            @media(max-width: 992px){
              margin-bottom: 0;
            }
          }
        }
      }
    }

    // Custom dropdown-filter behavior
    .filterBox{
      padding-bottom: 28px;
      @media(max-width: 992px){
        display: none;
      }

      &.active-mobile{
        @media(max-width: 992px){
          display: flex;
        }
      }
    }
  }

  .listArticleWrapper {
    &__list {
      &--item {
        margin-bottom: 80px;

        h2 {
          @media(max-width: 992px){
            padding-right: 0;
          }
          span{
            &::after{
              display: none;
            }
          }
        }

        p {
          @media(min-width: 992px){
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.32px;
          }
        }
      }
    }
  }
}
