.banner{
  margin-bottom: 100px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
  &__wrapper {
    width: 100%;
  }
  &__text {
    position: relative;

    @media (max-width: 991px) {
      margin-top: 12px;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100vw;
        height: calc(100% + 124px);
        left: calc(-50vw + 50%);
        top: -124px;
      }
    }

    h2 {
      @include font-size(36);
      line-height: 1.33;
      margin-bottom: 0;
      @media (max-width: 1199px) {
        @include font-size(22);
        line-height: 1.27;
      }
    }
    p {
      @include font-size(18);
      line-height: 1.89;
      letter-spacing: 0.01em;
      margin-top: 16px;
      @media (max-width: 1199px) {
        @include font-size(17);
        line-height: 1.53;
        margin-top: 6px;
      }
    }

    .button{
      margin-top: 28px;

      @media (max-width: $mobileWidth){
        margin-top: 32px;
        width: 100%;
        justify-content: center;
      }
    }
  }
  &--box-side {
    @media (max-width: 991px) {
      margin-bottom: 32px;
    }
    .banner {
      &__wrapper {
        display: flex;
        @media (max-width: 991px) {
          display: block;
        }
        figure {
          flex: 0 0 58%;
          width: 58%;
          @media (max-width: 991px) {
            width: 100%;
          }
          .img {
            padding-top: calc((485 / 680) * 100%);
            @media (max-width: 991px) {
              padding-top: calc((232 / 335) * 100%);
            }
            img {
              min-height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      &__text {
        padding: 8% 6.8% 4%;
        flex: 0 0 42%;
        @media (max-width: 1199px) {
          padding: 6% 5.1% 3%;
        }
        @media (max-width: 991px) {
          padding: 0 0 40px;
        }
      }
    }
  }
  &--box-out {
    @media (max-width: 991px) {
      margin-bottom: 32px;
    }
    .banner {
      &__wrapper {
        margin: 0 -30px;
        width: auto;
        @media (max-width: 991px) {
          margin: 0;
        }
        .img {
          padding-top: calc((640 / 1240) * 100%);
          @media (max-width: 991px) {
            padding-top: calc((232 / 335) * 100%);
          }
          img {
            min-height: 100%;
            object-fit: cover;
          }
        }
      }
      &__text {
        width: 520px;
        padding: 56px 74px 60px;
        margin-left: auto;
        margin-right: 30px;
        margin-top: calc((-320 / 1240) * 100%);
        @media (max-width: 991px) {
          padding: 0 0 48px;
          margin: 12px 0 0;
          width: auto;
        }
        .button {
          @extend .button--rounded;
        }
      }
    }
  }

  // primary colors
  &--primary{
    .featuredImage{
      .img{
        background-color: $glava-yellow-600;

        @include site(weber){
          background-color: $weber-black-50;
        }

        @include site(gyproc){
          background-color: $gyproc-aqua-300;
        }
      }
    }

    .banner__text{
      background-color: $glava-yellow-600;

      a{
        border-color: $gray-400;

        &:hover{
          border-color: $glava-black;
        }
      }

      @include site(weber){
        background-color: $weber-black-50;

        a{
          border-color: $gray-400;

          &:hover{
            border-color: $weber-black;
          }
        }
      }

      @include site(gyproc){
        background-color: $gyproc-aqua-300;

        a{
          border-color: $gyproc-blue;

          &:hover{
            border-color: $gyproc-gray;
          }
        }
      }

      &::before{
        background-color: $glava-yellow-600;

        @include site(weber){
          background-color: $weber-black-50;
        }

        @include site(gyproc){
          background-color: $gyproc-aqua-300;
        }
      }

      .button{
        @include site(gyproc){
          @extend .button--secondary;
        }
      }
    }
  }

  // secondary colors
  &--secondary{
    .featuredImage{
      .img{
        background-color: $glava-green-200;

        @include site(weber){
          background-color: $weber-yellow;
        }

        @include site(gyproc){
          background-color: $gyproc-sand-300;
        }
      }
    }

    .banner__text{
      background-color: $glava-green-200;

      a{
        border-color: $gray-400;

        &:hover{
          border-color: $glava-black;
        }
      }

      @include site(weber){
        background-color: $weber-yellow;

        a{
          border-color: $gray-400;

          &:hover{
            border-color: $weber-black;
          }
        }
      }

      @include site(gyproc){
        background-color: $gyproc-sand-300;

        a{
          border-color: $gyproc-blue-750;

          &:hover{
            border-color: $gyproc-gray;
          }
        }
      }

      &::before{
        background-color: $glava-green-200;

        @include site(weber){
          background-color: $weber-yellow;
        }

        @include site(gyproc){
          background-color: $gyproc-sand-300;
        }
      }
    }
  }

  // secondary colors
  &--tertiary{
    .featuredImage{
      .img{
        background-color: $glava-black;

        @include site(weber){
          background-color: $weber-anthracite-500;
        }

        @include site(gyproc){
          background-color: $gyproc-blue;
        }
      }
    }

    .banner__text{
      background-color: $glava-black;
      color: $white;

      a{
        border-color: $glava-yellow-600;

        &:hover{
          border-color: $white;
        }
      }

      @include site(weber){
        background-color: $weber-anthracite-500;

        a{
          border-color: $weber-yellow;

          &:hover{
            border-color: $white;
          }
        }
      }

      @include site(gyproc){
        background-color: $gyproc-blue;

        a{
          border-color: $white;

          &:hover{
            border-color: $gyproc-gray;
          }
        }
      }

      &::before{
        background-color: $glava-black;

        @include site(weber){
          background-color: $weber-anthracite-500;
        }

        @include site(gyproc){
          background-color: $gyproc-blue;
        }
      }

      .button{
        @extend .button--secondary;

        @include site(gyproc){
          background-color: $gyproc-aqua-300;
          color: $gyproc-black;

          &:hover{
            color: $gyproc-black;
          }
        }
      }
    }
  }
}
