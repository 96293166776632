.search-products{
  .aside-filter-top{
    // modifiers...
  }

  .list-wrapper{
    margin-bottom: 48px;
    overflow: hidden;

    @media(max-width: 992px){
      margin-bottom: 25px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .loading-animation{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1;
      display: block;
      text-align: center;
      padding-top: 120px;

      @media(max-width: 992px){
        padding-top: 30px;
      }


      @keyframes ldio-a2ac99d2nu {
        0% { opacity: 1 }
        50% { opacity: .5 }
        100% { opacity: 1 }
      }
      .ldio-a2ac99d2nu div {
        position: absolute;
        width: 10px;
        height: 50px;
        top: 30px;
        animation: ldio-a2ac99d2nu 1s cubic-bezier(0.5,0,0.5,1) infinite;
      }.ldio-a2ac99d2nu div:nth-child(1) {
        transform: translate(15px,0);
        background: #353535;
        animation-delay: -0.6s;
      }.ldio-a2ac99d2nu div:nth-child(2) {
        transform: translate(35px,0);
        background: #353535;
        animation-delay: -0.4s;
      }.ldio-a2ac99d2nu div:nth-child(3) {
        transform: translate(55px,0);
        background: #353535;
        animation-delay: -0.2s;
      }.ldio-a2ac99d2nu div:nth-child(4) {
        transform: translate(75px,0);
        background: #353535;
        animation-delay: -1s;
      }
      .loadingio-spinner-bars-lcvhnzzsobr {
        width: 74px;
        height: 74px;
        display: inline-block;
        //overflow: hidden;
        text-align: center;

        span{
          font-weight: 600;
        }
      }
      .ldio-a2ac99d2nu {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.74);
        backface-visibility: hidden;
        transform-origin: 0 0;
      }
      .ldio-a2ac99d2nu div { box-sizing: content-box; }
    }
  }

  .doc-drawer{
    position: fixed;
    top: 0;
    right: calc((58vw + 80px) * -1);
    width: calc(58vw + 80px);
    height: 100vh;
    max-width: calc(977px + 80px);
    padding-left: 80px;
    overflow-y: auto;
    transition: right 0.5s ease-in-out , opacity 0.5s ease-in-out;
    opacity: 0;
    z-index: 999;

    @media(max-width: 1440px){
      right: calc((75vw + 80px) * -1);
      width: calc(75vw + 80px);
    }

    @media(max-width: 992px){
      top: 100vh;
      left: 0;
      right: 0;
      width: 100vw;
      height: calc(100vh - 80px);
      padding: 0;
      transition: top 0.5s ease-in-out , opacity 0.2s ease-in-out;
    }

    &.active{
      right: 0;
      opacity: 1;

      @media(max-width: 992px){
        top: 80px;
      }
    }

    .content{
      position: relative;
      min-height: 100vh;
      padding: 80px;
      background-color: white;

      @media(max-width: 992px){
        min-height: calc(100vh - 80px);
        padding: 0 0 40px;
      }

      .doc-drawer__btn-close{
        position: absolute;
        top: 0px;
        left: -80px;
        width: 80px;
        height: 80px;
        background-color: #3A3C45;
        background-image: url("../images/./icons/close-burger-menu.svg");
        background-repeat: no-repeat;
        background-size: 32px 32px;
        background-position: center;
        border: none;

        @media(max-width: 992px){
          right: 0;
          left: auto;
          width: 56px;
          height: 56px;
          background-size: 20px 20px;
        }
      }

      .doc-drawer__header{
        margin-bottom: 36px;

        @media(max-width: 992px){
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 56px;
          margin-bottom: 25px;
          padding: 0px 100px 0px 25px;
          border-bottom: 1px solid #EBECEE;
          pointer-events: none;
        }

        h3{
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          letter-spacing: -0.005em;

          @media(max-width: 992px){
            font-size: 13px;
            line-height: 20px;
            text-align: right;
          }

          span{
            margin: 0 12px;

            @media(max-width: 992px){
              margin: 0 4px;
            }
          }

          strong{
            font-weight: 600;
          }
        }
      }

      .doc-drawer__list{
        list-style: none;

        @media(max-width: 992px){
          padding-left: 25px;
          padding-right: 25px;
        }

        li{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-right: 40px;
          margin-bottom: -1px;
          padding: 16px 20px 16px 40px;
          border: 1px solid #D2D3D5;
          border-bottom: none;

          @media(max-width: 992px){
            flex-direction: column;
            align-items: flex-start;
            padding: 16px 20px;
            border-color: #E5E6E8;
          }

          &:last-child{
            border-bottom: 1px solid #D2D3D5;
          }

          h4{
            margin-right: 40px;
            font-size: 17px;
            line-height: 24px;
            font-weight: 600;

            @media(max-width: 992px){
              margin-right: 0;
              margin-bottom: 8px;
              font-size: 13px;
              line-height: 20px;
            }
          }

          .buttons{
            display: flex;
            flex-direction: row;
            padding: 4px 0;

            @media(max-width: 992px){
              padding: 0;
            }

            .btn{
              justify-content: center;
              padding: 7px 20px;
              border: 1px solid $glava-black;
              border-radius: 100px;
              font-size: 15px;
              line-height: 24px;
              font-weight: 600;
              white-space: nowrap;
              transition: none;
              color: $glava-black;

              @include site(weber){
                color: $weber-black;
              }

              @include site(gyproc){
                color: $gyproc-black;
              }

              @media(max-width: 992px){
                width: auto;
                margin: 0 auto;
                padding: 4px 12px 5px;
                font-size: 11px;
                line-height: 16px;
                font-weight: 500;
              }

              &:first-child{
                margin-right: 12px;

                @media(max-width: 992px){
                  margin-right: 6px;
                }
              }

              &--share{
                background-color: #F3F4F5;
                border-color: transparent;

                &:hover{
                  text-decoration: underline;
                }
              }

              &--download{
                @include site(weber){
                  border-color: $weber-black;
                }

                @include site(gyproc){
                  border-color: $gyproc-black;
                }

                &:hover{
                  box-shadow:0px 0px 0px 1px $glava-black;

                  @include site(weber){
                    box-shadow:0px 0px 0px 1px $weber-black;
                  }

                  @include site(gyproc){
                    box-shadow:0px 0px 0px 1px $gyproc-black;
                  }
                }
              }

              &:focus-visible{
                outline: 2px solid;
              }
            }
          }

          > a{
            width: 108px;
            height: 36px;
            border-radius: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.16px;
            color: #333333;
            border-bottom: none;

            @media(max-width: 992px){
              width: 100%;
              font-size: 13px;
            }

            &.copy{
              width: 226px;
              height: 36px;
              background: #333333;
              color: #FFFFFF;
              border-radius: 0;
              text-decoration: none;
              &:hover{
                text-decoration: none;
              }
              @media(max-width: 992px){
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .bg-overlay{
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
}

.product-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;

  @media(max-width: 992px){
    margin-right: -7.5px;
    margin-left: -7.5px;
  }

  &__item{
    position: relative;
    width: 33.33%;
    margin-bottom: 32px;
    padding: 0 16px;

    @media(max-width: 992px){
      width: 33.33%;
      padding: 0 7.5px;
      margin-bottom: 15px;
    }

    @media(max-width: 768px){
      width: 50%;
    }

    &:hover{
      .wrapper{
        .buttons{
          display: block;
        }
      }
    }

    .wrapper{
      position: relative;
      height: 100%;
      border: 1px solid #e9ebef;

      @media(max-width: 992px){
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      > a{
        display: block;
        padding: 24px 28px 40px;
        text-decoration: none;

        @media(max-width: 1200px){
          padding: 16px;
        }

        @media(max-width: 768px){
          padding: 12px 12px 8px;
        }

        .image-wrapper{
          figure{
            img{
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .description{
          padding-top: 12px;

          @media(max-width: 768px){
            padding-top: 8px;
          }

          h2{
            color: #121212;
            font-size: 17px;
            line-height: 24px;
            font-weight: 600;
            text-align: center;

            @media(max-width: 992px){
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }

      .buttons{
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 32px 28px;
        background-color: white;

        @media(max-width: 1200px){
            padding: 16px 16px 20px;
        }

        @media(max-width: 992px){
          display: block;
          position: relative;
          margin-top: auto;
          padding: 0px 12px 16px;
        }

        .btn{
          width: 100%;
          justify-content: center;
          padding: 7px 32px;
          border: 1px solid $glava-black;
          border-radius: 100px;
          font-size: 15px;
          line-height: 24px;
          font-weight: 600;
          transition: none;
          color: $glava-black;

          @media(max-width: 992px){
            width: auto;
            margin: 0 auto;
            padding: 4px 12px 5px;
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
          }

          &--view-product{
            display: none;
            background-color: #F3F4F5;
            border-color: transparent;
          }

          &--documentation{
            background-color: white;

            @include site(weber){
              border-color: $weber-black;
              color: $weber-black;
            }

            @include site(gyproc){
              border-color: $gyproc-black;
              color: $gyproc-black;
            }

            &::before{
              content: "";
              display: block;
              min-width: 9px;
              width: 9px;
              height: 9px;
              margin-right: 7px;
              background-image: url("../images/./icons/arrowUpLeft.svg");
              background-repeat: no-repeat;
              background-size: contain;

              @media(max-width: 992px){
                display: none;
              }
            }

            &:hover{
              box-shadow:0px 0px 0px 1px $glava-black;

              @include site(weber){
                box-shadow:0px 0px 0px 1px $weber-black;
              }

              @include site(gyproc){
                box-shadow:0px 0px 0px 1px $gyproc-black;
              }
            }
          }

          &:focus-visible{
            outline: 2px solid;
          }
        }
      }
    }
  }

  &--list-view , &--compact-view{
    margin-right: 0;
    margin-left: 0;

    .product-list__item{
      width: 100%;
      margin-bottom: 0px;
      padding: 12px 40px 12px 12px;
      border: 1px solid #e9ebef;
      border-bottom: none;

      &:last-child{
        margin-bottom: 0;
        border: 1px solid #e9ebef;
      }

      @media(max-width: 1200px){
        padding-right: 12px;
      }

      @media(max-width: 992px){
        min-height: 129px;
        padding: 36px 12px 36px 12px;
      }

      @media(max-width: 768px){
        min-height: 85px;
        padding: 12px 12px 16px 12px;
      }

      .wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;

        @media(max-width: 992px){
          flex-direction: column;
          align-items: flex-start;
          padding-left: 136px;
        }

        @media(max-width: 768px){
          padding-left: 76px;
        }

        a{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 20px 0 0;

          @media(max-width: 992px){
            width: 100%;
            padding: 0;
          }

          .image-wrapper{
            width: 120px;
            height: 96px;
            min-width: 120px;
            min-height: 96px;
            margin-right: 28px;

            @media(max-width: 992px){
              position: absolute;
              top: calc(50% - 48px);
              left: 12px;
              width: 96px;
              height: 96px;
              min-width: 96px;
              min-height: 96px;
              margin: 0;
            }

            @media(max-width: 768px){
              top: 12px;
              width: 52px;
              height: 52px;
              min-width: 52px;
              min-height: 52px;
            }

            figure{
              padding-top: calc((96 / 120) * 100%);

              @media(max-width: 992px){
                padding-top: calc((1 / 1) * 100%);
              }
            }
          }

          .description{
            max-width: 560px;
            padding-top: 0;

            h2{
              text-align: left;
            }
          }
        }

        .buttons{
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: auto;
          margin-left: auto;
          padding: 0;

          @media(max-width: 992px){
            margin-top: 8px;
            margin-left: 0;
          }

          .btn{
            padding: 7px 20px;

            &:last-child{
              margin-right: 0;
            }

            @media(max-width: 992px){
              padding: 4px 12px 5px;
            }

            &--view-product{
              display: block;
              margin-right: 12px;

              @media(max-width: 992px){
                display: none;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  &--compact-view{
    .product-list__item{
      padding: 20px 40px;

      @media(max-width: 992px){
        padding: 16px 20px;
        min-height: inherit;
      }

      .wrapper{
        @media(max-width: 992px){
          padding: 0;
        }

        a{
          .image-wrapper{
            display: none;
          }

          .description{
            max-width: 680px;
          }
        }
      }
    }
  }
}
