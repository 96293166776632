.teaser{
  margin-top: 20px;
  padding-right: 17%;
  @media (max-width: 991px) {
    margin-top: 16px;
    padding-right: 0;
  }
  p {
    @include font-size(21);
    line-height: 1.62;
    letter-spacing: 0.01em;
    @media (max-width: 991px) {
      @include font-size(18);
      line-height: 1.67;
    }
  }
  .btn {
    @include font-size(18);
    line-height: 1.56;
    font-weight: 600;
    letter-spacing: 0.01em;
    padding: 18px 56px 18px 28px;
    border: none;
    border-radius: 0;
    background: url("../images/./icons/arrow-right-24.svg")  no-repeat center right 24px/24px;
    background-color: #FFE66F;
    margin-top: 32px;
    width: fit-content;
    @media (max-width: 991px) {
      @include font-size(16);
      line-height: 1.31;
      padding: 12px 40px 11px 20px;
      background-image:  url("../images/./icons/arrow-right-16.svg") ;
      background-size: 16px;
      background-position: center right 18px;
      margin-top: 24px;
    }
  }
}
