.carousel{
    overflow: hidden;
    margin-bottom: 144px;
    @media (max-width: 992px){
        padding-top: 10px;
        margin-bottom: 55px;
    }


    &__wrapper{
        &--slider{
            width: 100%;
            .slick-slider {
                *{
                    outline: none !important;
                }

                position: initial;
                margin-left: calc(9.5% - 15px);

                @media (max-width: 1200px){
                    margin-left: calc(9.9% - 15px);
                }

                @media (max-width: 992px){
                    margin-left: calc(10.4% - 15px);
                }

                @media (max-width: 768px){
                    margin-left: 0;
                }

            }

            .carouselSlioder{
                width: calc(57.8% + 15px);
                @media (max-width: 768px){
                    width: calc(88.2% + 15px);
                    margin: 0 auto;
                }
                .slick-list{
                    overflow: visible;
                }
                .item{
                    width: 100%;
                    padding-right: 30px;
                    @media (max-width: 768px){
                        padding-right: 10px;
                        padding-left: 10px;
                    }

                    .captions{
                        padding-top: 20px;
                        overflow: hidden;
                        margin-left: calc(12.5% + 5px);

                        @media (max-width: $mobileWidth){
                            margin-left: 0;
                        }

                        &__itemNumber,
                        &__text{
                            font-size: 14px;
                            line-height: 140%;
                            letter-spacing: 0.02em;
                            color: $gray-800;
                            @media (max-width: 768px){
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                        &__itemNumber{
                            width: 40px;
                            & + .captions__text{
                                width: calc(100% - 40px);
                            }
                        }
                        &__text{
                            width: 100%;
                        }
                        .cite, cite {
                            color: $gray-600;
                            margin-top: 8px;
                            font-size: 14px;
                            line-height: 140%;

                            @media (max-width: 768px) {
                                margin-top: 2px;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }

                }
            }

            .carouselControl{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                pointer-events: none;
                padding-top: calc((120.5 / 323) * 100%);

                @media (max-width: 1200px){
                    padding-top: calc((118 / 323) * 100%);
                }

                @media (max-width: 992px){
                    padding-top: calc((114 / 323) * 100%);
                }

                @media (max-width: 768px){
                    padding-top: calc((175 / 323) * 100%);
                }

                .slick-prev,
                .slick-next {
                    pointer-events: all;
                    width: 80px;
                    height: 80px;
                    z-index: 1;
                    @media (max-width: 992px){
                        width: 40px;
                        height: 40px;
                    }

                    &.slick-disabled{
                        display: none!important;
                    }
                }

                .slick-prev{
                    left: 15px;
                    @media (max-width: 768px){
                        left: 15px;
                    }
                }

                .slick-next {
                    right: 15px;
                    @media (max-width: 768px){
                        right: 15px;
                    }
                }

                .slick-next:before{
                    content: url("../images/icons/ArrowRight.svg");
                    font-size: 1px;
                    opacity: 1;
                }

                .slick-prev:before {
                    content: url("../images/icons/ArrowLeft.svg");
                    font-size: 1px;
                    opacity: 1;
                }

            }

        }
    }
    &.macro{
        margin-top: 80px;
        margin-bottom: 80px;
        position: relative;

        @media (max-width: 992px){
            padding-top: 0px;
            overflow: visible;
        }

        .slick-slider {
            margin-left: calc(13% - 15px);
            @media (max-width: 768px){
                margin-left: auto;
            }
        }

        .carouselSlioder{
            @media (max-width: 768px){
                width: calc(78.7% + 15px);
            }
            .item{
                .captions{
                    width: 100%;
                }
            }
        }
    }
}
