.styled-text {
  @include font-size(18);
  line-height: 1.89;
  letter-spacing: 0.005em;
  @media (max-width: 991px) {
    @include font-size(17);
    line-height: 1.65;
  }
  >* {
    &:first-child {
      margin-top: 0!important;
    }
    &:last-child {
      margin-bottom: 0!important;
    }
  }
  p, ul, ol {
    margin-bottom: 28px;
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    letter-spacing: 0.005em;
    margin-top: 36px;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      margin-top: 32px;
      margin-bottom: 12px;
    }
  }
  h2 {
    @include font-size(24);
    line-height: 1.42;
    @media (max-width: 991px) {
      @include font-size(20);
      line-height: 1.60;
    }
  }
  h3, h4, h5, h6 {
    @include font-size(18);
    line-height: 1.89;
    @media (max-width: 991px) {
      @include font-size(17);
      line-height: 1.65;
    }
  }
  p {
    a {
      color: #387197;
      text-decoration: underline;
      text-underline-position: under;
      font-weight: 600;
      border: none;
    }
  }
  ol {
    padding-left: 26px;
    li {
      margin-bottom: 0;
    }
  }
  ul {
    list-style: '\2022';
    padding-left: 16px;
    li {
      padding-left: 10px;
      margin-bottom: 0;
    }
  }
  strong, b {
    font-weight: 600;
  }
  a {
    color: #387197;
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 600;
  }
}
