.hero{
  margin-bottom: 64px;
  @media (max-width: 992px){
    margin-bottom: 0;
  }

  &.meddle{
    margin-bottom: 108px;
    @media (max-width: 992px){
      margin-bottom: 60px;
    }
  }

  &__image{
    padding-right: calc(119px * 1.25 - 80px);
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 1200px){
      padding-right: 0;
    }
  }

  &__content{

    margin-bottom: 60px;

    &.portrait{
      @media (max-width: 992px){
        padding-right: calc( 100% / 12 + 20px);
      }
    }

    &.landscape{
      @media (max-width: 992px){
        padding-left: calc( 100% / 12 + 20px);
      }
    }

    &--article{
      article{
        a{
          text-decoration: none;
        }
        h2{
          @include font-size(36);
          line-height: 44px;
          color: #2A2A2A;
          margin-top: 32px;
          position: relative;
          display: block;
          padding-right: 65px;
          word-break: break-word;
          @media (max-width: 992px){
            @include font-size(24);
            line-height: 32px;
            margin-top: 20px;
            padding-right: 45px;
          }
          span{
            position: relative;
            // color: red;
            &::after{
              @include background-image("./icons/arrow-hero.svg", 32, 24);
              position: absolute;
              right: -59px;
              top: 12px;
              @media (max-width: 992px){
                @include background-image("./icons/arrow-hero.svg", 22, 16);
                top: 8px;
                right: -36px;
              }
            }
          }

          &.font-small{
            @include font-size(28);
            line-height: 36px;
          }
        }
        p{
          @include font-size(20);
          line-height: 32px;
          color: #333333;
          margin-top: 20px;
          @media (max-width: 992px){
            @include font-size(16);
            line-height: 25px;
            margin-top: 15px;
          }
          &.column-6{
            width: 83%;
          }
        }
        > span{
          @include font-size(20);
          line-height: 32px;
          margin-top: 20px;
          display: block;
          color: #989B9E;
          @media (max-width: 992px){
            @include font-size(16);
            line-height: 25px;
            margin-top: 15px;
          }
        }
      }
      &.middle{
        height: 100%;
        display: flex;
        align-items: center;
        article{
          h2{
            @include font-size(48);
            line-height: 60px;
            @media (max-width: 1024px){
              @include font-size(36);
              line-height: 44px;
              padding-right: calc(119px * 1.25 - 115px);
            }
            @media (max-width: 992px){
              @include font-size(24);
              line-height: 32px;
            }
            span{
              // color: red;
              position: relative;

              &::after{
                @include background-image("./icons/arrow-hero-big.svg", 40, 32);
                position: absolute;
                right: -59px;
                top: 16px;
                @media (max-width: 1024px){
                  @include background-image("./icons/arrow-hero-big.svg", 30, 24);
                  top: 12px;
                }
                @media (max-width: 992px){
                  @include background-image("./icons/arrow-hero-big.svg", 22, 16);
                  top: 8px;
                  right: -36px;
                }
              }
            }
          }
        }
      }
    }
  }
}
