.categoryBlock{
    overflow: hidden;
    margin-bottom: 133px;
    @media (max-width: 991px){
        margin-bottom: 100px;
    }
    &__content{
        .title{
            @include font-size(32);
            line-height: 1.25;
            font-weight: 600;
            @media (max-width: 991px){
                @include font-size(24);
                line-height: 1.17;
                letter-spacing: 0.12px;
            }
        }
        .text{
            margin-top: 24px;
            p{
                @include font-size(21);
                line-height: 1.62;
                font-weight: 500;
                letter-spacing: 0.01em;
                color: $gray-800;
                @media (max-width: 991px){
                    @include font-size(20);
                    line-height: 1.5;
                }
            }
        }
    }
    &__slider{
        width: 100%;
        margin: 52px auto 0 auto;
        @media (max-width: 991px){
            margin-top: 40px;
        }

        &.container {
            padding: 0!important;
            @media (max-width: 991px){
                padding: 0 14px!important;
            }
        }

        .slick-track{
            margin-left: unset!important;
            margin-right: unset!important;
        }

        @media (max-width: 991px){
            max-width: 85%;
            margin: 40px 0 0 0;
        }

        @media (max-width: 767px){
            max-width: 70%;
            margin: 40px 0 0 0;
            &.container{
                padding-left: 0px;
            }
        }
        @media (max-width: 480px){
            max-width: 75.5%;
        }

        .itemWrapper{
            padding: 0 15px;
            @media (max-width: 991px){
                padding: 0 6px;
            }
            &:first-child{
                width: 33.33333%;
                @media (max-width: 991px){
                    width: 50%;
                }
                @media (max-width: 767px){
                    width: 100%;
                }
            }
        }

        &--item{
            z-index: 0;
            position: relative;
            &:after{
                z-index: 1;
                content: "";
                width: 100%;
                height: 67%;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
                background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 70.42%, rgba(0, 0, 0, 0.9) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 70.42%, rgba(0, 0, 0, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 70.42%, rgba(0, 0, 0, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
                display: block;
                position: absolute;
                bottom: 0;
            }
            .title{
                color: #FFFFFF;
                font-weight: 600;
                @include font-size(32);
                line-height: 1.25;
                letter-spacing: -0.02em;
                margin-bottom: 0px;
                @media (max-width: 991px){
                    @include font-size(20);
                    line-height: 1.2;
                }
            }
            &__intro {
                color: #FFFFFF;
                @include font-size(18);
                line-height: 1.45;
                letter-spacing: 0.01em;
                margin-top: 16px;
                opacity: 0.8;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                max-height: 4.35em;
                @media (max-width: 991px){
                    @include font-size(16);
                    line-height: 1.12;
                    -webkit-line-clamp: 4;
                    max-height: 4.48em;
                    margin-top: 6px;
                }
            }
            &__content {
                word-break: break-word;
                position: absolute;
                padding: 0 60px;
                bottom: 64px;
                left: 0;
                z-index: 2;
                max-height: 100%;
                overflow: hidden;
                @media (max-width: 991px){
                    bottom: 20px;
                    padding: 0 20px;
                }

            }

            .img{
                img{
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .slick-slider {
            *{
                outline: none !important;
            }
            .slick-prev, .slick-next {
                font-size: 20px;
                line-height: 0;
                position: absolute;
                top: 50%;
                display: block;
                width: 80px;
                height: 80px;
                padding: 0;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                cursor: pointer;
                color: #000000;
                border: none;
                margin: 0 15px;
                outline: none;
                background: #fff;
                border-radius: 50%;
                z-index: 1;

                &:before{
                    display: none;
                }
                &.slick-disabled{
                    display: none!important;
                }
            }

            .slick-next {
                right: -40px;
            }
            .slick-prev {
                left: -40px;
                img {
                    transform: rotate(180deg);
                }
            }
        }
        .slick-list {
            @media (max-width: 991px){
                overflow: visible;
            }
        }
    }
}
