.carousel-news {
  overflow-x: hidden;
  margin-bottom: 80px;
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
  .section-title {
    @media (min-width: 992px) {
      min-height: 40px;
    }
  }
  &__wrapper {
    width: 100%;
    position: relative;
    @media (max-width: 991px) {
      padding: 0 20px;
    }
    .carouselControl {
      position: absolute;
      top: -76px;
      right: 15px;
      width: 84px;
      height: 32px;
      @media (max-width: 991px) {
        display: none;
      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        transform: none;
        top: 0;
        &:before {
          content: "";
          @include background-image("icons/arrow-right-24.svg", 32, 32);
          opacity: 1;
        }
        &:focus-visible {
          outline: auto;
          outline-color: #000;
        }
        &.slick-disabled{
          opacity: .25;
        }
        &.slick-prev{
          transform: rotateY(180deg);
          left: 0;

        }
        &.slick-next {
          right: 0;
        }
      }
    }
  }
  &__slick {
    width: 100%;
    @media (max-width: 919px) {
      width: 87.5%;
    }
    @media (max-width: 767px) {
      width: 75%;
    }
    .slick-list {
      @media (max-width: 991px) {
        overflow: visible;
      }
    }
    // .slick-slide:focus, .slick-slide a:focus {
    //   outline: none!important;
    // }
  }
  .item {
    padding: 0 15px;
    @media (max-width: 991px) {
      padding: 0 16px 0 0;
    }
  }
}
.card-news {
  &:hover {
    text-decoration: none;
  }
  .img {
    margin-bottom: 20px;
    @media (max-width: 991px) {
      margin-bottom: 4px;
    }
  }
  &__content {
    height: 190px;
    max-height: 190px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    @media (max-width: 991px) {
      // height: 146px;
      // max-height: 146px;
      // display: -webkit-box;
      // -webkit-line-clamp: 7;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      height: 200px;
      max-height: 182px;
    }
  }
  &__date {
    @include font-size(13);
    line-height: 1rem;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
    color: $gray-800;
    @media (max-width: 991px) {
      margin-bottom: 6px;
    }
  }
  &__title {
    @include font-size(24);
    line-height: 1.44;
    letter-spacing: -0.005em;
    font-weight: 600;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 139px;
    padding-bottom: 10px;
    @media (max-width: 991px) {
      @include font-size(20);
      line-height: 1.4;
      letter-spacing: initial;
      padding-bottom: 6px;
      -webkit-line-clamp: 5;
      max-height: 7em;
    //   display: block;
    //   overflow: visible;
    }
  }
  &__description {
    @include font-size(16);
    line-height: 1.625;
    letter-spacing: 0.01em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    height: 10px;
    max-height: 6.5em;
    min-height: 26px;
    color: $gray-800;
    @media (max-width: 991px) {
      @include font-size(18);
      line-height: 1.334;
      letter-spacing: initial;
      max-height: 6.667em;
      min-height: 1.5em;
      -webkit-line-clamp: 5;
      // display: block;
      // overflow: visible;
    }
  }
}
