.square-card {
  margin: 90px 0 100px;
  overflow: hidden;
  @media (max-width: 991px) {
    margin: 56px 0 48px;
  }
  h2 {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      @include font-size(24);
      line-height: 1.17;
      margin-bottom: 32px;
      letter-spacing: 0.12px;
    }
  }
  &__wrapp {
    margin-bottom: -56px;
    @media (max-width: 991px)  {
      margin-bottom: -30px;
    }
    @media (max-width: 767px) {
      margin-bottom: -15px;
    }
  }
  &__item {
    display: block;
    margin-bottom: 56px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    &:hover {
      text-decoration: none;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    .img {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        padding-top: calc((225/335) * 100%);
        margin-bottom: 5px;
      }
    }
    &__title {
      @include font-size(19);
      line-height: 28px;
      font-weight: 600;
      letter-spacing: -0.01em;
      position: relative;
      @media (max-width: 991px) {
        @include font-size(18);
        line-height: 1.33;
      }
      &:after{
        content: '';
        background-image: url(../images/icons/arrow-right-24.svg);
        background-repeat: no-repeat;
        background-position: center center;
        width: 24px;
        height: 24px;
        display: inline-block;
        background-size: cover;
        display: inline-block;
        margin-left: -24px;
        vertical-align: middle;
      }
      span {
        margin-right: 30px;
      }
    }
  }
  &--slick {
    h2 {
      @media (max-width: 991px) {
        margin-bottom: 24px;
      }
    }
    .slick-prev, .slick-next {
      position: absolute;
      top: 0;
      margin-top: calc(10.9% - 13px);
      display: block;
      width: 80px;
      height: 80px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      border: none;
      // margin: 0 15px;
      outline: none;
      background: #fff;
      border-radius: 50%;
      z-index: 1;
      @media (max-width: 991px) {
        margin-top: calc((70 / 320) * 100%);
        margin-left: -15px;
        margin-right: -15px;
      }
      @media (max-width: 767px) {
        margin-top: calc((140 / 320) * 100%);
        width: 60px;
        height: 60px;
        margin-left: -5px;
        margin-right: -5px;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px;
        font-size: 1px;
        opacity: 1;
        @include background-image("/icons/arrow-right-24.svg",24,24);
        background-size: contain !important;
      }
      &.slick-disabled {
        display: none!important;
      }
      &:hover, &:focus {
        background: #fff;
      }
    }
    .slick-prev{
      @media (max-width: 575px) {
        left: -4px;
      }
      &:before {
        transform: rotateY(180deg);
      }
    }
    .slick-next {
      @media (max-width: 650px) {
        left: 480px;
      }
      @media (max-width: 575px) {
        left: calc(100vw - 68px);
      }
    }
    .square-card {
      &__wrapp {
        margin-bottom: 0px;
        flex-wrap: nowrap;
        @media (max-width: 991px) {
          // max-width: 82vw;
          margin: 0 -4px;
        }
        @media (max-width: 650px) {
          max-width: 64vw;
        }
        .col-lg-3 {
          @media (max-width: 991px) {
            padding: 4px;
          }
        }
        .slick-list {
          @media (max-width: 991px) {
            overflow: visible;
          }
        }
        .slick-track {
          min-width: 100%;
        }
      }
      &__item {
        margin-bottom: 0;
        &:focus {
          outline: none!important;
        }
        .img {
          padding-top: calc((280 / 320) * 100%);
        }
        &__title {
          font-weight: 600;
          letter-spacing: -0.005em;
          @media (max-width: 991px) {
            @include font-size(18);
            line-height: 1.33;
          }
          &:after{
            content: none;
          }
          span {
            margin-right: 0;
          }
        }
      }
    }
  }
}
