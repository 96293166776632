.accordion-title {
  font-style: normal;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.12px;
  font-weight: 600;
  padding: 32px 0 54px 0;
  word-wrap: break-word;
}
.accordion {
  border-top: 2px solid $glava-black;
  margin-bottom: 85px;

  @include site(weber){
    border-top-color: $weber-black;
  }

  @include site(gyproc){
    border-top-color: $gyproc-black;
  }

  .card {
    border-radius: 0!important;
    border: 0;
    border-bottom: 1px solid $gray-400;
    &:not(:last-of-type) {
      border: 0;
      border-bottom: 1px solid $gray-400;
    }
    .card-body {
      padding: 0 0 40px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      width: 90%;
      line-height: 32px;
      letter-spacing: 0.24px;
      @media (max-width: 992px) {
        padding: 0 0 30px 0;
      }

      // Optional accordion ingress
      .accordion-ingress{
        max-width: 720px;
        margin-bottom: 40px;
        opacity: 1;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.24px;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.24px;
      }

      // Optional accordion footer
      .accordion__footer{
        margin-top: 44px;
        padding: 0;
        background-color: inherit;

        p{
            font-size: 21px;
            line-height: 34px;
            letter-spacing: 0.24px;
        }
    }
    }
    &-header {
      padding: 30px 0;
      background-color: transparent;
      border-bottom: 0;
      h2 {
        line-height: 0;
        button {
          font-family: $font-regular;
          font-style: normal;
          font-size: 18px;
          line-height: 32px;
          margin: 0;
          padding: 0;
          width: 90%;
          text-align: left;
          font-weight: 600;
          text-decoration: none;
          color: $glava-black;

          @include site(weber){
            color: $weber-black;
          }

          @include site(gyproc){
            color: $gyproc-black;
          }

          &:hover {
            text-decoration: none;
          }
          &:after {
            @include background-image("icons/icon-minus.svg", 16, 3);
            position: absolute;
            right: 12px;
            top: 45px;
          }
          &.collapsed {
            font-weight: 500;
            &:after {
              @include background-image("icons/icon-plus.svg", 16, 16);
              position: absolute;
              right: 12px;
              top: 38px;
            }
          }
        }
      }
    }
  }

  &.accordion__content {
    .card {
      &-header {
        padding: 41px 0;
        h2 {
          button {
            width: auto;
            font-weight: 700;
            &:after {
              @include background-image("icons/search-arrow-icon.svg", 15, 12);
              position: relative;
              top: 0;
              left: 15px;
              transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              -moz-transform: rotate(270deg);
              -ms-transform: rotate(270deg);
              -o-transform: rotate(270deg);
            }
            &.collapsed {
              &:after {
                @include background-image(
                  "icons/search-arrow-icon.svg",
                  15,
                  12
                );
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
    select {
      position: absolute;
      right: 0;
      top: 20px;
      color: $gray2;
      min-width: 350px;
      padding: 20px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background-image: url(../images/icons/arrow-down-menu.svg);
      background-size: 10px 10px;
      background-repeat: no-repeat;
      background-position: center right;
    }
  }
}
