.market-form{
  margin-bottom: 60px;
  padding: 56px 64px 64px 64px;
  border: 1px solid #D5D9DC;

  @media (max-width: 768px) {
    padding: 24px;
  }

  .formTitle{
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 36px;
    font-weight: 600;
  }

  .formIngress{
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: #535759;
  }

  .formHtml{
    margin-bottom: 0;
    padding-top: 40px;

    form{
      // display: flex;
      // flex-direction: column;
      > div {
        font-size: 0.9375rem;
        line-height: 1.6;
        font-weight: 600;
        width: 100%;
        margin-bottom: 12px;
      }
      button {
        font-size: 0.9375rem;
        line-height: 1.6;
        font-weight: 600;
        margin-top: 16px;
        padding: 12px 52px 12px 28px;
        border: none;
        border-radius: 24px;
        background-color: $brandYellow2;
        width: fit-content;
        position: relative;
        &:after {
          position: absolute;
          background-image: url(../images/icons/search-arrow-icon.svg);
          background-repeat: no-repeat;
          background-position: center center;
          width: 14px;
          height: 11px;
          content: "";
          display: inline-block;
          background-size: cover;
          right: 28px;
          margin-top: -5px;
          top: 50%;
        }
      }
      td {
        font-size: 0.9375rem;
        line-height: 1.6;
        font-weight: 400;
        padding-bottom: 32px;
        padding-left: 40px;
        input[type="checkbox"] {
          margin-left: -40px;
        }
      }
      input[type="text"], input[type="email"], input[type="number"], input[type="password"], textarea {
        color: #181818;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4737;
        padding: 21px 24px;
        border-bottom: 2px solid #2A2A2A;
        margin: 0px 0 28px;
        background: #F4F6F9;
        width: 100% !important;
        z-index: 2;
        
        @media (max-width: 992px) {
          border-radius: 0;
          -webkit-appearance: none;
          -webkit-border-radius:0px;
        }
      }
      input {
        &[type="radio"]{
              opacity: 0;
              height: 0;
              position: absolute;
          + label {
            font-size: 1.1875rem;
            line-height: 1.4737;
            font-weight: 600;
            padding: 23px 28px 21px 72px;
            display: block;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #D5D9DC;
            position: relative;
            margin-bottom: 8px;
            &::after{
              content: "";
              border: 2px solid #D5D9DC;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              position: absolute;
              display: inline-block;
              box-sizing: border-box;
              left: 28px;
              cursor: pointer;
            }
          }
          &:hover {
            + label {
              border-color: #535759;
            }
          }
          &:checked {
            + label {
              border-color: #8FB7A6;
              &:after {
                border-color: #8FB7A6;
              }
              &:before {
                content: "";
                background-color: #8FB7A6;
                margin: 5px;
                border-radius: 50%;
                width: 14px;
                height: 14px;
                position: absolute;
                display: inline-block;
                box-sizing: border-box;
                left: 28px;
                cursor: pointer;
              }
            }

          }
        }
        &[type="checkbox"] {
          transform: scale(1.5);
          transform-origin: left;
          margin: 5px 27px 3px 0;
          position: absolute;
          + label {
            font-size: 0.9375rem;
            line-height: 1.6;
            font-weight: 400;
            margin-bottom: 32px;
            position: relative;
            padding-left: 40px;
            z-index: -1;

            display: block;
            + * {
              margin-top: 32px;
            }
          }
        }
      }
    }
    
    // Remove unnecessary space
    br{
      display: none;
    }
  }
}
