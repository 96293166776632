.cards-with-bg {
  margin: 80px 0;
  padding: 80px 0;

  @media (max-width: 991px) {
    margin: 46px 0;
    padding: 48 0 58px;
  }
  &__wrapper {
    margin-bottom: -30px;
    @media (max-width: 767px) {
      margin-bottom: -24px;
    }
  }
  &__item{
    margin-bottom: 30px;
    background-color: #fff;
    min-height: calc(100% - 30px);
    display: block;
    @media (max-width: 767px) {
      min-height: unset;
      margin-bottom: 24px;
    }
    &:hover {
      text-decoration: none;
    }
    .img {
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      @include font-size(16);
      line-height: 1.75;
      padding: 24px 28px 32px;
      text-align: center;
      @media (max-width: 991px) {
        @include font-size(18);
        line-height: 1.33;
        padding: 24px;
      }
    }
    &__title {
      @include font-size(21);
      line-height: 1.33;
      font-weight: 600;
      margin-bottom: 8px;
      @media (max-width: 991px) {
        @include font-size(20);
        line-height: 1.2;
        margin-bottom: 4px;
      }
    }
    &__intro {
      color: $gray-800;
    }
  }

  &--primary , &--primary .img{
    background-color: $glava-green-200;

    @include site(weber){
      background-color: $weber-black-50;
    }

    @include site(gyproc){
      background-color: $gyproc-aqua-300;
    }
  }

  &--secondary , &--secondary .img{
    background-color: $glava-black;

    @include site(weber){
      background-color: $weber-anthracite-500;
    }

    @include site(gyproc){
      background-color: $gyproc-sand-300;
    }
  }
}
