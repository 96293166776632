.pop-up-site{
  z-index: 999;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  background: rgba($color: #000000, $alpha: .5);

  // &.show{
  //   display: block;
  // }

  &__wrapper{
    width: auto;
    height: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin: 130px 100px;

    @media (max-width: 992px) {
      margin: 130px 12px 18px 12px;
    }

    &--content{
      width: 100%;
      max-width: 970px;
      padding: 80px 100px;
      background: #ffffff;
      position: relative;
      margin: 0 auto;
      @media (max-width: 992px) {
        padding: 44px 20px;
      }

      .btn-close-pop-up{
        position: absolute;
        top: 16px;
        right: 16px;
        text-decoration: none;
        display: inline-block;
        line-height: 13px;
        width: 13px;
        height: 13px;
        img{
          width: 13px;
          height: 13px;
        }
      }

    }

  }

  .articleWrapper{
    &__content{
      margin-bottom: 40px;
      @media (max-width: 992px) {
        margin-bottom: 8px;
      }
      .contentArticle{
        h1{
          font-weight: 600;
          font-size: 48px;
          line-height: 60px;
          letter-spacing: 0.12px;
          color: #2A2A2A;
          margin-bottom: 36px;
          max-width: 640px;
          padding: 0;
          @media (max-width: 992px) {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
          }
        }
        p{
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0.24px;
          color: #333333;
          padding: 0;
          @media (max-width: 992px) {
            font-size: 15px;
            line-height: 25px;
          }
        }
        .imageWrapper{
          @media (max-width: 992px) {
            padding: 0;
          }
        }
      }
    }

  }


  .btnWrapper{
    a{
      padding: 12px 40px;
      background: #FFE66F;
      border-radius: 24px;
      display: inline-block;
      text-decoration: none;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.16px;
      color: #333333;
      @media (max-width: 992px) {
        padding: 8px 28px;
        font-size: 13px;
        line-height: 24px;
      }
    }
  }


  &.overlayLight{
    background: #ffffff;
    .pop-up-site__wrapper--content{
      box-shadow: 0px 36px 60px rgba(0, 0, 0, 0.15);
    }
  }

  &.ie-11{
    .articleWrapper__content{
      .contentArticle{
        h1{
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
          letter-spacing: 0.12px;
          color: #2A2A2A;
          margin-bottom: 36px;
          max-width: 640px;
          padding: 0;
          @media (max-width: 992px) {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
          }
        }
        p{
          a{
            font-size: 22px;
            line-height: 40px;
            letter-spacing: -0.01em;
            text-decoration: underline;
            color: #4C80A4;
            @media (max-width: 992px) {
              font-size: 17px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }

}
