.text-block{
  .articleWrapper__content{
    .container{
      position: relative;

      @media (max-width: 992px) {
        position: unset;
      }

      .row{
        .content-grid{
          position: unset;

          @media (max-width: 992px) {
            position: relative;
          }

          .contentArticle{
            figure{
              margin-bottom: 0px;

              @media (max-width: 992px) {
                  margin-bottom: 20px;
                  float: none !important;
                  width: 100% !important;
              }

              img{
                position: absolute;
                right: 15px; // to compensate bootstrap padding-right
                max-width: 36.85%;

                @media (max-width: 992px) {
                  position: unset;
                  right: inherit;
                  max-width: inherit;
                  width: 100%;
                }
              }

              figcaption{
                margin-top: 20px;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #939399;

                &.absoluteCaption{
                  position: absolute;
                  right: 15px;
                  width: 36.85%;
                }
              }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              @media (max-width: 992px) {
                padding-left: 0;
                padding-right: 0;
              }
            }

            p{
              @media (max-width: 992px) {
                padding-left: 0;
                padding-right: 0;
              }
            }

            ol,
            ul{
              @media (max-width: 992px) {
                padding: 0 0 0 22px;
              }
            }

            .ingress{
              @media (max-width: 992px) {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
