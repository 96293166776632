$num_columns: 12;
$gutter: 32px;

// $grid-columns: 12 !default;
// $grid-gutter-width: 32px !default;
// @media (max-width: 768px) {
//   $grid-columns: 8 !default;
//   $grid-gutter-width: 20px !default;
// }

// @media (max-width: 768px) {
//   .row > .col,
//   .row > [class*="col-"] {
//     padding-right: 10px;
//     padding-left: 10px;
//   }
// }

$grid-breakpoints: (
  xxs: 0px,
  xs: 320px,
  // Extra small
    sm: 576px,
  // Small
    md: 768px,
  // Medium
    lg: 992px,
  // Large
    xl: 1200px // Extra large
) !default;

$container-max-widths: (
  sm: 540px,
  // Small
    md: 720px,
  // Medium
    lg: 960px,
  // Large
    xl: 1466px // Extra large
);

$env: "development" !default;
$path: "../images/" !default;

@if ($env == "production") {
  $path: "../img/";
}

//import bootstrap

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/utilities";

//base application
@import "base/typography";
@import "base/mixins";
@import "base/animations";
@import "base/styleguide";
@import "base/image-aspect-ratio";
@import "base/styled-text";
@import "base/cards";

//components
@import "components/buttons";
@import "components/box";
@import "components/searchbar";
@import "components/table";
@import "components/header";
@import "components/footer";
@import "components/hero";
@import "components/insulation-map";
@import "components/menu";
@import "components/info-window";
@import "components/registration-form";
@import "components/registration-form-successful-submission";




// @import "components/product"; OLD
@import "components/article";
@import "components/page-header";
@import "components/category-block";
@import "components/accordion";
@import "components/employee";
@import "components/list-article";
@import "components/select-aktuelt";
@import "components/aktuelt";
@import "components/search-results";
@import "components/carousel";
@import "components/product-filter"; // OLD ?
@import "components/soluctions-filter";
@import "components/price-lists";
@import "components/pop-up-site";
@import "components/deduction-table";
// @import "components/search-products"; OLD
@import "components/aside-filter.scss";
@import "components/new-search-products.scss";
@import "components/list-blocks-solution.scss";
@import "components/dropdown-filter.scss";
@import "components/newsletter.scss";
@import "components/temamal";
@import "components/banner";
@import "components/text-block.scss";
@import "components/products-block.scss";
@import "components/formHtml.scss";
@import "components/macroTag.scss";
@import "components/pagination";
@import "components/solution-list";
@import "components/new-product";
@import "components/content-tabs";
@import "components/solution";
@import "components/contactus";
@import "components/gallery";
@import "components/link-list.scss";
@import "components/link-cards.scss";
@import "components/carousel-news.scss";
@import "components/blog-posts.scss";
@import "components/card-side-img.scss";
@import "components/article-list.scss";
@import "components/square-card.scss";
@import "components/form-box.scss";
@import "components/video-carousel.scss";
@import "components/article-grid";
@import "components/faq";
@import "components/logo-line";
@import "components/text-and-media";
@import "components/product-block-dynamic";
@import "components/folder";
@import "components/form-box.scss";
@import "components/teaser";
@import "components/article-slider";
@import "components/cards-with-bg";
@import "components/job-position";
@import "components/featured-jobs";
@import "components/jobs-link-list";
@import "components/career-page";
@import "components/campaign-page-full-image";
@import "components/content-text";
@import "components/icon-list";
@import "components/block-list";
@import "components/banner-global";
@import "components/video-poster";
@import "components/hero-flexible";
@import "components/color-box";
@import "components/calculator";
//layouts
