.form-box {
  margin-top: 144px;
  margin-bottom: 80px;
  @media (max-width: 1360px) {
    margin-top: 104;
  }
  @media (max-width: 991px){
    margin-top: 96px;
    margin-bottom: 40px;
    background: $glava-black;

    @include site(weber){
      background: $weber-anthracite-500;
    }

    @include site(gyproc){
      background: $gyproc-aqua-300;
    }
  }

  @media (max-width: 767px) {
    margin-top: 72px;
  }

  &__wrapp {
    display: flex;
    color: $white;
    background: $glava-black;

    @include site(weber){
      background: $weber-anthracite-500;
    }

    @include site(gyproc){
      color: $gyproc-black;
      background: $gyproc-aqua-300;
    }

    @media (max-width: 991px){
      flex-direction: column;
    }
  }
  &__img {
    width: 50%;
    @media (max-width: 991px){
      width: 100vw;
      margin-left: calc(50% - 50vw);
    }
    .img {
      height: 100%;
      padding-top: calc((944 / 840) * 100%);
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    width: 50%;
    padding: 40px 80px 88px;
    display: flex;
    flex-direction: column;
    justify-content: end;

    @media (min-width: 1361px) {
      padding-left: 130px;
      padding-right: 130px;
    }

    @media (max-width: 1360px) {
      padding: 20px 48px 32px;
    }
    @media (max-width: 991px) {
      padding: 40px 0;
      width: 100%;
    }
    @media (max-width: 767px) {
    }
    h2 {
      @include font-size(36);
      line-height: 1.2;
      letter-spacing: -0.0075em;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        @include font-size(32);
        letter-spacing: -0.005em;
      }
      @media (max-width: 767px) {
        @include font-size(28);
        line-height: 1.29;
      }
    }
    p {
      @include font-size(18);
      line-height: 1.5;
      letter-spacing: 0.015em;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        @include font-size(16);
        line-height: 1.5;
      }
      @media (max-width: 767px) {
        @include font-size(15);
        line-height: 1.4;
      }
    }
    form {
      @media (min-width: 768px) {
        margin-top: 8px;
      }
    }
    .form-group {
      input[type="text"], input[type="email"], input[type="number"], input[type="password"], textarea {
        font-size: 1rem;
        line-height: 1.25;
        padding: 20px 24px;
        border: none;
        background: #fff;
        width: 100% !important;
        color: $glava-black;

        @include site(weber){
          color: $weber-black;
        }

        @include site(gyproc){
          color: $gyproc-black;
        }

        @media (max-width: 991px) {
          @include font-size(15);
          line-height: 1.33;
          border-radius: 0;
          padding: 16px;
          -webkit-appearance: none;
          -webkit-border-radius:0px;
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $gray-600;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $gray-600;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $gray-600;
        }
      }

      input[type="radio"] {
        margin-right: 10px;
      }
    }
    .btn-group {
      display: flex;
      margin-top: 24px;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 767px) {
        margin-top: 16px;
      }
      .label-info {
        color: inherit;
        @include font-size(14);
        line-height: 1.3;
        letter-spacing: 0.015em;
        margin-left: 24px;
        flex: 1;
        @media (max-width: 991px) {
          @include font-size(13);
          line-height: 1.4;
          margin: 16px 0 0;
          width: 100%;
          flex: auto;
        }
      }
      .button {
        @include font-size(16);
        line-height: 1.25;
        letter-spacing: 0.01em;
        padding: 16px 32px;

        @include site(glava){
          background-color: $glava-yellow;
        }

        @media (max-width: 991px) {
          @include font-size(15);
          line-height: 1.33;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  &--1x1 {
    @media (min-width: 1361px) {
      margin-top: 124px;
    }
    .form-box {
      &__img {
        .img {
          padding-top: 100%;
        }
      }
      &__content {
        @media (min-width: 1361px) {
          padding-right: 80px;
          padding-bottom: 80px;
          padding-left: 80px;
        }
      }
    }
  }
  &--3x2 {
    @media (min-width: 1361px) {
      margin-top: 124px;
    }
    .form-box {
      &__img {
        .img {
          padding-top: calc((2 / 3) * 100%);
        }
      }
      &__content {
        @media (min-width: 1361px) {
          padding-right: 80px;
          padding-bottom: 56px;
          padding-left: 80px;
        }
      }
    }
  }
  &--reverse {
    .form-box {
      &__wrapp {
        @media (min-width: 992px) {
          flex-direction: row-reverse;
        }
      }
    }
  }
  &--flex{
    .form-box{
      &__content{
        @media (max-width: 991px) {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
    }
  }
}
