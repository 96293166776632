.solution-list{
    .aside-filter-top{
        .wrapper{
            .aside-filter-top__mobile-buttons{
                .btn-show-views{
                    @media(max-width: 992px){
                        display: none;
                    }
                }
            }
        }
    }

    .list-wrapper{
        overflow: hidden;
        @media(max-width: 992px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .bg-overlay{
        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.7);
        z-index: 99;
    }

    .loading-animation{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1;
        display: block;
        text-align: center;
        padding-top: 120px;

        @media(max-width: 992px){
          padding-top: 30px;
        }


        @keyframes ldio-a2ac99d2nu {
          0% { opacity: 1 }
          50% { opacity: .5 }
          100% { opacity: 1 }
        }
        .ldio-a2ac99d2nu div {
          position: absolute;
          width: 10px;
          height: 50px;
          top: 30px;
          animation: ldio-a2ac99d2nu 1s cubic-bezier(0.5,0,0.5,1) infinite;
        }.ldio-a2ac99d2nu div:nth-child(1) {
          transform: translate(15px,0);
          background: #353535;
          animation-delay: -0.6s;
        }.ldio-a2ac99d2nu div:nth-child(2) {
          transform: translate(35px,0);
          background: #353535;
          animation-delay: -0.4s;
        }.ldio-a2ac99d2nu div:nth-child(3) {
          transform: translate(55px,0);
          background: #353535;
          animation-delay: -0.2s;
        }.ldio-a2ac99d2nu div:nth-child(4) {
          transform: translate(75px,0);
          background: #353535;
          animation-delay: -1s;
        }
        .loadingio-spinner-bars-lcvhnzzsobr {
          width: 74px;
          height: 74px;
          display: inline-block;
          //overflow: hidden;
          text-align: center;

          span{
            font-weight: 600;
          }
        }
        .ldio-a2ac99d2nu {
          width: 100%;
          height: 100%;
          position: relative;
          transform: translateZ(0) scale(0.74);
          backface-visibility: hidden;
          transform-origin: 0 0;
        }
        .ldio-a2ac99d2nu div { box-sizing: content-box; }
    }
}

.solution-list__list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-bottom: 12px;
    margin-left: -16px;

    @media(max-width: 992px){
        margin-bottom: 25px;
        margin-right: -7.5px;
        margin-left: -7.5px;
    }

    .solution-list__item{
        position: relative;
        width: 33.33%;
        margin-bottom: 40px;
        padding: 0 16px;

        @media(max-width: 992px){
          width: 33.33%;
          padding: 0 7.5px;
          margin-bottom: 15px;
        }

        @media(max-width: 768px){
          width: 50%;
        }

        .wrapper{
            display: flex;
            flex-direction: column;
            height: 100%;
            border: 1px solid #E5E6E8;

            >a{
                display: block;
                text-decoration: none;

                .image-wrapper{
                    figure{
                        img{
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .description{
                    padding: 24px 28px;
                    font-size: 21px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #222222;

                    @media(max-width: 992px){
                        padding: 12px 16px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            .tags{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: auto;
                padding: 0 28px 24px 28px;

                @media(max-width: 992px){
                    padding: 0 16px 16px;
                }

                .tag{
                    display: none;
                    margin-right: 4px;
                    margin-bottom: 4px;
                    padding: 6px 12px;
                    font-size: 12.5px;
                    line-height: 16px;
                    font-weight: 500;
                    background-color: #F1F2F4;
                    border: none;
                    text-decoration: none;
                    cursor: default;

                    @media(max-width: 992px){
                        font-size: 11px;
                        padding: 4px 8px;
                    }

                    &.active{
                        display: block;
                    }

                    &:hover{
                        color: white;
                        background-color: #3A3C45;
                    }
                }

                .btn-show-more{
                    display: none;
                    min-height: 28px;
                    margin-right: 0;
                    margin-bottom: 4px;
                    padding: 6px 17px;
                    background-image: url("../images/./icons/ellipsis.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #F1F2F4;
                    border: none;

                    @media(max-width: 992px){
                        min-height: 24px;
                    }

                    &:hover{
                        background-color: #3A3C45;
                        background-image: url("../images/./icons/ellipsis-white.svg");
                    }
                }

                &.hasHiddenTags{
                    .btn-show-more{
                        display: block;
                    }
                }
            }
        }
    }

    &--list-view{
        margin-bottom: 80px;

        @media(max-width: 992px){
            margin-bottom: 40px;
        }

        // List view exists only on desktop
        @media(min-width: 992px){
            .solution-list__item{
                width: 100%;
                min-height: 132px;
                margin-bottom: 12px;

                &:last-child{
                    margin-bottom: 0;
                }

                .wrapper{
                    position: relative;

                    >a{
                        position: absolute;
                        width: 100%;
                        height: 100%;

                        &::after{
                            content: "";
                            position: absolute;
                            top: calc(50% - 14px);
                            right: 45px;
                            width: 28px;
                            height: 28px;
                            display: block;
                            background-image: url("../images/./icons/arrow-right.svg");
                            background-repeat: no-repeat;
                            background-position: 14px center;
                            background-size: 9px;
                        }

                        &:hover{
                            &::before{
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: transparentize(#E5E6E8, 0.9);
                                z-index: -1;
                            }

                            &::after{
                                background-image: url("../images/./icons/arrow-hero.svg");
                                background-size: 19px;
                                background-position: center;
                            }
                        }

                        .image-wrapper{
                            position: absolute;
                            width: 184px;
                            height: 100%;

                            figure{
                                height: 100%;
                                padding: 0;

                                img{
                                    position: relative;
                                    top: 0;
                                    left: 0;
                                    transform: none;
                                    object-fit: cover;
                                }
                            }
                        }

                        .description{
                            padding: 28px 132px 12px 216px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .tags{
                        padding: 0px 132px 28px 216px;
                        pointer-events: none;
                        z-index: 1;

                        &::before{
                            content: "";
                            display: block;
                            height: 70px;
                            width: 100%;
                        }

                        .tag{
                            position: relative;
                            pointer-events: all;

                            &::after{
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: -4px;
                                width: 4px;
                                height: 100%;
                            }
                        }

                        .btn-show-more{
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
}
