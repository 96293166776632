.icon-list {
  margin: 124px 0;

  @media (max-width: 991px) {
    margin: 60px 0;
  }

  &__wrapper {
    margin-bottom: -48px;
    @media (max-width: 991px) {
      margin-bottom: -32px;
    }
  }
  &__item {
    text-align: center;
    margin-bottom: 48px;
    @media (max-width: 991px) {
      margin-bottom: 32px;
    }
    @media (max-width: 767px) {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }
    &__icon {
      margin: 0 auto 24px;
      width: 80px;
      height: 80px;
      @media (max-width: 991px) {
        margin-bottom: 12px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__title {
      @include font-size (24);
      line-height: 1.2;
      font-weight: 600;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        @include font-size (20);
        letter-spacing: normal;
        margin-bottom: 8px;
      }
    }
    &__p {
      @include font-size (20);
      line-height: 1.40;
      letter-spacing: 0.0135em;
      color: $gray-800;
      @media (max-width: 991px) {
        @include font-size (16);
        letter-spacing: normal;
      }
    }
  }
  &--four {
    @media (min-width: 568px) {
      .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (min-width: 992px) {
      .col-md-4 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .icon-list__item {
        &__title {
          @include font-size (22);
          letter-spacing: normal;
        }
        &__p {
          @include font-size (18);
          letter-spacing: 0.0115em;
        }
      }
    }
  }

}
