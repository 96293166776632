// This is the new button style created in the 2023 redesign
// Using ".button" class to avoid conflicts with existing buttons that already use the ".btn" class
.button{
  display: inline-flex;
  align-items: center;
  padding: 12px 24px;
  -webkit-appearance: none;
  border-radius: 0;
  color: $white;
  background-color: $glava-black;
  border: none;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;

  @include site(weber){
    background-color: $weber-black;
  }

  @include site(gyproc){
    background-color: $gyproc-black;
  }

  &:hover{
    color: $white;
    text-decoration: none;
    box-shadow: 0 0 0 2px $gray-400;
  }

  // Small button
  &--small{
    font-size: 16px;
    padding: 12px 20px;
  }

  // Large button
  &--large{
    padding: 18px 28px;
  }

  // Right icon
  &--icon-right{
    padding-right: 20px;

    &.button--small{
      padding-right: 18px;
    }

    &.button--large{
      padding-right: 24px;
    }

    .icon{
      margin-left: 8px;
    }
  }

  // Left icon
  &--icon-left{
    padding-left: 20px;

    &.button--small{
      padding-left: 18px;
    }

    &.button--large{
      padding-left: 24px;
    }

    .icon{
      margin-right: 8px;
    }
  }

  .icon{
    width: 20px;

    path{
      fill: $white;
    }
  }

  // Secondary button (yellow or blue)
  &--secondary{
    background-color: $glava-yellow-600;
    color: $glava-black;

    @include site(weber){
      background-color: $weber-yellow;
      color: $weber-black;
    }

    @include site(gyproc){
      background-color: $gyproc-blue;
      color: $white;
    }

    .icon{
      path{
        fill: $glava-black;

        @include site(weber){
          fill: $weber-black;
        }

        @include site(gyproc){
          fill: $white;
        }
      }
    }

    &:hover{
      box-shadow: 0 0 0 2px $glava-yellow;
      color: $glava-black;

      @include site(weber){
        box-shadow: 0 0 0 2px #D7C204;
        color: $weber-black;
      }

      @include site(gyproc){
        box-shadow: 0 0 0 2px $gyproc-blue-750;
        color: $white;
      }
    }
  }

  // Outline button (white)
  &--outline{
    background-color: $white;
    border: 1px solid $glava-black;
    color: $glava-black;

    @include site(weber){
      background-color: $white;
      border-color: $weber-black;
      color: $weber-black;
    }

    @include site(gyproc){
      background-color: $white;
      border-color: $gyproc-black;
      color: $gyproc-black;
    }

    .icon{
      path{
        fill: $glava-black;

        @include site(weber){
          fill: $weber-black;
        }

        @include site(gyproc){
          fill: $gyproc-black;
        }
      }
    }

    &:hover{
      box-shadow: 0 0 0 1px $glava-black;
      color: $glava-black;

      @include site(weber){
        box-shadow: 0 0 0 1px $weber-black;
        color: $weber-black;
      }

      @include site(gyproc){
        box-shadow: 0 0 0 1px $gyproc-black;
        color: $gyproc-black;
      }
    }
  }

  // Disabled button (must add tabindex="-1" to the button html)
  &.disabled, &[disabled]{
    background-color: $gray-100;
    color: $gray-600;
    border: none;
    pointer-events: none;

    .icon{
      path{
        fill: $gray-600;
      }
    }
  }

  // Rounded button
  &--rounded{
    border-radius: 1000px;
  }
}
