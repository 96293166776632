.link-cards {
  margin-bottom: 80px;
  @media(max-width: 991px) {
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 40px;
    @media(max-width: 991px) {
      margin-bottom: 20px;
    }
  }
  &__wrapper {
    margin-bottom: -30px;
    @media(max-width: 991px) {
      margin-bottom: -16px;
    }
    &--short {
      @media(max-width: 991px) {
        margin-bottom: -8px;
      }
      .col-lg-3 {
        @media(min-width: 992px) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
      .link-cards__item {
        padding: 0;
        @media(max-width: 991px) {
          margin-bottom: 8px;
          height: auto;
        }
        &:hover {
          cursor: unset;
        }
        &__content {
          @include font-size(20);
          line-height: 2rem;
          padding: 40px 21px 40px 48px;
          position: static;
          @media(max-width: 991px) {
            @include font-size(18);
            line-height: 1.334;
            padding: 23px 24px;
          }
        }
        span {
          @include font-size(24);
          margin-bottom: 12px;
          @media(max-width: 991px) {
            @include font-size(20);
            line-height: 1.4;
            margin-bottom: 6px;
            margin-top: 0;
          }
        }
      }
    }
    &--icon {
      @media(max-width: 991px) {
        margin-right: -3px;
        margin-left: -3px;
      }
      .col-lg-3 {
        @media(max-width: 991px) {
          flex: 0 0 50%;
          max-width: 50%;
          padding: 0 8px;
        }
      }
      .link-cards__item {
        @media(max-width: 991px) {
          @include font-size(16);
          line-height: 1.25;
          padding-top: 100%;
          margin-bottom: 16px;
        }
        &__content {
          @media(min-width: 992px) {
            padding: 84px 44px 44px;
          }
          @media(max-width: 991px) {
            position: absolute;
            padding-top: 56px;
            span {
              margin-top: 0;
            }
          }
        }
      }
    }
    &:not(.link-cards__wrapper--short) {
      .link-cards__item {
      }
    }
  }
  &__item {
    @include font-size(22);
    line-height: 1.364;
    display: block;
    text-decoration: none;
    padding-top: 100%;
    position: relative;
    margin-bottom: 30px;
    @media(max-width: 991px) {
      @include font-size(20);
      line-height: 1;
      padding: 0;
      height: 122px;
      margin-bottom: 16px;
    }
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 0 0 3px $glava-black;

      @include site(weber){
        box-shadow: inset 0 0 0 3px $weber-black;
        // background-color: $white;
      }

      @include site(gyproc){
        box-shadow: inset 0 0 0 3px $gyproc-black;
        // background-color: $white;
      }

      @media(max-width: 991px) {
        box-shadow: inset 0 0 0 2px $glava-black;
        // background-color: $white;

        @include site(weber){
          box-shadow: inset 0 0 0 2px $weber-black;
          // background-color: $white;
        }

        @include site(gyproc){
          box-shadow: inset 0 0 0 2px $gyproc-black;
          // background-color: $white;
        }
      }
    }
    &__content {
      padding: 48px 52px;
      position: absolute;
      max-height: 100%;
      width: 100%;
      bottom: 0;
      left: 0;
      overflow: hidden;
      cursor: pointer;
      @media (max-width: 1199px) {
        padding: 16px;
      }
      span {
        font-weight: 600;
        display: block;
        @media (max-width: 1199px) {
          margin-top: 4px;
        }
      }
    }
    &__icon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 44px;
      left: 44px;
      @media(max-width: 991px) {
        width: 32px;
        height: 32px;
        top: 16px;
        left: 16px;
      }
    }

    &--primary{
      background-color: $glava-yellow-600;

      @include site(weber){
        background-color: $weber-black-50;
      }

      @include site(gyproc){
        background-color: $gyproc-aqua-300;
      }
    }

    &--secondary{
      background-color: $glava-green-200;

      @include site(weber){
        background-color: $weber-yellow;
      }

      @include site(gyproc){
        background-color: $gyproc-sand-300;
      }
    }

    &--tertiary{
      background-color: $glava-black;
      color: $white;

      @include site(weber){
        background-color: $weber-anthracite-500;
      }

      @include site(gyproc){
        background-color: $gyproc-blue;
      }
    }

    &:hover{
      background-color: $white;
    }
  }
}
