.temamal{
  margin-top: 16px;
  .temamal__header{
    margin-bottom: 80px;
    z-index: 0;

    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
    .breadcrumbs--slash {
      margin-bottom: 8px;
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }
    }
    &__img {
      height: 100%;
      @media (max-width: 991px) {
        margin-top: 24px;
        width: 100vw;
        height: initial;
        margin-left: calc(50% - 50vw);
        padding-top: calc(30200vw/375);
      }
      img {
        min-height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      height: initial;
      padding: 80px 120px 92px;

      @media (min-width: 992px) {
        height: 100%;
        background-color: $glava-yellow-600;

        @include site(weber){
          background-color: $weber-yellow;
        }

        @include site(gyproc){
          background-color: $gyproc-aqua-300;
        }
      }

      @media (max-width: 1420px) {
        padding: 11.7% 17.6% 13.5%;
      }
      @media (max-width: 991px) {
        padding: 0;
        background-color: transparent;
      }

      h1{
        margin-bottom: 0px;
        font-weight: 600;
        @include font-size(40);
        line-height: 1.2;
        letter-spacing: normal;
        color: inherit;

        // @include site(weber){
        //   color: $weber-black;
        // }

        // @include site(gyproc){
        //   color: $gyproc-black;
        // }

        @media (max-width: 1199px) {
          @include font-size(32);
          line-height: 1.14;
          letter-spacing: 0.00375em;
        }
      }
      p, ul {
        font-weight: 500;
        @include font-size(21);
        line-height: 1.62;
        letter-spacing: 0.01em;
        margin-top: 20px;
        @media (max-width: 1199px) {
          @include font-size(20);
          line-height: 1.5;
        }
        @media (max-width: 991px) {
          margin-top: 24px;
        }
      }
      &__link {
        font-weight: 600;
        @include font-size(21);
        letter-spacing: -0.005em;
        line-height: 1.33;
        display: block;
        margin-top: 30px;
        padding-left: 34px;
        background-image: url("../images/icons/arrow-right-24.svg");
        background-repeat: no-repeat;
        background-position: 0 2px;
        min-height: 1.74rem;
        @media (max-width: 991px) {
          margin-top: 24px;
        }
        &:hover {
          text-decoration: none;
        }
      }

      &--secondary{
        @media (min-width: 992px) {
          background-color: $glava-green-200;

          @include site(weber){
            background-color: $weber-black-50;
          }

          @include site(gyproc){
            background-color: $gyproc-sand-300;
          }
        }
      }

      &--tertiary{
        @media (min-width: 992px) {
          color: $white;
          background-color: $glava-black;

          @include site(weber){
            background-color: $weber-anthracite-500;
          }

          @include site(gyproc){
            color: $gyproc-sand-300;
            background-color: $gyproc-blue;
          }

          h1{
            color: $white;

            @include site(gyproc){
              color: $gyproc-sand-300;
            }
          }

          .temamal__header__content__link{
            background-image: url("../images/icons/arrow-right-24-w.svg");

            @include site(gyproc){
              background-image: url("../images/icons/arrow-right-24-sand.svg");
            }
          }
        }
      }
    }
  }
}
