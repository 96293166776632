.article-list{

    @media(max-width: 992px){
        overflow: hidden;
    }

    .breadcrumbs{
        //display: none;
        @media(max-width: 992px){
            display: block;
        }
    }

    .filter-mobile{
        display: none;
        margin-bottom: 36px;

        button{
            height: 52px;
            width: 100%;
            border: 1px solid #CCCDCF;
            background-color: #F1F2F6;
            font-size: 17px;
            line-height: 24px;
        }

        @media(max-width: 992px){
            display: block;
        }

        &+#solution__filter{
            margin-top: -26px;
        }
    }

    .slick-arrow{
        width: 60px;
        height: 60px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        z-index: 1;
        top: 100px;

        @media(max-width: 1024px){
            top: 140px;
        }

        @media(max-width: 991px){
            //top: 110px;
            top: 100px;
        }

        @media(max-width: 767px){
            top: 40%;
        }

        @media(max-width: 576px){
            top: 35%;
        }

        @media(max-width: 425px){
            top: 30%;
        }

        &:after, &:before{
            display: none;
        }

        &.slick-disabled{
            display: none !important;
        }

        &.slick-prev{
            display: none;
            @media(max-width: 767px){
                //left: -10px;
                //display: none !important;
                left: 10px;
            }
        }

        &.slick-next{
            @media(max-width: 767px){
                //right: 0px;
                right: 10px;
            }
        }
    }

    h1{
        font-family: $font-regular;
        font-weight: 600;
        font-size: 60px;
        line-height: 56px;
        margin-bottom: 44px;
        color: #2a2a2a;

        @media(max-width: 992px){
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 36px;
        }
    }

    .article-featured{
        position: relative;
        max-height: 567px;
        height: auto;

        @media(max-width: 992px){
            margin-bottom: 40px;
            min-height: 1px;
            max-height: 100%;
        }

        figure{
            width: 100%;
            height: 100%;
            position: relative;

            @media(max-width: 992px){
                height: auto;
            }

            img{
                width: 100%;
                height: auto;

                @media(max-width: 992px){
                    object-fit: cover;
                }
            }
        }
        .imageFilter{
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            top: 0;
            left: 0;
        }

        &__content{
            display: flex;
            flex-direction: column;
            position: absolute;
            padding: 56px 64px;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            @media(max-width: 992px){
                padding: 20px 24px;
                height: 100%;
            }

            h2{
                font-family: $font-regular;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 20px;
                color: #FFFFFF;
                max-height: 144px;

                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;

                @media(max-width: 1200px){
                    -webkit-line-clamp: 2;
                    max-height: 94px;
                }

                @media(max-width: 992px){
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 10px;
                }

            }
            .intro{
                color: #FFFFFF;
                font-family: $font-regular;
                font-weight: 400;
                font-size: 22px;
                line-height: 32px;
                max-width: 424px;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                max-height: 125px;

                @media(max-width: 1200px){
                    -webkit-line-clamp: 2;
                    max-height: 62px;
                }

                @media(max-width: 992px){
                    font-size: 15px;
                    line-height: 20px;
                }

                @media(max-width: 320px){
                    -webkit-line-clamp: 1;
                }

            }
            a{
                &.btn{
                    margin-top: auto;
                    position: relative;
                    background-color: #FFFFFF;
                    color: #151515;

                    @media(max-width: 992px){
                        max-width: 145px;
                        height: 44px;
                        font-size: 13px;
                        margin-top: auto;
                        //margin-bottom: 30px;
                    }
                }
            }
        }

    }

    .article-item{
        margin-top: 80px;

        @media(max-width: 992px){
            margin-top: 0;
        }

        &__item{
            position: relative;
            margin-bottom: 80px;

            @media(max-width: 992px){
                margin-bottom: 40px;
            }

            a{
                &:hover{
                    color: inherit
                }
            }
            figure{
                margin-bottom: 20px;
                @media(max-width: 992px){
                    margin-bottom: 12px;
                }
            }
            .macroTag{
                top: 0;
                bottom: auto;
                font-size: 12px;
                @media(max-width: 992px){
                    font-size: 10px;
                    line-height: 19px;
                }
            }
            .subject{
                text-transform: uppercase;
                color: #7B7B7B;
                font-size: 12px;
                line-height: 19px;
                margin-bottom: 10px;
                display: block;
                @media(max-width: 992px){
                    font-size: 10px;
                    line-height: 19px;
                }

            }
            h3{
                font-size: 22px;
                line-height: 32px;
                margin-bottom: 12px;
                color: #151515;

                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;

                a{
                    color: #151515;
                }

                @media(max-width: 992px){
                    font-size: 20px;
                    line-height: 28px;
                }

            }
            .read-more{
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 10px;
                font-weight: 600;
                padding-bottom: 4px;
                border-bottom: 2px solid #C7DCE9;
                color: #151515;

                @media(max-width: 992px){
                    font-size: 13px;
                    line-height: 15px;
                }

                &:hover{
                    color: #151515;
                    text-decoration: none;
                }
            }
        }
    }

    .article-featured-full{
        padding: 40px 0 0 0;
        border-top: 2px solid #2A2A2A;
        margin-top: 88px;
        margin-bottom: 100px;

        @media(max-width: 992px){
            width: calc(100% + 40px);
            margin: -30px -20px 40px -20px;
            border-top: 0;
            .row{
                margin-left: 0;
                margin-right: 0;
                .col-12{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        h4{
            font-size: 28px;
            line-height: 40px;
            margin-bottom: 40px;
            color: #2A2A2A;
            @media(max-width: 992px){
                display: none;
            }
        }

        .article-featured-full__item{
            display: flex;
            width: 100%;
            flex: 0 0 50%;
            height: 520px;
            background-color: #E6EFF5;

            @media(max-width: 992px){
                flex-direction: column;
                height: auto;
                padding-bottom: 40px;
                margin-bottom: 0;
            }

            >a{
                flex-basis: 50%;
                position: relative;

                .macroTag{
                    top: 0;
                    left: 0;
                    @media(max-width: 992px){
                        display: none;
                    }
                }

                figure{
                    margin-bottom: 0;
                    height: 100%;
                    @media(max-width: 992px){
                        padding: 20px;
                    }

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &--content{
                flex-basis: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media(max-width: 992px){
                    justify-content: flex-start;
                }

                h3{
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 52px;
                    margin-bottom: 38px;
                    text-align: center;
                    max-width: 530px;
                    padding: 0 50px;

                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;

                    a{
                        //color: inherit;
                        color: #2A2A2A;
                    }

                    @media(max-width: 992px){
                        font-size: 24px;
                        line-height: 30px;
                        max-width: 600px;
                        margin: 0 auto 20px auto;
                    }

                    @media(max-width: 768px){
                        max-width: 395px;
                        padding: 0 20px;
                    }

                    @media(max-width: 540px){
                        max-width: 295px;
                        padding: 0 20px;
                    }

                }

                .subject{
                    text-transform: uppercase;
                    color: #637886;
                    font-size: 12px;
                    line-height: 19px;
                    margin-bottom: 20px;
                    display: block;

                    @media(max-width: 992px){
                        font-size: 10px;
                        margin-bottom: 12px;
                        padding: 0 20px;
                        line-height: normal;
                        text-align: center;
                    }
                }

                .btn{
                    padding: 14px 43px;

                    &.btn-arrow{
                        &:after{
                            right: 42px;
                        }
                    }

                    @media(max-width: 992px){
                        padding: 14px 20px;
                        &.btn-arrow{
                            &:after{
                                right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .articles-grid{
        margin-top: 100px;
        margin-bottom: 80px;
        @media(max-width: 992px){
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    #solution__filter{
       width: 100%;

       @media(max-width: 992px){
           //display: none;
       }

        .filterOptions{
            padding-bottom: 40px;
            border-bottom: 2px solid #2a2a2a;
            margin-bottom: 60px;

            @media(max-width: 992px){
                padding-bottom: 0;
                border-bottom: 0;
                margin-bottom: 40px;
            }

            .filterBox{
                padding-bottom: 0;

                @media(max-width: 992px){
                    display: none;
                    &.active-mobile{
                        display: block;
                    }
                }

                .wrapper{
                    @media(max-width: 992px){
                        width: 100%;
                        padding-bottom: 15px;
                    }
                }

                .custom-select{
                    margin: 0 16px 0 0;

                    @media(max-width: 992px){
                        margin-right: 0;
                    }
                }
            }

            .filterOptions__buttons{
                display: flex;
                @media(max-width: 992px){
                  width: 100%;
                }

                button.showFilter{
                  display: none;
                  width: 100%;
                  min-width: 135px;
                  margin-bottom: 0px;
                  padding: 14px 16px;
                  background-color: #FFE66F;
                  color: #2B2B2C;
                  border: 0;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 17px;
                  line-height: 24px;
                  letter-spacing: 0.24px;
                  white-space: nowrap;

                  @media(max-width: 992px){
                    display: block;
                  }
                }

                button.clearFilter{
                    margin-bottom: 0;
                  @media(max-width: 992px){
                    display: none;
                    width: 40%;
                    height: inherit;
                    margin-left: 16px;
                    padding: 14px 16px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: 0.24px;
                    color: #2B2B2C;
                    white-space: normal;
                  }
                }

                &.buttons--clear{
                  button.showFilter{
                    @media (max-width: 768px) {
                      width: 60%;
                    }

                    @media (max-width: 375px) {
                      width: 55%;
                    }
                  }
                  button.clearFilter{
                    display: block;

                    @media (max-width: 768px) {
                      width: 40%;
                    }

                    @media (max-width: 375px) {
                      width: 45%;
                    }
                  }
                }
            }
        }
    }

}


.hero.aktuelt {
    margin-bottom: 20px;
    @media (max-width: 992px) {
        margin-bottom: 0px;
    }
    .hero__content {
        @media (max-width: 992px) {
            margin-bottom: 48px;
        }
    }
    &.meddle {
        margin-bottom: 88px;
        @media (max-width: 992px) {
            margin-bottom: 48px;
        }
    }
}

[data-portal-region="aktueltRegion"] .hero {
    margin-bottom: 0px;
}
[data-portal-region="aktueltRegion"] .hero__content {
    margin-bottom: 0px;
}
[data-portal-component-type="layout"].layout-list-blocks .listArticleWrapper__list .line {
    margin-top: 88px;
}
[data-portal-region="aktueltRegion"] .part-hero {
    margin-top: 140px;
}
[data-portal-region="aktueltRegion"] .part-hero:first-child {
    margin-top: 0px;
}
.layout-landing-page + .layout-list-blocks {
    margin-top: -38px;
}

.layout-list-blocks-solutions [data-portal-region="aktueltRegion"] {
    margin-bottom: 100px;
}

@media (max-width: 992px) {
    [data-portal-region="aktueltRegion"] .hero__content {
        margin-bottom: 60px;
    }

    [data-portal-region="aktueltRegion"] .part-hero .hero .row div:last-child .hero__content {
        margin-bottom: 0px;
    }

    [data-portal-component-type="layout"].layout-list-blocks .listArticleWrapper__list .line {
        margin-top: 40px;
        margin-bottom: 50px;
    }

    [data-portal-region="aktueltRegion"] .part-hero {
        margin-top: 60px;
    }
    .layout-landing-page + .layout-list-blocks {
        margin-top: -38px;
    }

    .layout-list-blocks-solutions [data-portal-region="aktueltRegion"] {
        margin-bottom: 60px;
    }
}

.layout-list-blocks.listblock-margin-top {
    margin-top: -25px !important;
    @media (max-width: 992px) {
        margin-top: -8px !important;
    }
}

.layout-list-blocks.listblock-margin-top-15 {
  margin-top: -15px !important;
  @media (max-width: 992px) {
      margin-top: -8px !important;
  }
}
