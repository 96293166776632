.article-list {
  margin: 80px 0 100px;
  overflow: hidden;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
  &__head {
    display: flex;
    margin-bottom: 40px;
    align-items: bottom;
    width: 100%;
    @media(max-width: 991px) {
      margin-bottom: 24px;
    }
    h2 {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      flex: 1;
      @media(max-width: 991px) {
        @include font-size(24);
        line-height: 1.17;
        letter-spacing: 0.12px;
        margin-left: 0;
        display: block;
      }

      a{
        &:hover{
          color: inherit
        }
      }
    }
    > a {
      @include font-size(16);
      line-height: 1.81;
      letter-spacing: -0.005em;
      font-weight: 600;
      margin-left: auto;
      @media(max-width: 991px) {
        display: none;
      }
      &:hover {
        text-decoration: none;
        color: inherit;
      }
      &:after {
        content: '';
        margin-left: 6px;
        @include background-image("icons/arrow-right-20.svg", 20, 20);
        vertical-align: sub;
      }
    }
  }
  &__wrapper {
    margin-bottom: -30px;
    @media (max-width: 991px) {
      margin: 0 -6px;
      flex-wrap: nowrap;
      max-width: 73.33vw;
    }
    .col-lg-3 {
      @media (max-width: 991px) {
        padding: 0 6px;
        width: 50%;
      }
      @media (max-width: 767px) {
      }
    }
    .slick-list {
      @media (max-width: 991px) {
        overflow: visible;
      }
    }
    .slick-track{
      min-width: 100%;
    }
  }
  &__item {
    margin-bottom: 30px;
    display: block;
    @media (max-width: 991px) {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
    }
    .img {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        margin-bottom: 6px;
      }
    }
    &__title {
      @include font-size(24);
      line-height: 1.42;
      font-weight: 600;
      letter-spacing: -0.005em;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        @include font-size(20);
        line-height: 1.4;
        margin-bottom: 6px;
      }
    }
    &__intro {
      @include font-size(16);
      line-height: 1.62;
      letter-spacing: 0.01em;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      max-height: 4.86;
      color: $gray-800;
      @media (max-width: 767px) {
        -webkit-line-clamp: 4;
      }
    }
  }
}
