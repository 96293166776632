.first-col{
  @media (max-width: 1200px) {
    width: 100dvw;
  }
}
.second-col{
  position: absolute;
  min-height: 100%;
  padding: 0;

  @media (max-width: 768px) {
    min-height: 100%;

    position: relative;
    width: 100dvw;
  }
}
.insulation-hero {
  background-color: #ffdd00;
  margin-bottom: 0 !important;
  overflow-y: hidden;

  &__wrap {
    position: relative;
    min-height: calc(100dvh - 201px);

    @media (max-width: 1200px) {
      min-height: calc(100dvh - 70px);
    }
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100dvh - 201px);
    z-index: 0;


    @media (max-width: 768px) {
      height: calc(100dvh);
    }

    @media (max-width: 768px) {
      position: relative !important;
    }

    .gm-style iframe + div {
      border:none!important;
    }
    & > div:first-child {
      background-color: #fd0 !important;
    }
  }

  &__content {
    position: relative;
    left: 0;
    width: 100%;
    max-width: 626px;
    margin-top: 110px;
    margin-left: 140px;
    margin-bottom: 30px;
    padding: 0;
    z-index: 5;
    background-color: #fd0;
    outline: 15px solid #fd0;

    @media (max-width: 1200px) {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
      max-width: 350px;
    }
  }

  &__title {
    position: relative;
    font-size: 96px;
    margin-bottom: 20px;
    white-space: nowrap;
    z-index: 6;

    @media (max-width: 1200px) {
      font-size: 36px;
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    p{
      font-size: 26px;

      &:not(:last-child){
        margin-bottom: 20px;

        @media (max-width: 1200px) {
          margin-bottom: 12px;
        }
      }

      @media (max-width: 1200px) {
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      a{
        border-color: $textDark1;
        @media (max-width: 1200px) {
          border: none;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  &__buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 68px;

    @media (max-width: 1200px) {
      margin-top: 25px;
      gap: 8px;
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    padding: 0 63px 0;
    height: 83px;
    border-radius: 42px;
    border: none;
    color: #fff;
    background-color: #1F1F21;
    font-size: 28px;

    @media (max-width: 1200px) {
      padding: 8px 24px;
      font-size: 12px;
      font-weight: 400;
      height: auto;
    }

    &:hover {
      color: #fff;
      box-shadow: 0 0 0 2px #878787;
      text-decoration: none;
    }
  }

  &__centralize-map-button {
    position: absolute;
    bottom: 75px;
    right: 10px;
    z-index: 10;
    background-color: #fff;
    padding: 10px;
    border: 2px solid #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: 40px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    line-height: 38px;
    margin: 8px 0 22px;
    padding: 0 5px;
    text-align: center;

    img {
      fill: rgb(25, 25, 25)
    }
  }

  &__text{
    padding-top: 64px;

    @media (max-width: 1200px) {
      padding-top: 40px;
    }

    .articleWrapper__content{
      .contentArticle{
        & > * {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

// a[href^="http://maps.google.com/maps"]{display:none !important}
// a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}

