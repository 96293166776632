footer{
  padding: 80px 0 128px 0;
  background: $glava-black;

  @media (max-width: 1366px){
    padding: 64px 0 108px 0;
  }

  @media (max-width: 992px){
    padding: 42px 0 48px 0;
  }

  @include site(weber){
    background: $weber-anthracite-700;
  }

  @include site(gyproc){
    background: $gyproc-blue;
  }

  .container{
    @media (max-width: 992px){
      // padding: 0 20px;
    }
  }

  .logo__Wrapper{
    &.top{
      display: none;
      @media (max-width: 1366px){
        display: block;
        margin-bottom: 64px;
      }
      @media (max-width: 992px){
        margin-bottom: 40px;
      }
    }
    @media (max-width: 1366px){
      display: none;
    }

    &--logo{
      width: 100%;
      max-width: 114px;

      @media (max-width: 992px){
        max-width: 103px;
      }

      @include site(weber){
        max-width: 164px;

        @media (max-width: 1024px){
          max-width: 131px;
        }

        @media (max-width: 768px) {
          max-width: 108px;
        }
      }

      @include site(gyproc){
        max-width: 164px;

        @media (max-width: 1024px){
          max-width: 131px;
        }

        @media (max-width: 768px) {
          max-width: 108px;
        }
      }
    }
  }
  .content__wrapper{

    .contentRow.address{
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid $gray-800;
      @media (max-width: 1024px){
        margin-top: 48px;
        padding-top: 48px;
      }
      @media (max-width: 992px){
        margin-top: 40px;
        padding-top: 40px;
      }

      @include site(gyproc){
        border-color: $white;
      }
    }

    @media (max-width: 1366px){
      .col-lg-4,
      .col-lg-8,
      .offset-lg-4.col-lg-8{
        width: 100%!important;
        flex: 100%;
        max-width: 100%!important;
        margin-left: 0!important;
      }
    }

    .contentRow{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 30px;
      row-gap: 40px;
      overflow: hidden;
      flex-wrap: wrap;
      @media (max-width: 992px){
        row-gap: 40px;
      }
      p{
        width: calc(25% - 23px);
        font-size: 16px;
        line-height: 100%;
        font-weight: 600;
        color: #ffffff;
        display: block;
        // padding: 0 15px 0 0;
        // &:nth-child(3n + 1){padding-left: 0;}
        // &:nth-child(3n){padding-right: 0;}

        @media (max-width: 992px){
          width: calc(50% - 15px);
        }

        @media (max-width: 992px){
          font-size: 14px;
          // &:nth-child(3n + 1){padding-left: 15px;}
          // &:nth-child(3n){padding-right: 15px;}
          // &:nth-child(odd){padding-left: 0;}
          // &:nth-child(even){padding-right: 0;}
        }
        a{
          font-size: 16px;
          line-height: 20px;
          color: $gray-600;
          font-weight: 450;
          display: inline-block;
          letter-spacing: 0.02em;
          margin-top: 16px;
          @media (max-width: 992px){
            font-size: 14px;
            margin-top: 12px;
          }

          @include site(gyproc){
            color: $white;
          }
        }
        sub,
        sup{
          color: #FFFFFF;
          font-size: 10px;
        }
      }
      &.address{
        @media (max-width: 992px){

        }
        p{
          color: $gray-600;
          font-weight: 450;
          line-height: 20px;
          padding: 0;
          margin-top: 0;

          @include site(gyproc){
            color: $white;
          }

          sub{
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            color: #ffffff;
            display: block;
            @media (max-width: 992px){
              font-size: 14px;
            }
          }
          a{
            color: #ffffff;
            text-decoration-line: underline;
            text-decoration-color: $gray-600;
            text-underline-offset: 3px;
            text-decoration-thickness: 1px;

            @include site(gyproc){
              text-decoration-color: $white;
            }

            &:hover{
              text-decoration-color: #ffffff;
            }
          }
        }
        strong{
          color: #ffffff;
          font-weight: 600;
        }
      }
    }

    &--socialMedia{
      margin-top: 75px;

      h2{
        font-size: 16px;
        line-height: 100%;
        font-weight: 600;
        color: #ffffff;
        display: block;
        @media (max-width: 992px){
          font-size: 12px;
        }
      }

      .linkItem{
        margin-top: 12px;
        a{
          margin-right: 15px;
          color: #FFF5C5;
          border: 1px solid #FFF5C5;
          border-radius: 100%;
          width: 32px;
          height: 32px;
          display: inline-block;
          text-decoration: none;
          margin-top: 10px;
          &:last-child{
            margin-right: 0;
          }
          @media (max-width: 320px){
            margin-right: 5px;
          }
          span{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 500;
            color: #FFF5C5;
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }

      &.mobile{
        height: 100%;
        position: relative;
        margin-top: 0;
        h2{
          display: none;
        }
        .linkItem{
          margin-top: 0;
        }
        .content{
          position: absolute;
          bottom:10px;
          left: 0;
        }
      }

    }
  }
  .hide-mobile{
    @media (max-width: 992px){
      display: none;
    }
  }
  .show-mobile{
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  }

  .social__links{
    .contentRow{
      &.social{
        padding-top: 60px;
        margin-top: 60px;
        border-top: 1px solid $gray-800;
        @media (max-width: 1024px){
          margin-top: 48px;
          padding-top: 48px;
        }
        @media (max-width: 992px){
          margin-top: 40px;
          padding-top: 40px;
        }

        @include site(gyproc){
          border-color: $white;
        }
      }
      p{
        // width: auto;
        a{
          display: block;
        }
      }
    }
  }
}
