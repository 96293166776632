.card-body__content{
    @media (max-width: 991px) {
        padding: 0;
    }

    .deduction-table{
        position: relative;
        word-break: normal!important;

        .deduction-table__header{
            display: table;
            width: 100%;
            min-height: 52px;
            margin-bottom: -1px;
            padding: 11px;
            background-color: #f1f2f6;
            border: 1px solid #B4B6C1;
            font-weight: 600;
            z-index: 0;

            @media (max-width: 767px) {
                min-height: 30px;
                padding: 6px;
            }

            h2{
                font-size: 24px;
                padding: 0;
                text-align: center;
                line-height: 28px;
                letter-spacing: 0.24px;
                opacity: 1;

                @media (max-width: 767px) {
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.24px;
                }
            }
        }

        .table-wrapper{
            .table-scroller{
                table{
                    white-space: nowrap;
                    width: 100%;
                    border: 1px solid #B4B6C1;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0.24px;
                    text-align: center;
                    overflow: hidden;

                    @media (max-width: 767px) {
                        font-size: 9px;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }

                    tr.header-row{
                        background-color: #F1F2F6;
                        border: 1px solid #B4B6C1;

                        &.last{
                            td{
                                // border-bottom: 3px solid #333333;

                                @media (max-width: 767px) {
                                    border-bottom: inherit;
                                }

                                &.sticky-th{
                                    border-bottom: inherit;

                                    &::after{
                                        content: '';
                                        color: inherit;
                                        border-bottom: 3px solid #333333;

                                        @media (max-width: 767px) {
                                            border-bottom: inherit;
                                        }
                                    }
                                }
                            }
                        }

                        td{
                            padding: 10px;
                            background-color: #F1F2F6;
                            border: 1px solid #B4B6C1;

                            @media (max-width: 767px) {
                                padding: 6px;
                            }

                            &.header-cell{
                                font-weight: 600;

                                p{
                                    font-weight: 600!important;
                                    font-size: 15px;
                                    line-height: 24px;
                                    letter-spacing: 0.24px;

                                    @media (max-width: 767px) {
                                        font-size: 9px;
                                        line-height: 16px;
                                        letter-spacing: 0.24px;
                                    }

                                    br{
                                        display: none;
                                    }
                                }
                            }

                            &.sticky-th{
                                position: sticky;
                                top: 0;
                                z-index: 2;

                                &::after{
                                    content: '';
                                    position: absolute;
                                    border: 1px solid #B4B6C1;
                                    width: calc(100% + 2px);
                                    height: calc(100% + 2px);
                                    left: -1px;
                                    top: -1px;
                                    pointer-events: none;
                                }

                                &.empty-sticky{
                                    border-bottom: inherit;
                                    &::after{
                                        // border-bottom: 3px solid #333333;

                                        @media (max-width: 767px) {
                                            border-bottom: inherit;
                                        }
                                    }
                                }
                            }

                            &.empty-sticky{
                                // border-bottom: 3px solid #333333;

                                @media (max-width: 767px) {
                                    border-bottom: inherit;
                                }

                                &::after{
                                    content: '';
                                    color: inherit;

                                    @media (max-width: 767px) {
                                        border-bottom: inherit;
                                    }
                                }
                            }
                        }
                    }

                    tbody{
                        border: 1px solid #B4B6C1;

                        tr.tbody-row{
                            background-color: #FFFFFF;
                            border: 1px solid #B4B6C1;
                            cursor: pointer;

                            @media (min-width: 992px) {
                                &:hover{
                                    background-color: #f5f7fb;

                                    .fixed-cell{
                                        background-color: #f5f7fb;
                                    }
                                }
                            }

                            &:focus{
                                outline: none;
                            }

                            &.selected{
                                background-color: #F1F2F6;

                                .fixed-cell{
                                    background-color: #F1F2F6;
                                }
                            }
                            &-first {
                                border-top: 3px solid #333333;
                            }

                            td{
                                padding: 10px;
                                border: 1px solid #B4B6C1;
                                white-space: normal;

                                @media (max-width: 767px) {
                                    padding: 6px;
                                }

                                p{
                                    font-size: 15px;
                                    line-height: 24px;
                                    letter-spacing: 0.24px;

                                    @media (max-width: 767px) {
                                        font-size: 9px;
                                        line-height: 16px;
                                        letter-spacing: 0.24px;
                                    }
                                }

                                &.fixed-cell{
                                    width: 202px;
                                    min-width: 202px;
                                    max-width: 202px;
                                    white-space: normal;
                                    font-weight: 600;

                                    p{
                                        font-weight: 600!important;
                                        font-size: 15px;
                                        line-height: 24px;
                                        letter-spacing: 0.24px;

                                        @media (max-width: 767px) {
                                            font-size: 9px;
                                            line-height: 16px;
                                            letter-spacing: 0.24px;
                                        }
                                    }

                                    @media (max-width: 767px) {
                                        width: 102px;
                                        min-width: 102px;
                                        max-width: 102px;
                                    }
                                }

                                &.relative-cell{
                                    width: 200px;
                                    min-width: 200px;
                                    max-width: 200px;
                                    font-weight: 600;
                                    white-space: normal;

                                    p{
                                        font-weight: 600!important;
                                        font-size: 15px;
                                        line-height: 24px;
                                        letter-spacing: 0.24px;

                                        @media (max-width: 767px) {
                                            font-size: 9px;
                                            line-height: 16px;
                                            letter-spacing: 0.24px;
                                        }
                                    }

                                    @media (max-width: 767px) {
                                        width: 100px;
                                        min-width: 100px;
                                        max-width: 100px;
                                    }
                                }
                            }
                        }
                    }

                    span.empty{
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #F1F2F6;
                        border: 1px solid #B4B6C1;
                        border-bottom: 3px solid #333333;
                        width: 50px;
                        height: 50px;
                        z-index: 2;

                        @media (max-width: 767px) {
                            border-bottom: inherit;
                        }
                    }
                }
            }
        }

        .deduction-table__footer{
            background: none;
            background-color: #f1f2f6;
            padding: 32px;
            @media (max-width: 767px) {
                padding: 16px;
            }
            &:empty {
                display: none;
            }
            .footer-text{
                margin-bottom: 0;
                color: #2A2A2D;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.24px;
                font-style: italic;
                opacity: 1;
                p {
                    color: #2A2A2D;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0.24px;
                    font-style: italic;
                    padding: 0;
                }
            }
        }

        &--x-scroll{
            .table-wrapper{
                .table-scroller{
                    overflow-x: auto;

                    table{
                        margin-right: 30px;
                        display: inline-table;

                        @media (max-width: 767px) {
                            margin-right: 20px;
                        }

                        tbody{
                            tr{
                                td.fixed-cell{
                                    position: absolute;
                                    top: auto;
                                    left: 0px;
                                    background-color: #ffffff;
                                    border: none;
                                    border-right: 1px solid #B4B6C1;
                                    border-left: 1px solid #B4B6C1;
                                    padding: 10px 9.5px;

                                    @media (max-width: 767px) {
                                        padding: 6px 5.5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Disable table scroll when the user checks the "Disable Scroll" option in content studio (desktop only)
        &.force-no-scroll{
            .table-wrapper{
                .table-scroller{
                    @media (min-width: 992px) {
                        width: 100% !important;
                    }

                    table{
                        @media (min-width: 992px) {
                            margin-right: 0;
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }
}

.table-glava {
    table {
        caption-side: bottom;
        text-align: left;
    }
}

.deduction_table_acc + .deduction_table_acc {
    margin-top: 40px;
}
// Custom scrollbar (Chrome and Safari)
.deduction-table .table-scroller{
    /* width */
    &::-webkit-scrollbar {
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #DDDDDD;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #cacaca;
    }
}
