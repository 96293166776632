.job-position {
  .breadcrumbs {
    margin-bottom: 84px;
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
  }
  &__header {
    margin-bottom: 56px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
    h1 {
      @include font-size(52);
      line-height: 1.15;
      margin: 0;
      @media (max-width: 991px) {
        @include font-size(28);
        line-height: 1.14;
        letter-spacing: 0.12px;
      }
    }
    .ingress {
      @include font-size(25);
      line-height: 1.76;
      margin-top: 24px;
      @media (max-width: 991px) {
        @include font-size(20);
        line-height: 1.5;
        margin-top: 20px;
      }
    }
    &__info {
      color: #8E8E8E;
      @include font-size(14);
      line-height: 1.71;
      letter-spacing: 0.005em;
      margin-top: 24px;
      @media (max-width: 991px) {
        margin-top: 12px;
      }
    }
  }
  &__img {
    margin-bottom: 60px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    margin-bottom: 80px;
    @media (max-width: 991px) {
      margin-bottom: 38px;
    }
    .styled-text {
      max-width: 88.23%;
      @media (max-width: 1199px) {
        max-width: 100%;
      }
    }
  }
  &__side {
    max-width: 466px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    .job-position__box:not(:first-child) {
      margin-bottom: 0;
      margin-top: 20px;
      @media (max-width: 991px) {
        margin-top: 32px;
        text-align: center;
      }
    }
  }
  &__box {
    @include font-size(18);
    line-height: 1.56;
    padding: 9.4% 10.3% 10.3%;
    background: #ECE3DC;
    @media (max-width: 991px) {
      padding: 22px;
      margin-bottom: 24px;
    }
    a {
      color: #387197;
      text-decoration: underline;
      text-underline-position: under;
      font-weight: 600;
    }
    &__info {
      display: flex;
      word-break: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      @media (max-width: 991px) {
      }
      > * {
        display: block;
        flex: 1;
        &:not(:last-child) {
          padding-right: 6px;
        }
      }
      + .job-position__box__info {
        margin-top: 12px;
        @media (max-width: 991px) {
          margin-top: 8px;
        }
      }
      strong{
        font-weight: 600;
      }
    }
    &--blue {
      background: #E7F3FA;
      @media (min-width: 992px) {
        @include font-size(20);
        line-height: 1.56;
      }
    }
  }
  &__contact {
    padding: 80px 0;
    background: #EBF2F6;
    margin: 80px 0;
    background: #EBF2F6;
    @media (max-width: 991px) {
      padding: 45px 0 104px;
      margin: 38px 0 58px;
    }
    h2 {
      @include font-size(24);
      line-height: 1.334;
      margin-bottom: 40px;
      @media (max-width: 991px) {
        @include font-size(22);
        line-height: 1.273;
        margin-bottom: 32px;
      }
    }
  }
}
