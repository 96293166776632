.career-page {
  &__header {
    margin-bottom: 80px;
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
    h1 {
      @include font-size(52);
      line-height: 1.15;
      margin-bottom: 0px;
      @media (max-width: 991px) {
        @include font-size(32);
        line-height: 1;
      }
    }
    .ingress {
      @include font-size(25);
      line-height: 1.76;
      margin: 24px 0 0px;
      @media (max-width: 991px) {
        @include font-size(17);
        line-height: 1.65;
        margin-top: 20px;
      }
    }
    .breadcrumbs {
      margin-bottom: 84px;
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }
    }
  }
  &__content {
    margin: 80px 0;
    @media (max-width: 991px) {
      margin: 40px 0;
    }
    .select-jobs-link-list {
      margin-bottom: 48px;
      @media (max-width: 991px) {
        margin-bottom: 24px;
      }
    }
  }
}
