.card-horizontal {
  display: flex;
  background-color: #fff;
  min-height: 228px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @media (max-width: 991px) {
    min-height: 160px;
    height: auto;
    margin-bottom: 16px;
  }

  &__img {
    min-height: 100%;
    width: 50%;
    padding-right: 30px;
    flex: 1;
    @media (max-width: 991px) {
      padding-right: 8px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    @include font-size(15);
    line-height: 1.467;
    letter-spacing: 0.005em;
    padding: 28px;
    padding-left: 15px;
    min-height: 100%;
    width: 50%;
    max-width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      @include font-size(14);
      line-height: 1.25rem;
      padding: 16px 8px;
    }
  }
  &__title {
    @include font-size(20);
    line-height: 1.4;
    letter-spacing: unset;
    font-weight: 600;
    margin-bottom: 4px;
    @media (max-width: 991px) {
      @include font-size(17);
      line-height: 1.334;
      margin-bottom: 2px;
    }
  }
  &__intro {
    color: #8C8C8C;
    margin-bottom: auto;
    @media (max-width: 991px) {
      @include font-size(13);
    }
  }
  &__info {
    font-weight: 600;
    a {
      color: #4B93C1;
      text-decoration: underline;
      display: block;
      margin-top: 4px;
      &[href^="mailto:"], &[href^="tel:"] {
        color: inherit;
      }
      &[href^="mailto:"] {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  &--thin {
    @media (max-width: 991px) {
      height: 216px;
      min-height: calc(100% - 40px);
      margin-bottom: 40px;
    }
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    @media (max-width: 575px) {
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
    }
    .card-horizontal {
      &__img {
        @media (max-width: 991px) {
          padding-right: 25px;
        }
      }
      &__content {
        padding-top: 26px;
        padding-bottom: 26px;
        padding-left: 13px;
        @media (max-width: 991px) {
          line-height: 1.375rem;
          padding: 24px 20px 32px 0;
          min-width: calc(50% + 5px);
        }
      }
      &__title {
        @media (max-width: 991px) {
          @include font-size(18);
          line-height: 1.334;
          margin-bottom: 4px;
        }
      }
      &__intro {
        @media (max-width: 991px) {
          @include font-size(15);
          line-height: 1.467;
        }
      }
    }
  }
  &--mob-v {
    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 20px;
      .card-horizontal {
        &__img {
          height: 168px;
          width: 100%;
          padding: 0;
          position: relative;
          padding-top: 55%;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &__content {
          width: 100%;
          max-width: 100%;
          padding: 20px 24px 28px;
        }
        &__title {
          @include font-size(18);
          line-height: 1.556;
          margin-bottom: 2px;
        }
        &__intro {
          margin-bottom: 12px;
        }
        &__info {
          line-height: 1.429;
        }
      }
    }
  }
}
