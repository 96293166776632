@import url("https://use.typekit.net/swg4rub.css");

// @mixin font-face($name, $file) {
//   @font-face {
//     font-family: "#{$name}";
//     src: url("../fonts/#{$file}.eot");
//     src: url("../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
//       url("../fonts/#{$file}.woff") format("woff"),
//       url("../fonts/#{$file}.ttf") format("truetype");
//       url("../fonts/#{$file}.svg?#webfont") format("svg");
//   }
// }
// @include font-face("Gordita-Regular", "Buypass-Gordita-Regular");
// @include font-face("Gordita-Medium", "Buypass-Gordita-Medium");

$font-regular: "neue-haas-grotesk-display", sans-serif;
$font-medium: "neue-haas-grotesk-text", sans-serif;

// Light 400
// Roman 500
// Medium 600
// Bold 700
// Black 900

html,
body {
  font-size: 16px;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $font-regular;
  letter-spacing: 0.24px;
  font-weight: 500;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-regular;
  font-weight: 600;
}
