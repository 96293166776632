.content-text {
  color: $textDark1;
  @include font-size(20);
  line-height: 1.5;
  @media (max-width: 991px) {
    @include font-size(16);
    line-height: 1.53;
  }
  > * {
    &:first-child {
      margin-top: 0!important;
      > figure {
        margin-top: 0!important;
      }
    }
    &:last-child {
      margin-bottom: 0!important;
      > figure {
        margin-bottom: 0!important;
      }
    }
  }
  h2 {
    @include font-size(24);
    line-height: 1.2;
    letter-spacing: -0.0025em;
    margin-bottom: 24px;
    margin-top: 40px;
    @media (max-width: 991px) {
      font-size: rem(20);
    }
  }
  h3, h4, h5, .h3, .h4, .h5 {
    @include font-size(20);
    line-height: 1.5;
    margin-top: 32px;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      @include font-size(16);
      line-height: 1.53;
    }
  }
  p {
    margin-bottom: 24px;
  }
  u {
    text-decoration-line: underline;
  }
  i {
    font-style: italic;
  }
  b, strong {
    font-weight: bolder;
  }
  sup {
    vertical-align: baseline;
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  tt {
    font-family: monospace;
  }
  a {
    color: $turquoise;
    text-decoration: underline;
  }
  ul, ol {
    margin-bottom: 24px;
    li {
      p{
        margin-bottom: 8px;
      }
      >:last-child {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }
  ul {
      list-style: none;
      padding-left: 12px;
      li {
          padding-left: 18px;
          position: relative;
          @media (max-width: 991px) {
              padding-left: 20px;
          }
          &:before {
              content: '';
              position: absolute;
              border-radius: 4px;
              height: 5px;
              width: 5px;
              margin-top: -2.5px; //0.5 height
              background-color: $textDark1;
              vertical-align: middle;
              left: 0;
              top: 0.75em;
          }
      }
  }
  ol {
    list-style-type: decimal;
    padding-left: 30px;
  }
  table {
    margin-bottom: 24px;
    display: block;
    max-width: 100%;
    overflow: auto;
    @media (max-width: 991px) {
      margin-bottom: 40px;
    }
    td, th {
      padding: 5px 10px;
      border-bottom: 1px solid $textDark1;
    }
    th {
      font-weight: bolder;
    }
  }
  figure {
    margin-top: 60px;
    margin-bottom: 60px;
    @media (max-width: 991px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    &.editor-align-right,  &.editor-align-left {
        margin-top: 0;
    }
    img {
        margin-top: 0;
        margin-bottom: 0;
    }
    blockquote {
      font-size: rem(24);
      line-height: 1.4;
      position: relative;
      @media (max-width: 991px) {
        font-size: rem(20);
      }
      &:before, &:after {
        content: '"';
        display: inline;
      }
    }
  }
  figcaption, .figcaption {
    color: #5D5D5F;
    @include font-size(14);
    line-height: 1.4;
    margin-top: 16px;
    letter-spacing: 0.02em;
    * {
        margin: 0;
    }
    .cite, cite {
      font-style: normal;
      color: #ACACAD;
      display: block;
      margin-top: 8px;
      @media (max-width: 991px) {
        margin-top: 2px;
      }
    }
  }
  img {
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 100%;
    @media (max-width: 991px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &-section {
    margin: 124px 0;
    @media (max-width: 991px) {
      margin: 56px 0;
    }
  }
  &-empty {
    margin: 0
  }
}
.content-text-carousel {
  overflow-x: hidden;
  margin: 80px 0;
  @media (max-width: 991px) {
    margin: 48px 0;
  }
  &__slick {
    margin: 0 calc(-16.7% - 20px);
    @media (max-width: 991px) {
      margin: 0 -20px;
    }
    .img {
      img {
        margin: 0!important;
        object-fit: contain;
      }
    }
    .slick-slide{
      padding: 0 15px;
      @media (max-width: 991px) {
        padding-left : 0;
      }
    }
    .slick-arrow {
      width: 32px;
      height: 32px;
      border-radius: 30px;
      @media (max-width: 991px) {
        // width: 60px;
        // height: 60px;
        // background-color: #fff;
        display: none;
      }
      &:before {
        @include background-image ("icons/arrow-right-squared-32.svg", 32, 32);
        opacity: 1;
        // @media (max-width: 767px) {
        //   @include background-image ("icons/arrow-right-24.svg", 24, 24);
        // }
      }
      &.slick-disabled {
        opacity: 0;
      }
      &.slick-prev {
        left: -65px;
        z-index: 10;
        @media (max-width: 991px) {
          // left: -32px;
        }
        // @media (max-width: 800px) {
        //   right: -24px;
        // }
        // @media (max-width: 767px) {
        //   left: -10px;
        // }
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next {
        right: -65px;
        @media (max-width: 991px) {
          // right: -32px;
        }
        // @media (max-width: 800px) {
        //   right: -24px;
        // }
        // @media (max-width: 767px) {
        //   right: -10px;
        // }
      }
    }
    .slick-list{
      @media (max-width: 991px) {
        overflow: visible;
      }
    }
  }
  figcaption, .figcaption {
    color: $gray-800;
    @include font-size(14);
    line-height: 1.4;
    margin: 16px calc(12.5% + 5px) 0;
    letter-spacing: 0.02em;
    @media (max-width: 991px){
      margin: 16px 0 0;
    }
    * {
        margin: 0;
    }
    .cite, cite {
      font-style: normal;
      color: $gray-600;
      display: block;
      margin-top: 8px;
      @media (max-width: 991px) {
        margin-top: 2px;
      }
    }
  }
}
