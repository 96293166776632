.hero-flexible{
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  padding: 80px 0;

  @media (max-width: $mobileWidth) {
    margin-bottom: 52px;
  }

  &__figure{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-100;

    .videoWrapper{
      display: none;
      max-height: 100vh;
    }
  }

  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--desktop{
      display: block;
      @media (max-width: $mobileWidth) {
        display: none;

        &:last-child{
          display: block;
        }
      }
    }

    &--mobile{
      display: none;
      @media (max-width: $mobileWidth) {
        display: block;
      }
    }
  }

  &__play{
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    @extend .col-lg-10;
    @extend .offset-lg-1;

    @media (max-width: $mobileWidth) {
      margin-bottom: 40px;
    }

    .play-button{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 132px;
      background-color: transparentize(#000000, 0.5);
      border-radius: 100%;

      @media (max-width: $mobileWidth) {
        width: 88px;
        height: 88px;
      }

      &::after{
        margin-left: 16px;
        border-width: 28px 0 28px 48px;

        @media (max-width: $mobileWidth) {
          margin-left: 10px;
          border-width: 18px 0 18px 30px;
        }
      }
    }
  }

  &__title{
    @extend .col-lg-10;
    @extend .offset-lg-1;

    h1{
      font-size: 112px;
      line-height: 1;
      color: inherit;

      @media (max-width: $mobileWidth) {
        font-size: 52px;
        line-height: 1.1;
      }
    }
  }

  &__intro{
    margin-top: 32px;
    @extend .col-lg-6;
    @extend .offset-lg-3;

    @media (max-width: $mobileWidth) {
      margin-top: 24px;
    }

    p{
      font-size: 24px;
      line-height: 1.4;

      @media (max-width: $mobileWidth) {
        font-size: 16px;
      }
    }
  }

  &__buttons{
    @extend .col-lg-10;
    @extend .offset-lg-1;
    @extend .col-sm-8;
    @extend .offset-sm-2;
    @extend .col-xs-10;
    @extend .offset-xs-1;
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    @media (max-width: $mobileWidth) {
      margin-top: 40px;
      flex-direction: column;
      gap: 12px;

      .button{
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__scroll-btn{
    position: absolute;
    bottom: 54px;
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;

    @media (max-width: $mobileWidth) {
      bottom: 20px;
      width: 48px;
      height: 48px;
      left: calc(50% - 24px);
    }

    button , a{
      border: none;
      background-color: transparent;
      @include background-image("icons/arrow-narrow-down-32.svg", 60, 60);

      @media (max-width: $mobileWidth) {
        @include background-image("icons/arrow-narrow-down-24.svg", 48, 48);
      }
    }

    // Only shown in the "large" variation
    .hero-flexible--small &, .hero-flexible--medium &{
      display: none;
    }
  }

  &--center{
    text-align: center;
  }

  &--left{
    text-align: left;

    @media (max-width: $mobileWidth) {
      text-align: center;
    }

    .hero-flexible__play , .hero-flexible__title, .hero-flexible__buttons{
      @extend .col-lg-7;
      @extend .offset-lg-1;
    }

    .hero-flexible__intro{
      @extend .col-lg-6;
      @extend .offset-lg-1;
    }

    .hero-flexible__play , .hero-flexible__buttons{
      justify-content: flex-start;

      @media (max-width: $mobileWidth) {
        justify-content: center;
      }
    }

    &.video-poster{
      &.hero-flexible--small{
        .hero-flexible__play{
          @extend .col-lg-2;
          @extend .offset-lg-0;
        }

        .hero-flexible__title {
          @extend .offset-lg-0;
        }

        .hero-flexible__intro, .hero-flexible__buttons {
          @extend .offset-lg-2;
        }
      }
    }
  }

  &--right{
    text-align: right;

    @media (max-width: $mobileWidth) {
      text-align: center;
    }

    .hero-flexible__play , .hero-flexible__title, .hero-flexible__buttons{
      @extend .col-lg-7;
      @extend .offset-lg-4;
    }

    .hero-flexible__intro{
      @extend .col-lg-6;
      @extend .offset-lg-5;
    }

    .hero-flexible__play , .hero-flexible__buttons{
      justify-content: flex-end;

      @media (max-width: $mobileWidth) {
        justify-content: center;
      }
    }

    &.video-poster{
      &.hero-flexible--small{
        .hero-flexible__play{
          order: 2;
          @extend .col-lg-2;
          @extend .offset-lg-0;
        }

        .hero-flexible__title{
          order: 1;
          @extend .offset-lg-3;
        }

        .hero-flexible__intro{
          order: 3;
          @extend .offset-lg-4;
        }

        .hero-flexible__buttons{
          order: 4;
          @extend .offset-lg-3;
        }
      }
    }
  }

  // Styles that left and right variations have in common
  &--left, &--right{
    .hero-flexible__intro{
      @media (min-width: $mobileWidth) {
        margin-top: 28px;
      }
    }

    // Left or right + Small
    &.hero-flexible--small{
      .hero-flexible__intro{
        @media (min-width: $mobileWidth) {
          margin-top: 24px;
        }
      }

      .hero-flexible__buttons{
        @media (min-width: $mobileWidth) {
          margin-top: 32px;
        }
      }

      // Left or Right + Small + Video
      &.video-poster{
        .hero-flexible__play{
          @media (min-width: $mobileWidth) {
            margin-bottom: 0;
            height: 1px;
          }
        }

        .hero-flexible__buttons{
          @media (min-width: $mobileWidth) {
            margin-top: 32px;
          }
        }
      }
    }
  }

  &--medium{
    min-height: 680px;

    @media (max-width: $mobileWidth) {
      min-height: 524px;
    }

    .hero-flexible__title{
      h1{
        font-size: 100px;

        @media (max-width: $mobileWidth) {
          font-size: 40px;
        }
      }
    }

    .hero-flexible__intro{
      margin-top: 28px;

      @media (max-width: $mobileWidth) {
        margin-top: 20px;
      }
    }

    .hero-flexible__buttons{
      margin-top: 40px;

      @media (max-width: $mobileWidth) {
        margin-top: 32px;
      }
    }
  }

  // Small variation
  &--small{
    min-height: 572px;

    @media (max-width: $mobileWidth) {
      min-height: 524px;
    }

    .hero-flexible__title{
      h1{
        font-size: 80px;

        @media (max-width: $mobileWidth) {
          font-size: 40px;
        }
      }
    }

    .hero-flexible__intro{
      margin-top: 28px;

      @media (max-width: $mobileWidth) {
        margin-top: 20px;
      }
    }

    .hero-flexible__buttons{
      margin-top: 40px;

      @media (max-width: $mobileWidth) {
        margin-top: 32px;
      }
    }
  }

  // Video variation
  &.video-poster{
    .hero-flexible__buttons{
      margin-top: 40px;
    }

    &.hero-flexible--large {
      .hero-flexible__title{
        h1{
          font-size: 100px;

          @media (max-width: $mobileWidth) {
            font-size: 52px;
          }
        }
      }
    }

    &.hero-flexible--medium {
      .hero-flexible__play{
        @media (max-width: $mobileWidth) {
          margin-bottom: 32px;
        }
      }

      .hero-flexible__title{
        h1{
          font-size: 80px;

          @media (max-width: $mobileWidth) {
            font-size: 40px;
          }
        }
      }

      .hero-flexible__buttons{
        @media (max-width: $mobileWidth) {
          margin-top: 32px;
        }
      }
    }

    &.hero-flexible--small {
      .hero-flexible__play{
        @media (max-width: $mobileWidth) {
          margin-bottom: 32px;
        }
      }

      .hero-flexible__title{
        h1{
          font-size: 64px;

          @media (max-width: $mobileWidth) {
            font-size: 40px;
          }
        }
      }

      .hero-flexible__buttons{
        @media (max-width: $mobileWidth) {
          margin-top: 32px;
        }
      }
    }

    .stop-video{
      display: none;
      width: 60px;
      height: 60px;
      background-color: $glava-yellow-600;
      position: absolute;
      top: 30px;
      right: 30px;
      border-radius: 100%;
      border: none;

      @include site(weber){
        background-color: $weber-yellow;
      }

      @include site(gyproc){
        background-color: $gyproc-blue;
      }

      &::after{
        content: "";
        @include background-image("./icons/close-pop-up.svg", 20, 25);
        background-position: bottom center;
        background-size: contain;

        @include site(gyproc){
          @include background-image("./icons/close-white-20.svg", 30, 36);
          background-position: bottom center;
          background-size: contain;
        }
      }
    }

    &--active{
      .videoWrapper{
        display: block;
      }

      img{
        display: none;
      }

      .hero-flexible__container{
        display: none;
      }

      .stop-video{
        display: block;
      }
    }
  }

  // White text variation
  &--dark{
    color: $white;

    .hero-flexible__scroll-btn{
      button , a{
        @include background-image("icons/arrow-narrow-down-32-w.svg", 60, 60);

        @media (max-width: $mobileWidth) {
          @include background-image("icons/arrow-narrow-down-24-w.svg", 48, 48);
        }
      }
    }

    .hero-flexible__figure{
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.2;
      }
    }
  }

  &--intro{
    .hero-flexible__title{
      h1{
        font-size: 100px;

        @media (max-width: $mobileWidth) {
          font-size: 44px;
        }
      }
    }

    .hero-flexible__buttons{
      margin-top: 40px;

      @media (max-width: $mobileWidth) {
        margin-top: 32px;
      }
    }

    &.hero-flexible--medium{
      .hero-flexible__title{
        h1{
          font-size: 80px;

          @media (max-width: $mobileWidth) {
            font-size: 36px;
          }
        }
      }

      .hero-flexible__buttons{
        @media (max-width: $mobileWidth) {
          margin-top: 28px;
        }
      }
    }

    &.hero-flexible--small{
      .hero-flexible__title{
        h1{
          font-size: 64px;

          @media (max-width: $mobileWidth) {
            font-size: 36px;
          }
        }
      }

      .hero-flexible__buttons{
        @media (max-width: $mobileWidth) {
          margin-top: 28px;
        }
      }
    }
  }
}
