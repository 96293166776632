.filterSoluctionWrapper{
  width: 100%;
  height: 68px;
  background: #181818;
  z-index: 14;
  position: fixed;
  bottom: 0;
  transition: all 400ms;
  @media (max-width: 768px) {
    height: 45px;
    }
  &.show{
      bottom: -68px;
      @media (max-width: 768px) {
        bottom: -45px;
      }
  }
  &__btn{
      height: 100%;
      width: 100%;
      text-decoration: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      &:hover{
          text-decoration: none;
      }
      span{
          font-weight: 600;
          font-size: 20px;
          line-height: 180%;
          text-align: center;
          letter-spacing: 0.24px;
          color: #FFFFFF;
          opacity: 0.9;
          @media (max-width: 768px) {
            font-size: 16px;
          }
      }
  }
}

.productFilterOverlaySoluctions{
    display: block;
    background: #2A2A2D;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 13;
    top: 100vh;
    transition: all 400ms;
    &.show{
        top: 0;
    }

  &::-webkit-scrollbar-track{
      border-radius: 10px;
      background-color: #2A2A2D;
  }

  &::-webkit-scrollbar {
      width: 7px;
      background-color: #2A2A2D;
  }

  &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #8B8B8B;
  }

  &__wrapper{
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      @media (max-width: 992px) {
          height: 100%;
      }

      &--content{
          width: 100%;
          height: 100vh;
          background: #2A2A2D;
          position: relative;
          @media (max-width: 992px) {
              padding-bottom: 116px;
              height: 100%;
              position: fixed;
              overflow-y: auto;
              overflow-x: hidden;
          }
          .btnCloseWrapper{
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 15px;
              margin-bottom: 58px;
              position: relative;
              @media (max-width: 992px) {
                  margin-bottom: 25px;
                  margin-top: 33px;
              }
              a{
                  font-weight: 600;
                  @include font-size(16);
                  line-height: 24px;
                  text-align: center;
                  letter-spacing: 0.24px;
                  color: #FFFFFF;
                  text-decoration: none;
                  display: inline-block;
                  position: relative;
                  padding-right: 21px;
                  @media (max-width: 992px) {
                      @include font-size(13);
                      line-height: 16px;
                  }
                  &:after{
                      @include background-image("icons/lukk.svg", 11, 11);
                      position: absolute;
                      right: 0;
                      top: 50%;
                      margin-top: -5.5px;
                  }
              }

          }
          .item{
              @media (max-width: 992px) {
                  margin-top:10px;
              }
              .title{
                  font-weight: 600;
                  @include font-size(18);
                  line-height: 24px;
                  letter-spacing: 0.24px;
                  color: #FFFFFF;
                  padding-bottom: 24px;
                  border-bottom: 1px solid rgba(255,255,255,.2);
                  position: relative;
                  pointer-events: none;
                  @media (max-width: 992px) {
                      @include font-size(14);
                      pointer-events: initial;
                      line-height: 20px;
                      padding-bottom: 12px;
                      cursor: pointer;

                      &::after{
                          @include background-image("icons/arrow-down-menu-light.svg", 12, 6);
                          position: absolute;
                          right: 0;
                          top: 50%;
                          margin-top: -3px;
                      }

                      &.active{
                          &:after{
                              transform: rotate(180deg);
                          }
                      }
                  }
              }
              .subItemsWrapper{
                  margin-top: 32px;
                  @media (max-width: 992px) {
                      margin-top: 25px;
                    //   display: none;
                  }
                  @media (min-width: 992px) {
                      display: block!important;
                  }
                  .subItem{
                      display: flex;
                      height: auto;
                      min-height: 28px;
                      margin-bottom: 32px;
                      align-items: center;
                      @media (max-width: 992px) {
                          min-height: 20px;
                          margin-bottom: 20px;
                      }
                      &:last-child{
                          margin-bottom: 32px;
                      }

                      input[type="checkbox"]{
                          display: none;
                      }

                      label{
                          font-size: 20px;
                          line-height: 130%;
                          letter-spacing: 0.24px;
                          color: #8B8B8B;
                          padding-left: 52px;
                          cursor: pointer;
                          display: block;
                          position: relative;
                          @media (max-width: 992px) {
                              @include font-size(15);
                              padding-left: 29px;
                          }
                      }

                      input[type="checkbox"]:checked + label::after {
                          content: url("../images/icons/check.svg");
                          opacity: 1;
                          left: 7px;
                          top: 1px;
                          @media (max-width: 992px) {
                              left: 2px;
                              transform: scale(.7);
                          }
                      }

                      input[type="checkbox"]:checked + label::before {
                          opacity: 1;
                      }

                      input[type="checkbox"]:checked + label {
                          color: #ffffff;
                      }

                      label::before{
                          content: "";
                          border: 1px solid #FFFFFF;
                          display: inline-block;
                          box-sizing: border-box;
                          opacity: 0.3;
                      }

                      label::before {
                          position: absolute;
                          width: 28px;
                          height: 28px;
                          left: 0;
                          top: 0;
                          @media (max-width: 992px) {
                              width: 20px;
                              height: 20px;
                          }
                      }

                      label::after {
                          position: absolute;
                          width: 28px;
                          height: 28px;
                          left: 0;
                          top: 0;
                          @media (max-width: 992px) {
                              width: 20px;
                              height: 20px;
                          }
                      }

                  }
              }
          }
          .ctaWrapper{
              background: #2A2A2D;
              text-align: right;
              padding-top: 41px;
              padding-bottom: 64px;
              width: 100%;
              @media (max-width: 992px) {
                  display: none;
              }
              .cta{
                  padding: 12px 40px;
                  font-weight: 600;
                  @include font-size(15);
                  line-height: 24px;
                  letter-spacing: 0.16px;
                  color: #FFFFFF;
                  text-decoration: none;
                  border: 1px solid #A2A2A2;
                  border-radius: 24px;
                  background: transparent;
                  margin-right: 16px;
                  display: inline-block;
                  @media (max-width: 992px) {
                      @include font-size(13);
                      padding: 8px 28px;
                  }
                  &:last-child{
                      margin-right: 0;
                  }
                  &.yellow{
                      color: #333333;
                      background: #FFE66F;
                      border: 1px solid #FFE66F;
                  }
              }

            &.mobile{
                display: none;
                @media (max-width: 992px) {
                    height: 116px;
                    padding: 0;
                    position: fixed;
                    bottom: -116px;
                    left: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 400ms;
                    &.show{
                        bottom: 0;
                    }
                }

            }

          }
      }

  }

  .overlayPage{
      width: 100%;
      height: 100vh;
      background: rgba($color: #2A2A2D, $alpha: .5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
  }

}
