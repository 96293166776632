.banner-global {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  &__bg {
    padding: 0px 20px;
    background: $glava-black;

    @include site(weber){
      background: $weber-black;
    }

    @include site(gyproc){
      background: $gyproc-black;
    }
  }
  &__min {
    max-width: 1440px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;
    height: 44px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      height: 48px;
    }

    .btn {
      margin-left: auto;
      margin-right: auto;
      border: 0;
      text-align: center;
      span {
        color: #fff;
        @include font-size(14);
        line-height: 1.43;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (max-width: 991px) {
          @include font-size(12);
          line-height: 1.33;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  &__close {
    color: transparent!important;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 50%;
    overflow: hidden;
    @include background-image("icons/close-white-20.svg",20,20);
  }
  &__content {
    display: none;
    padding: 16px 0 60px;
    max-width: 454px;
    margin: auto;
    text-align: left;
    @media (max-width: 991px) {
      margin-top: -20px;
      padding: 0px 40px 32px 12px;
    }
    .button {
      @include font-size(14);
      line-height: 1.43;
      font-weight: 600;
      background-color: #fff;
      border-radius: 20px;
      border: 0;
      padding: 8px 20px;
      display: inline-block;
      color: $glava-black;

      @include site(weber){
        color: $weber-black;
      }

      @include site(gyproc){
        color: $gyproc-black;
      }
    }
  }
  &__title {
    color: #fff;
    @include font-size(22);
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: 0.0025em;
    margin-bottom: 12px;
    @media (max-width: 991px) {
      @include font-size(18);
    }
  }
  &__intro {
    color: $gray-200;
    @include font-size(16);
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      @include font-size(14);
    }
  }
  &__overlay {
    display: none;
    background: #18191F;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &--open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    .banner-global__content, .banner-global__overlay {
      display: block;
      z-index: -1;
    }
    .banner-global__min .btn{
      opacity: 0;
    }
  }
}

.banner-global + .mainNav {
  .header {
    margin-top: 44px;
    @media (max-width: 991px) {
      margin-top: 48px;
    }
  }
  + .mainNav.active .header {
    top: 44px;
    @media (max-width: 991px) {
      top: 48px;
    }
  }
}
