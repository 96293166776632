.searchbar__wrapper {
  input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
  .searchbar {
    margin-bottom: 80px;
    position: relative;
    display: flex;
    ::-webkit-input-placeholder {
      color: #3a3c45;
    }
    :-ms-input-placeholder {
      color: #3a3c45;
    }
    ::placeholder {
      color: #3a3c45;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 45px;
    }

    input {
      background-color: #f7f7f7;
      // padding: 50px 25px 46px 90px;
      padding: 30px 25px 30px 90px;
      height: auto;
      width: 100%;
      border: none;
      border-radius: 0 !important;
      background-image: url($path+"icons/search-icon.svg");
      background-position: 38px center;
      background-size: 21px 23px;
      background-repeat: no-repeat;
      font-size: 24px;
      color: #3a3c45;
      font-weight: 500;

      @media (max-width: 991px) {
        font-size: 20px;
        background-size: 16px 17px;
        background-position: 21px 27px;
        // padding: 35px 50px 30px 58px;
        padding: 19px 50px 16px 58px;
      }

      &:active {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    button {
      border: 0;
      position: absolute;

      &::after {
        display: none;
        position: relative;
      }
      &:active {
        outline: none;
        border: none;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &.arrow-button {
      input {
        border-bottom: 4px solid #3a3c45;
        @media (max-width: 991px) {
          border-bottom: 2px solid #3a3c45;
        }
      }
      button {
        right: 15px;
        width: 70px;
        height: 88px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#343437+0,343437+100&0+0,1+17 */
        background: -moz-linear-gradient(
          left,
          rgba(247, 247, 247, 0) 0%,
          rgba(247, 247, 247, 1) 17%,
          rgba(247, 247, 247, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          rgba(247, 247, 247, 0) 0%,
          rgba(247, 247, 247, 1) 17%,
          rgba(247, 247, 247, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(247, 247, 247, 0) 0%,
          rgba(247, 247, 247, 1) 17%,
          rgba(247, 247, 247, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00343437', endColorstr='#343437',GradientType=1 ); /* IE6-9 */

        @media (max-width: 991px) {
          right: 0;
          top: 0;
          width: 80px;
          height: 58px;
        }

        img {
          margin-top: 7px;
          @media (max-width: 991px) {
            margin-top: 7px;
            width: 20px;
            height: auto;
          }
        }
      }
    }

    &.filter-button {
      input {
        height: 82px;
        border-bottom: 2px solid #3a3c45;
        padding-right: 25%;
        padding-top: 24px;
        padding-bottom: 20px;
        @media (max-width: 1280px) {
          padding-right: 35%;
        }
        @media (max-width: 1199px) {
          padding-right: 35%;
        }
        @media (max-width: 991px) {
          background-position: 21px 22px;
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 8%;
          height: 62px;
          @include font-size(16);
          &:after {
            @include background-image("./icons/arrow-down.svg", 16, 8);
            right: 20px;
            position: absolute;
            top: 25px;
          }
        }
        @media (max-width: 475px) {
          padding-right: 12%;
        }
        @media (max-width: 320px) {
          padding-right: 15%;
        }
      }
      .search-mobile-button {
        display: none;
        @media (max-width: 991px) {
          display: block;
          position: absolute;
          width: 20px !important;
          height: 16px !important;
          padding: 0;
          top: 22px;
          right: 15px;
          border: 0;
          @include background-image("./icons/arrow-hero.svg", 16, 8);
        }
      }
      button {
        background-color: $brandYellow2;
        color: #333333;
        border: 0;
        height: 80px;
        font-family: $font-medium;
        font-weight: 600;
        width: 100%;
        max-width: 254px;
        right: 0;
        @include font-size(17);
        line-height: 100%;
        text-align: left;
        padding-left: 40px;

        @media (max-width: 991px) {
          position: relative;
          width: 100%;
          max-width: 100%;
          font-style: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.24px;
          padding: 0 20px;
          height: 60px;
          text-align: left;
        }

        &:after {
          @include background-image("./icons/arrow-down.svg", 16, 8);
          right: 40px;
          position: absolute;
          top: 38px;
          @media (max-width: 991px) {
            top: 25px;
            right: 20px;
          }
        }
      }
    }
    &.front-page {
      margin-bottom: 140px;
      @media (max-width: 991px) {
        margin-bottom: 60px;
      }
    }
  }

  &.searchbar-autocomplete{
    &.searchbar-autocomplete--active {
      pointer-events: all;
    }
    .searchLabel{
      font-family: $font-medium;
      font-weight: 600;
      color: #151515;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.24px;
      margin-bottom: 16px;

      @media (max-width: 991px) {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 12px;
      }

    }

    .searchbar{
      flex-wrap: wrap;

      input{
        background-color: transparent;
        padding: 26px 50px 26px 32px;
        background-image: none;
        color: #303030;
        font-size: 20px;
        line-height: 100%;
        font-weight: 400;
        border: 1px solid #CCCFDB;
        border-bottom: 3px solid #494A50;
        z-index: 2;
        height: 80px;
        @media (max-width: 991px) {
          height: 66px;
          font-size: 17px;
          padding: 20px 50px 20px 24px;
        }
      }

      &.arrow-button{
        button{
          display: none;

          &.active{
            display: block;
          }

          z-index: 2;
          right: 15px;
          height: 75px;
          top: 1px;
          background: rgb(255,255,255);
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 18%);
          @media (max-width: 991px) {
            height: 55px;
          }
          img{
            width: 18px;
          }
        }
      }

      .result-autocomplete{
        width: 100%;
        border: 1px solid #CCCFDB;
        z-index: 1;
        position: absolute;
        top: 79px;

        display: none;

        &.active{
          display: block;
        }

        @media (max-width: 991px) {
          top: 65px;
        }

        .items, .category{
          .item{
            display: block;
            text-decoration: none;
            padding: 20px 50px 20px 32px;
            border-bottom: 1px solid #CCCFDB;
            background: #ffffff;

            @media (max-width: 991px) {
              padding: 16px 50px 16px 24px;
            }

            &:hover{
              background: #F4F5F9;
            }
            &:last-child{
              border-bottom: none;
            }
            .title{
              font-family: $font-medium;
              font-size: 17px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0px;
              text-align: left;
              display: block;
              color: #303030;
              margin-bottom: 8px;
              @media (max-width: 991px) {
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 6px;
              }
              &__bold {
                font-weight: 600;
                // display: inline-block;
              }
            }
            .type{
              padding: 4px 12px;
              background: #F8E8B0;
              font-family: $font-regular;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0px;
              color: #101010;
              display: inline-block;
              @media (max-width: 991px) {
                font-size: 13px;
                line-height: 17px;
                padding: 6px 8px;
              }
            }
          }

        }

        .seeAll{
          a{
            display: block;
            padding: 32px 50px 32px 32px;
            background: #E2E4ED;
            font-family: $font-medium;
            font-weight: 400;
            text-decoration: none;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.32px;
            color: #101010;

            @media (max-width: 991px) {
              font-size: 15px;
              line-height: 22px;
              padding: 24px 50px 24px 24px;
            }
          }
        }

      }

    }
    &--clean {

      &.searchbar-autocomplete--active {
        &::before {
          content: '';
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #191A1CCC;
        }
      }
      .searchbar {
        input {
          @include font-size(18);
          line-height: 1.5rem;
          font-weight: 600;
          @media (max-width: 991px) {
            @include font-size(15);
            line-height: 1.13;
            letter-spacing: 0;
          }
        }
        .result-autocomplete {
          background-color: #fff;
          padding-top: 8px;
          border: none;
          border-top: 0.5px solid #DCDCDC;
          max-height: calc(100vh - 220px);
          overflow: auto;
          @media (max-width: 991px) {
            padding-top: 4px;
          }
          &__loading {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 4px solid #A2A3A6;
            margin: 16px auto;

            &:before {
              display: block;
              position: relative;
              left: calc(4px * -1);
              top: calc(4px * -1);
              content: ' ';
              width: 64px;
              height: 64px;
              border-radius: 50%;
              border: 4px solid;
              border-color: #202020 transparent transparent transparent;
              animation: loading-rotate .8s ease-out infinite;
            }
          }
          &__text {
            @include font-size(16);
            line-height: 1.19;
            color: #1B1B1B;
            font-weight: 600;
            letter-spacing: 0;
            padding: 32px 16px 16px 64px;
            @media (max-width: 991px) {
              @include font-size(15);
              line-height: 1.13;
              padding: 20px 16px 8px 32px;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          .items .item, .category .item {
            @include font-size(16);
            color: #1B1B1B;
            letter-spacing: 0.025em;
            line-height: 1.19;
            padding: 8px 16px 8px 64px;
            border: none;
            @media (max-width: 991px) {
              @include font-size(15);
              line-height: 1.13;
              letter-spacing: 0.02em;
              padding-left: 32px;
            }
            &:hover {
              font-weight: 600;
              letter-spacing: 0;
              background-color: #fff;
              text-decoration: underline;
              text-decoration-color: #a4a4a4;
              text-underline-position: under;
              text-decoration-thickness: 1px;
              text-underline-offset: 1px;
              .title {
                font-weight: 600;
                letter-spacing: 0;
                &__bold {
                  text-decoration: none;
                }
              }
            }
            .title {
              @include font-size(16);
              line-height: 1.19;
              color: #1B1B1B;
              font-weight: 500;
              letter-spacing: 0.025em;
              margin-bottom: 0;
              @media (max-width: 991px) {
                @include font-size(15);
                line-height: 1.13;
              }
              &__bold {
                letter-spacing: 0em;
              }
            }
            .type {
              display: none;
            }

          }
          .seeAll {
            a {
              @include font-size(16);
              font-weight: 600;
              letter-spacing: 0em;
              line-height: 1.19;
              padding: 32px 16px 64px 64px;
              background-color: #fff;
              @media (max-width: 991px) {
                @include font-size(15);
                line-height: 1.13;
                letter-spacing: 0.02em;
                padding: 20px 16px 40px 32px;
              }
              &:hover {
                text-decoration: underline;
                text-decoration-color: #a4a4a4;
                text-underline-position: under;
                text-decoration-thickness: 1px;
                text-underline-offset: 1px;
              }
            }
          }
        }
      }
    }

  }

}

.search-result-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;

  & > .loading {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 4px solid #A2A3A6;
    margin: 16px auto;

    &:before {
      display: block;
      position: relative;
      left: calc(4px * -1);
      top: calc(4px * -1);
      content: ' ';
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 4px solid;
      border-color: #202020 transparent transparent transparent;
      animation: loading-rotate .8s ease-out infinite;
    }
  }
}

@keyframes loading-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
