.registration-form {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  padding-bottom: 100px;

  @media (min-width: 1201px) {
    margin: 0 140px;
  }

  @media (max-width: 1200px) {
    padding-bottom: 50px;

  }

  &__text {
    margin: 110px 0 60px 0;

    @media (max-width: 1200px) {
      margin: 38px 0;
    }
  }

  &__title {
    font-size: 96px;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      font-size: 36px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-size: 26px;

    @media (max-width: 1200px) {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }

  // FORM

  &__wrap {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px
  }

  &__group {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    gap: 45px;
    flex: 1;

    @media (max-width:1400px) {
      flex: 0;
    }

    @media (max-width: 1200px) {
      gap: 16px;
    }

    &--outlined {
      max-width: 675px;
      outline: 2px solid #ccc;
      margin: -15px -15px -15px -15px;
      padding: 15px 15px;

      @media (max-width: 1200px) {
        max-width: none;
        outline: none;
        margin: auto;
        padding: 0;
        width: 100%;
      }
    }

  }

  &__select {

    //reset native browser properties and controls the after arrow

    max-width: 650px;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 50px;

    border: 2px solid #000;
    color: #999;

    @media (max-width: 1200px) {
      height: 40px;
      border: 1px solid #000;
    }

    &::after {
      content: '';
      position: absolute;
      background-image: url('../images/icons/arrow-down.svg');
      background-position: center right;
      background-repeat: no-repeat;
      background-size: contain;
      height: 20px;
      width: 20px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      @media (max-width: 1200px) {
        height: 15px;
        width: 15px;
      }

    }

    &:focus-visible {
      height: 38px;

    }
  }

  &__select-element {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;


    @media (max-width: 1200px) {
      height: 38px;
      padding: 0 12px;
    }

    &:focus-visible {
      outline-style: outset;
    }
  }

  &__field {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 12px 14px;
    max-width: 650px;
    background-color: transparent;
    border: 2px solid #000;
    width: 100%;

    &::placeholder {
      color: #999;
    }

    &:focus-visible {
      outline-style: outset;
    }

    @media (max-width: 1200px) {
      height: 40px;
      border: 1px solid #000;
    }

    &--bilde {
      border: none;
    }

    &--file {
      opacity: 0;
      position: absolute;
      right: 0;
      width: 190px;
      pointer-events: none;
    }

    &--fieldset {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      overflow: hidden;
      word-break: break-all;
      height: auto;
    }

  }

  &__file-name {
    display: inline-flex;
    align-items: center;
    height: inherit;
    padding: 0 14px;
    color: #999;
    font-size: 1.0625rem;
    font-family: $font-medium;
  }

  &__file-upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 190px;
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    cursor: pointer;
    border-radius: 25px;
    white-space: nowrap;
    margin: 5px 10px 5px 0;

    @media (max-width: 1200px) {
      padding: 5px 16px;
      height: 30px;
      width: 120px;
      font-size: 12px;
      border: 1px solid #000;
    }

    @media (max-width: 400px) {
      padding: 5px;
      height: unset;
      width: unset;
      font-size: 12px;
      border: 1px solid #000;
    }

    &:hover {
      background-color: #fd0;
      color: #000;
      border: 2px solid #000;
    }
  }

  &__companies {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
    margin-top: 45px;

    @media (max-width: 1400px) {
      display: block;
      margin-top: 30px;
    }
  }

  &__project {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 26px;

    @media (max-width: 1400px) {
      margin-top: 35px;
      flex-direction: row;
      gap: 15px;
      font-size: 16px;
    }

    @media (max-width: 1200px) {
      margin-top: 20px;
    }

    @media (max-width: 400px) {
      flex-direction: column;
    }
  }

  &__project-btn {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #000;
    margin-right: 12px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
      border: 1px solid #000;
      height: 25px;
      width: 25px;
      font-size: 24px;
    }

    @media (max-width: 400px) {
      height: 20px;
      width: 20px;
      font-size: 20px;
    }

    &--plus {
      background-color: #fff;
      color: #000;
      padding-bottom: 2px;

      &:hover,
      &:active {
        background-color: #fd0;
        border: 2px solid #000;
      }

      @media (max-width: 1200px) {
        &:hover {
          background-color: #fff;
          border: 1px solid #000;
        }

        &:active {
          background-color: #fd0;
          border: 2px solid #000;
        }
      }

    }

    &--minus {
      font-size: 28px; // Adjust the icon size to be more proportional.

      &:hover,
      &:active {
        background-color: #000;
        color: #fff;
        border: 2px solid #ccc;
      }

    }
  }

  &__divider {
    display: none;

    @media (max-width: 1400px) {
      display: block;
      max-width: 700px;
      border: 1px solid #000;
      margin: 10px 0 10px -15px;
    }

    @media (max-width: 1200px) {
      max-width: 650px;
      border-top: 1px solid #000;
      border-bottom: none;
      margin: 5px 0;
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 650px;

    > div:first-child {
      margin-top: 0px ;
    }

    >.row {
      margin-left: 0 !important;
      padding-left: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }


  &__terms {
    display: flex;
    gap: 20px;
    font-size: 26px;
    line-height: 28px;
    margin-top: 20px;

    @media (max-width: 1400px) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 0;
    }

    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 0;
    }

    @media (max-width: 475px) {
      gap: 12px;

    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      height: 25px;
      width: 25px;

    }

    input[type="checkbox"]+label {
      position: relative;
      padding-left: 25px;
      padding-top: 25px;
      height: 25px;
      width: 25px;

      cursor: pointer;

      @media (max-width: 1200px) {
        padding-left: 20px;
        padding-top: 20px;
        height: 20px;
        width: 20px;
      }
    }

    input[type="checkbox"]+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 2px solid #000;

      @media (max-width: 1200px) {
        width: 20px;
        height: 20px;
        border: 1px solid #000;

      }
    }

    input[type="checkbox"]:checked+label:before {
      border: solid #000;
      background: #fd0;
    }

    input[type="checkbox"]:checked+label:after {
      content: '';
      position: absolute;
      left: 50%;
      /* Center horizontally */
      top: 40%;
      /* Center vertically */
      width: 9px;
      height: 15px;
      /* Reduce the height of the icon to appear more centered */
      border: solid black;
      border-width: 0 2px 2px 0;
      /* Adjust the width of the icon */
      transform: translate(-50%, -50%) rotate(45deg);
      /* Move the icon to the center */

      @media (max-width: 1200px) {
        width: 6px;
        height: 10px;
        /* Reduce the height of the icon to appear more centered */

      }
    }
  }

  &__send-btn {
    display: inline-flex;
    width: fit-content;
    align-items: center;
    margin-top: 18px;
    padding: 10px 28px;
    border-radius: 42px;
    border: none;
    color: #fff;
    background-color: #1F1F21;
    font-size: 26px;

    @media (max-width: 1200px) {
      padding: 8px 26px;
      font-size: 20px;
      height: auto;
    }

    &:hover:not(:disabled) {
      color: #fff;
      box-shadow: 0 0 0 2px #878787;
      text-decoration: none;
    }

    &:disabled {
      background-color: grey;
      cursor: not-allowed;
    }
  }

  &__modal-box {
    display: none;
    position: fixed;
    z-index: 1000;
    padding-top: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  &__modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 2.5rem;
    border: 1px solid #888;
    width: 55%;

    h2,
    h1,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 30px;
    }

    h4 {
      font-size: 24px;
    }

    h5 {
      font-size: 20px;
    }

    h6 {
      font-size: 18px;
    }

    @media (max-width: 320px) {
      width: 90%; // Modal width for screens smaller than 320px
    }

    @media (min-width: 321px) and (max-width: 480px) {
      width: 85%; // Modal width for screens between 321px and 480px
    }

    @media (min-width: 481px) and (max-width: 768px) {
      width: 75%; // Modal width for screens between 481px and 768px
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 70%; // Modal width for screens between 769px and 1024px
    }

    @media (min-width: 1025px) and (max-width: 1440px) {
      width: 65%; // Modal width for screens between 1025px and 1440px
    }

    @media (min-width: 1441px) and (max-width: 1920px) {
      width: 60%; // Modal width for screens between 1441px and 1920px
    }

    @media (min-width: 1921px) {
      width: 55%; // Modal width for screens larger than 1920px
    }

  }

  &__modal-close {
    color: #aaa;
    display: inline-flex;
    align-items: start;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__company-results {
    display: none;
    flex-direction: column;
    max-height: 390px;
    max-width: 650px;
    width: 100%;
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  &__company-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: .75rem 1rem;
    border-bottom: 1px solid #dee2e6;

    &:hover {
      opacity: 0.4;
      transition: all 0.2s;
    }
  }
}
