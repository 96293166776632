.article-slider{
  padding: 80px 0 120px 0;
  overflow: hidden;

  @media(max-width: 992px){
    padding: 40px 0 60px 0;
  }

  h4{
    margin-bottom: 42px;
    font-size: 28px;
  }
  .slick-arrow{
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    z-index: 1;
    top: 100px;
    &:hover , &:focus , &:focus-visible {
      background-color: #FFFFFF;
    }

    @media(max-width: 1024px){
      top: 140px;
    }

    @media(max-width: 991px){
      //top: 110px;
      top: 100px;
    }

    @media(max-width: 767px){
      width: 60px;
      height: 60px;
      top: 40%;
    }

    @media(max-width: 576px){
      top: 35%;
    }

    @media(max-width: 425px){
      top: 30%;
    }

    &:after {
      content: '';
      background-image: url(../images/icons/arrow-right-24.svg);
      background-repeat: no-repeat;
      background-position: center center;
      width: 24px;
      height: 24px;
      display: block;
      margin: auto;
    }
    &:before{
      display: none;
    }

    &.slick-disabled{
      display: none !important;
    }

    &.slick-prev{
      display: none;
      left: -40px;
      @media(max-width: 767px){
        //left: -10px;
        //display: none !important;
        left: 10px;
      }
      &:after {
        transform: rotate(180deg);
      }
    }

    &.slick-next{
      right: -40px;
      @media(max-width: 767px){
        //right: 0px;
        right: 10px;
      }
    }
  }

  &__wrapper{
    .slick-list{
      overflow: visible;
      margin: 0 -25px;
    }

    .slick-track{
      margin: 0;
    }
  }
  &__item{
    margin: 0 20px;
    @media(max-width: 768px){
      margin: 0 15px;
      //margin: 0 60px 0 30px;
    }
    &:last-child{
      //margin: 0;
    }

    &.slick-slide:not(.slick-active){
      opacity: 0.5;
      @media(max-width: 768px){
        //opacity: 1;
      }
    }

    &.slick-slide{
      &.slick-current{
        @media(max-width: 768px){
          //opacity: 1;
          opacity: 1;
        }
      }
    }

    &--image{
      margin-bottom: 12px;
      display: block;
    }

    .subject{
      text-transform: uppercase;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 8px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      @media(max-width: 992px){
          font-size: 10px;
      }
    }

    h3{
      font-size: 19px;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;

      @media(max-width: 992px){
          font-size: 18px;
      }
    }
  }

  &--primary{
    background-color: $glava-green-200;

    @include site(weber){
      background-color: $weber-black-50;
    }

    @include site(gyproc){
      background-color: $gyproc-aqua-300;
    }
  }

  &--secondary{
    background-color: $glava-black;
    color: $white;

    @include site(weber){
      background-color: $weber-anthracite-500;
    }

    @include site(gyproc){
      background-color: $gyproc-sand-300;
      color: inherit;
    }
  }
}
