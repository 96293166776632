.block-list {
  overflow: hidden;
  margin-top: 124px;
  margin-bottom: 80px;
  touch-action: none;
  @media (max-width: 991px){
    margin: 60px 0 40px;
  }
  h2 {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      @include font-size(24);
      line-height: 1.17;
      margin-bottom: 32px;
      letter-spacing: 0.12px;
    }
  }

  &__wrapper {
    min-width: 100%;
    overflow: hidden;
    padding-bottom: 2px;
    @media (max-width: 991px){
      overflow: visible;
    }
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      li{
        float: left;
        height: 100%;
        padding: 0 15px;
      }
    }
    &.sly-active {
      ul {
        display: block;
      }
    }
  }
  .scrollbar{
    height: 1px;
    background: $gray-200;
    line-height: 0;
    margin-bottom: 24px;
    width: 100%;
    .handle{
      width: 100px;
      height: 100%;
      background: $gray-800;
      cursor: pointer;
      position: relative;
      .mousearea{
        position: absolute;
        top: -9px;
        left: 0;
        width: 100%;
        height: 20px;
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: end;
    margin-top: 54px;
    flex-wrap: wrap;
    display: none;
    padding-bottom: 2px;
    .button {
      font-size: 0;
      display: block;
      position: static;
      background-color: $glava-black;
      border-radius: 0;
      transform: none;
      height: 40px;
      width: 40px;
      background-image: url("../images/icons/arrow-right-24-w.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      padding: 0;

      @include site(weber){
        background-color: $weber-black;
      }

      @include site(gyproc){
        background-color: $gyproc-black;
      }

      &.disabled{
        background-color: $gray-600;
        opacity: 1;
        pointer-events: none;
      }

      &:hover , &:focus-visible{
        background-color: $glava-black;
        background-image: url("../images/icons/arrow-right-24-w.svg");
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 0 0 2px $gray-400 !important;

        @include site(weber){
          background-color: $weber-black;
        }

        @include site(gyproc){
          background-color: $gyproc-black;
        }
      }
      &.prev {
        margin-right: 4px;
        transform: rotate(180deg);
      }
    }

  }
  &__item {
    position: relative;
    background: #FFFFFF;
    // transition: all 0.3s;
    // max-height: 440px;
    width: 33.3%;
    flex: 0 0 33.3%;
    @media (max-width: 991px){
      width: 50%;
      flex: 0 0 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
      flex: 0 0 100%;
    }
    a {
      display: block;
      border: none!important;
    }
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    >* {
      pointer-events: all;
    }
    .img {
      margin-bottom: 20px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &__title {
      @include font-size(24);
      width: fit-content;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: -0.0045em;
    }
    &__link, &__link:hover {
      width: fit-content;
      color: inherit;
      @include font-size(16);
      line-height: 1.25;
      letter-spacing: 0.01em;
      margin-top: 10px;
      text-decoration: underline 1px $gray-800!important;
      display: block;
      color: $gray-800;
    }
  }
  &--four {
    .block-list__item {
      @media (min-width: 992px) {
        width: 25%;
        flex: 0 0 25%;
      }
    }
  }
}
