.glava-calculator {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;

   padding-block: 44px 52px;

   @media (max-width: 992px) {
      padding-block: 40px;
   }

   @media (max-width: 575px) {
      padding-bottom: 0;
   }

   h2 {
      margin-bottom: 44px;
      @include font(false, 32px, false, 600, 1.25)
   }
}

.glava-calculator__form {

   // Resetting input and select;
   input,
   select {
      padding: 0;
      border-bottom: none;
   }

   h3 {
      margin-bottom: 20px;
      @include font(false, 18px, false, false, 1.25);

      @media (max-width: 992px) {
         margin-bottom: 16px;
      }
   }

   h3:not(:first-child) {
      margin-top: 36px;
   }

   h4 {
      margin-bottom: 12px;
      @include font(false, 12px, false, false, 1);
   }

   h4:not(:first-child) {
      margin-top: 28px;

      @media (max-width: 992px) {
         margin-top: 24px;
      }
   }

   h3+h4 {
      margin-top: 20px !important;

      @media (max-width: 992px) {
         margin-top: 16px !important;
      }
   }

   input,
   select {
      display: block;

      &:focus-visible {
         outline: none;
      }
   }

   fieldset {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      @media (max-width: 992px) {
         row-gap: 16px;
      }
   }

   fieldset+fieldset {
      margin-top: 12px;
   }

   label {
      width: 100%;
      height: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 4px;
      padding: 6px 12px 3px 12px;
      background-color: #E1E7E4;
      cursor: pointer;
      transition: all 175ms ease-out;
   }

   label:has(input:not([type="checkbox"])),
   label:has(select) {
      border: 1px solid #E1E7E4;

      &:hover {
         background-color: #D4DDD9;
      }
   }

   label:has(input:focus-visible:not([type="checkbox"])),
   label:has(select:focus-visible) {
      background-color: #D4DDD9;
      border-color: #949E99;
      outline: none;
   }

   label:has(input:disabled:not([type="checkbox"])),
   label:has(select:disabled) {
      background-color: #F1F4F2;
      border-color: #F1F4F2;
      color: #9E9E9E;

      svg {
         opacity: 0.5;
      }
   }

   label:has(input:placeholder-shown) {
      span {
         overflow: hidden;
         height: 0;
      }
   }

   label .form-element-label {
      font-size: 14px;
      color: #666666;
      transition: all 125ms ease-in-out;
   }

   input {
      min-width: 0;
      width: 100%;
      height: 0px;
      font-size: 14px;
      color: #2A2A2A;
      border: none;
      outline: none;
      background-color: transparent;
      transition: all 125ms ease-in-out;
   }

   input+span {
      font-size: 14px;
      line-height: 16px;
      color: #666666;
   }

   input:focus {
      border: none;
      outline: none;
   }

   label:has(input:not([type="checkbox"]):focus) .form-element-label,
   label:has(input:not([type="checkbox"]):not(:placeholder-shown)) .form-element-label {
      font-size: 8px;
      letter-spacing: 0.6px;
      font-weight: bold;
   }

   label:has(input:not([type="checkbox"]):focus) input,
   label:has(input:not([type="checkbox"]):not(:placeholder-shown)) input {
      height: 16px;
   }

   & .input-group {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      @media (max-width: 992px) {
         row-gap: 8px;
      }
   }

   & .input-helper-text {
      font-size: 12px;
      line-height: 1.2;
      color: #666666;
   }

   .input-group+.input-helper-text,
   label+.input-helper-text {
      margin-top: calc(8px - 20px);

      @media (max-width: 992px) {
         margin-top: calc(6px - 16px);
      }
   }

   /* Styling Select */
   label:has(select) {
      padding: 0;

      & select {
         padding-inline: 12px;
         appearance: none;
         /* Standard */
         -webkit-appearance: none;
         /* Safari */
         -moz-appearance: none;
         /* Firefox */
         background-color: transparent;
         width: 100%;
         height: 100%;
         font-size: 14px;
         color: #2A2A2A;
      }

      & svg {
         position: absolute;
         right: 12px;
         top: calc(20px - 5px);
      }
   }

   /* Styling Select */

   /* Styling Checkbox */
   label:has(input[type="checkbox"]) {
      display: inline-flex;
      flex-direction: row;
      padding: 0;
      column-gap: 12px;
      background-color: transparent;
      justify-content: flex-start;
      align-items: flex-start;
      width: auto;
      height: auto;

      input {
         position: absolute;
         top: 5px;
         left: 5px;
         width: 1px;
         height: 1px;
         z-index: 0;
      }

      &::before {
         content: "";
         position: relative;
         display: block;
         width: 16px;
         height: 16px;
         background-color: #E1E7E4;
         border: 1px solid #B2B2B2;
         z-index: 1;
      }

      &::after {
         content: "";
         display: none;
         position: absolute;
         width: 17px;
         height: 17px;
         top: -1px;
         left: -1px;
         background-image: url("./../images/icons/check-mark.svg");
         background-position: center;
         background-repeat: no-repeat;
         z-index: 1;
      }

      & .form-element-label {
         color: #2A2A2A;
      }
   }

   label:has(input[type="checkbox"]:checked) {
      &::after {
         display: block;
      }
   }

   /* Styling Checkbox */
}

.glava-calculator__summary {
   display: flex;
   flex-direction: column;
   row-gap: 32px;
   padding: 20px;
   padding-bottom: 28px;
   background-color: #1B1B1B;
   font-size: 14px;

   @media (min-width: 993px) {
      position: sticky;
      top: 32px;
   }

   @media (max-width: 992px) {
      margin-top: 40px;
      padding: 32px 16px;
      padding-bottom: 48px;
   }

   @media (max-width: 575px) {
      margin-inline: -20px;
   }

   h3 {
      color: #FFFFFF;
      font-size: 18px;
   }

   .summary-group {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
   }

   .summary-observation {
      color: #848484;

      @media (max-width: 992px) {
         font-size: 12px;
      }
   }

   .summary-item {
      display: flex;
      justify-content: space-between;
      column-gap: 12px;

      div:first-child {
         color: #FFFFFF;
         font-weight: 600;
      }

      div:nth-child(2) {
         color: #FFFFFF;
      }

      span.unit {
         color: #848484;
      }
   }

   .summary-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      column-gap: 6px;
      width: 100%;
      padding-block: 10px;
      background-color: #FFFFFF;
      color: #1B1B1B;
      font-weight: 600;
      border: 1px solid #1B1B1B;
      border-radius: 999px;
      cursor: pointer;
      transition: all 175ms ease-out;

      input {
         color: inherit;
         background-color: transparent;
         border: none;

         svg {
            transition: all 175ms ease-out;
         }
      }

      &:hover {
         border-color: #FFFFFF;
         background-color: #1B1B1B;
         color: #FFFFFF;

         svg {
            path {
               fill: #FFFFFF;
            }
         }
      }
   }
}
