.aside-filter-top{
  margin-bottom: 40px;
  // background-color: transparentize(#E9EDF3, 0.8);
  background-color: #fff;

  @media(max-width: 991px){
    margin-bottom: 12px;
    background-color: transparent;
  }

  .wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 32px 24px 0;
    border-bottom: 1px solid #EBECEC;

    @media(max-width: 991px){
      flex-direction: column;
      padding: 0;
      border: none;
    }

    .aside-filter-top__result-number{
      color: #1E1F22;
      @include font-size(14);
      line-height: 1.429;
      letter-spacing: 0.015em;
      font-weight: 600;
      min-width: 122px;

      @media(max-width: 991px){
        display: block;
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        order: 3;
      }
    }

    .aside-filter-top__brands{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      @media(max-width: 991px){
        width: 100%;
        margin: 0 0 24px 0;
      }

      h6{
        color: #1E1F22;
        @include font-size(14);
        line-height: 1.429;
        letter-spacing: 0.025em;
        font-weight: 400;
        text-transform: uppercase;
        margin-right: 24px;

        @media(max-width: 991px){
          display: none;
        }
      }

      .brands-list{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media(max-width: 991px){
          width: 100%;
        }

        .item{
          margin-right: 8px;

          @media(max-width: 991px){
            width: 100%;
            margin-right: -1px;
          }

          &:last-child{
            margin-right: 0;
          }

          button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 64px;
            background-color: white;
            border: 2px solid #E9EBEF;

            @media(max-width: 991px){
              width: 100%;
              height: 80px;
            }

            img{
              width: 100%;
              max-width: 88px;
              max-height: 36px;
              object-fit: contain;

              @media(max-width: 576px){
                max-width: 77px;
                max-height: 28px;
              }
            }

            &:hover{
              border-color: #C5C6CB;

              @media(max-width: 991px){
                border-color: #E9EBEF;
              }
            }

            &.active{
              border-color: #3A3C44;

              @media(max-width: 991px){
                position: relative;
                border: none;
              }

              &::after{
                @media(max-width: 991px){
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border: 2px solid #3A3C45;
                }
              }
            }
          }
        }
        &--remove {
          color: #616E73;
          @include font-size(14);
          line-height: 1.429;
          letter-spacing: 0.025em;
          font-weight: 400;
          text-decoration: underline;
          text-decoration-skip-ink: none;
          border: none;
          background-color: transparent;
          margin-left: 24px;
          display: inline;
          @media(max-width: 991px){
            display: none;
          }
        }
      }
    }

    .aside-filter-top__mobile-buttons{
      display: none;

      @media(max-width: 991px){
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 28px;
      }

      button{
        width: 100%;
        margin-right: 15px;
        background-color: transparent;
        border: 1px solid #E5E6E8;
        padding: 12px 20px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;

        &:last-child{
          margin-right: 0;
        }

        &.btn-show-filters{
          background-color: #3A3C45;
          border-color: #3A3C45;
          color: white;
        }
      }
    }

    .aside-filter-top__viewing{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 60px;

      @media(max-width: 1200px){
        margin-left: 40px;
      }

      @media(max-width: 991px){
        position: fixed;
        bottom: -277px;
        left: 0;
        flex-direction: column;
        width: 100vw;
        margin: 0;
        padding: 20px 0px 68px;
        background: white;
        opacity: 0;
        transition: bottom 0.4s, opacity 0.3s;
        z-index: 999;
      }

      h6{
        margin-right: 24px;
        font-size: 13px;
        line-height: 24px;
        font-weight: 600;
        display: none;

        @media(max-width: 991px){
          padding-bottom: 16px;
          width: 100%;
          margin-right: 0;
          margin-bottom: 40px;
          border-bottom: 1px solid #EBECEE;
          text-align: center;
          display: block;
        }
      }

      .view-options{
        list-style: none;
        display: flex;
        flex-direction: row;

        @media(max-width: 991px){
          padding: 0 25px;
        }

        .item{
          margin-right: 8px;

          @media(max-width: 991px){
            margin-right: 0;
            margin-left: -1px;
          }

          &:last-child{
            margin-right: 0;
          }

          button{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: transparent;
            border: none;

            @media(max-width: 991px){
              width: inherit;
              height: inherit;
              padding: 44px;
              line-height: 0;
              border: 1px solid #E5E6E8;
            }

            &:hover{
              svg{
                path{
                  fill: #1E1F22;
                }
              }
            }

            &.active{
              background-color: #EBECEC;
              &::after{
                @media(max-width: 991px){
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border:1px solid #3A3C45;
                }
              }

              svg{
                path{
                  fill: #1E1F22;
                }
              }
            }
          }
        }
      }

      &.active{
        @media(max-width: 991px){
          bottom: 0;
          opacity: 1;
        }
      }
    }
  }
  .aside-main-search {
    @media(min-width: 992px) {
      display: none;
    }
  }
}
.aside-main-search {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  @media(max-width: 991px){
    margin-bottom: 24px;
  }
  input{
    padding: 16px 44px 16px 16px;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: 0.0155em;
    height: 52px;
    width: 100%;
    border: 1px solid #E5E6E8;
    color: #121212;
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #A8A9B0;
      opacity: 1; /* Firefox */
      font-weight: 500;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #A8A9B0;
      font-weight: 500;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #A8A9B0;
      font-weight: 500;
    }
    &:focus {
      outline: none;
      border-color: #121212;
    }

    &[type="submit"] {
      position: absolute;
      width: 44px;
      right: 0;
      padding: 0;
      height: 100%;
      border-color: transparent;
      background-color: transparent;
      background-image: url("../images/./icons/search-icon.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: right 16px center;
      &:focus-visible {
        outline: none;
        border-color: #121212;
      }
    }
  }
}
.aside-wrapper{
  @media(max-width: 991px){
    // display: none;
    position: fixed;
    top: 100vh;
    left: 0;
    right: 0;
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 80px);
    padding: 0;
    background-color: white;
    z-index: 999;
    transition: top 0.5s ease-in-out;
  }

  &.active{
    @media(max-width: 991px){
      top: 80px;
    }
  }

  .aside-sticky-container{
    position: sticky;
    top: 40px;
    max-height: calc(100vh - 40px);
    padding-bottom: 20px;
    overflow: auto;
    transition: top 0.3s ease-in-out;

    @media(max-width: 991px){
      position: relative;
      top: 0;
      height: 100%;
      max-height: inherit;
      padding-bottom: 0;
      transition: none;
    }

    // CUSTOM SCROLLBAR
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 50px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(179, 179, 179);
      border-radius: 50px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(130, 130, 130);
    }

    &.has-fixed-header{
      top: 120px;
    }

    .aside-filter{
      padding-right: 25px;

      @media(max-width: 1200px){
        padding-right: 15px;
      }

      @media(max-width: 991px){
        padding: 20px 25px 110px;
      }

      &__mobile-header{
        display: none;

        @media(max-width: 991px){
          display: block;
          margin: 0 -25px;
          padding: 0 0 16px;
          border-bottom: 1px solid #E5E6E8;
        }

        h6{
          @media(max-width: 991px){
            padding: 0 25px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            text-align: center;
          }
        }
      }

      .accordion-group{
        @media(max-width: 991px){
          margin-top: 40px;
        }

        .aside-filter__accordion{
          margin-bottom: 40px;

          @media(max-width: 991px){
            margin-bottom: 20px;
          }

          &:last-child{
            margin-bottom: 0;
          }

          .accordion-header{
            border-bottom: 1px solid #121212;

            &:last-child{
              margin-bottom: 0;
            }

            button{
              position: relative;
              width: 100%;
              padding-right: 35px;
              padding-bottom: 12px;
              font-size: 17px;
              line-height: 24px;
              font-weight: 600;
              background-color: transparent;
              text-align: left;
              border: none;

              @media(max-width: 991px){
                font-size: 15px;
                line-height: 21px;
              }

              &::after{
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: calc(50% - 13px);
                width: 13px;
                height: 13px;
                background-image: url("../images/./icons/arrow-up-black.svg");
                background-repeat: no-repeat;
                background-position: center;
                -webkit-transform: rotateZ(180deg);
                transform: rotateZ(180deg);
                transition: 0.1s;
              }
            }
          }

          .accordion-content{
            display: none;

            .filter-list{
              margin-top: 20px;
              list-style: none;

              li{
                label {
                  display: block;
                  position: relative;
                  padding-left: 37px;
                  margin-bottom: 12px;
                  cursor: pointer;
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 500;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;

                  @media(max-width: 991px){
                    padding-left: 32px;
                    font-size: 15px;
                    line-height: 21px;
                  }

                  &:hover{
                    .checkmark{
                      @media(min-width: 992px){
                        border-color: #3A3C45;
                        background-color: transparentize(#3A3C45, 0.9);
                      }
                    }
                  }

                  &:focus-visible{
                    outline: 2px solid;
                  }

                  input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:checked ~ .checkmark {
                      background-color: #3A3C45;
                      border-color: #3A3C45;

                      &:after {
                        display: block;
                      }
                    }

                    &:focus-visible{
                      + span{
                        outline: 2px solid;
                      }
                    }
                  }

                  .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    border: 1px solid #D2D3D5;

                    @media(max-width: 991px){
                      width: 22px;
                      height: 22px;
                    }

                    &:after {
                      content: "";
                      position: absolute;
                      display: none;
                      left: 9px;
                      top: 4px;
                      width: 6px;
                      height: 11px;
                      border: solid white;
                      border-width: 0 2px 2px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);

                      @media(max-width: 991px){
                        left: 7px;
                        top: 3px;
                      }
                    }
                  }
                }
              }
            }

            .btn{
              &--show-all{
                width: 100%;
                justify-content: center;
                margin-top: 20px;
                padding: 9px 24px 7px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
                color: #121212;
                background-color: #ECEFF4;
                border-color: transparent;
                border-radius: 100px;
                transition: none;

                &:hover{
                  background-color: #3A3C45;
                  color: white;
                }

                &:focus-visible{
                  outline: 2px solid;
                }
              }
            }
          }

          &.active{
            @media(max-width: 991px){
              margin-bottom: 40px;
            }

            &:last-child{
              margin-bottom: 0;
            }

            .accordion-header{
              button{
                &::after{
                  -webkit-transform: rotateZ(0deg);
                  transform: rotateZ(0deg);
                }
              }
            }

            .accordion-content{
              display: block;
            }
          }
        }
      }

      .aside-filter__search{
        position: relative;
        margin-top: 16px;

        @media(max-width: 991px){
          display: none;
        }

        &::before{
          content: "";
          position: absolute;
          top: (50%);
          margin-top: -8px;
          left: 12px;
          width: 16px;
          height: 16px;
          background-image: url("../images/./icons/search-icon.svg");
          background-repeat: no-repeat;
          background-size: contain;
          pointer-events: none;
        }

        input{
          width: 100%;
          height: 40px;
          padding: 10px 10px 10px 36px;
          border: 1px solid #E5E6E8;
          color: #121212;
          font-weight: 600;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 0;

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #121212;
            opacity: 1; /* Firefox */
            font-weight: 500;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #121212;
            font-weight: 500;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: #121212;
            font-weight: 500;
          }
        }

        &--mobile{
          display: none;

          @media(max-width: 991px){
            display: block;
            margin-top: 28px;
            margin-bottom: 40px;
          }

          &::before{
            @media(max-width: 991px){
              left: 16px;
            }
          }

          input{
            @media(max-width: 991px){
              height: 48px;
              padding: 15px 15px 15px 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
    .aside-main-search {
      @media(max-width: 991px) {
        display: none;
      }
    }
  }

  .show-results-wrapper{
    display: none;

    @media(max-width: 991px){
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 16px 25px 20px;
      background-color: white;
      border-top: 1px solid #E5E6E8;
    }

    button{
      @media(max-width: 991px){
        width: 100%;
        padding: 16px;
        background-color: #3A3C45;
        border: none;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: white;
      }
    }
  }
}
