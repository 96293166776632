.article-grid {
  margin: 80px 0 88px;
  @media (max-width: 991px) {
    margin: 44px 0 32px;
  }
  &__title {
    @include font-size(28);
    line-height: 1.14;
    margin-bottom: 48px;
    @media (max-width: 991px) {
      @include font-size(22);
      line-height: 1.27;
      letter-spacing: 0.12px;
      margin-bottom: 24px;
    }
  }
  &__wrapper {
    margin-bottom: -48px;
    @media (max-width: 991px) {
      margin-bottom: -24px;
    }
  }
  &__item {
    @include font-size(17);
    line-height: 1.53;
    letter-spacing: 0.01em;
    margin-left: 40px;
    margin-bottom: 48px;
    display: block;
    position: relative;
    @media (max-width: 991px) {
      @include font-size(16);
      line-height: 1.25;
      margin-bottom: 24px;
      margin-left: 44px;
    }
    &:hover {
      text-decoration: none;
    }
    &::before {
      content: '';
      position: absolute;
      @include background-image("icons/lightbulb-24.svg", 24, 24);
      left: -40px;
      top: 0;
    }
    &__title {
      font-weight: 600;
      margin-bottom: 8px;
      @media (max-width: 991px) {
        margin-bottom: 6px;
      }
    }
    &__description {
      color: $gray-800;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      margin-bottom: 12px;
      @media (max-width: 991px) {
        margin-bottom: 8px;
      }
    }
    &__link {
      color: $gray-800;
      @include font-size(14);
      line-height: 1.429;
      font-weight: 600;
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        margin-left: -20px;
        @include background-image("icons/arrow-right-20-gray-800.svg", 18, 18);
        left: -40px;
        top: 0;
        vertical-align: middle;
      }
      > span {
        color: $gray-800;
        margin-right: 24px;
        vertical-align: middle;
      }

      &.noLink {
        &::after {
          display: none;
        }
      }
    }
  }
}
