.page-header{
    margin-bottom: 70px;
    @media (max-width: 992px) {
        margin-bottom: 35px;
    }
    &.faqPage{
        margin-bottom: 40px;
    }
    article{
        h1{
            @include font-size(80);
            line-height: 84px;
            color: #2A2A2A;
            margin-bottom: 40px;
            padding-right: 26%;
            @media (max-width: 992px) {
                @include font-size(32);
                line-height: 40px;
                margin-bottom: 15px;
                padding-right: 0px;
            }
        }
        p{
            @include font-size(24);
            line-height: 44px;
            font-weight: 500;
            margin-bottom: 48px;
            color: #333333;
            padding-right: 26%;
            @media (max-width: 992px) {
                @include font-size(18);
                line-height: 30px;
                margin-bottom: 28px;
                padding-right: 0px;
            }
        }
    }
    &.withoutTags{
        article{
            p:last-child{
                margin-bottom: 15px;
            }
        }
    }
}
