.priceListWrapper{
  margin-top: 60px;
  margin-bottom: 260px;
  @media (max-width: 992px) {
    margin-top: 0px;
    margin-bottom: 100px;
  }

  &.list{
    margin-bottom: 240px;
    @media (max-width: 992px) {
      margin-bottom: 120px;
    }
  }

  &__pageHeader{
    h1{
      font-family: $font-regular;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0.12px;
      color: #2A2A2A;
      margin-bottom: 24px;

      @media (max-width: 992px) {
        font-size: 32px;
        line-height: 40px;
      }

    }

    &.list{
      h1{
        @media (max-width: 992px) {
          font-size: 25px;
          line-height: 32px;
        }
      }
    }

    &--logos{
      img{
        margin-bottom: 24px;
        margin-right: 26px;

        &:last-child{
          margin-right: 0;
        }

        width: 100%;
        height: auto;

        &[data-brand='weber']{
          max-width: 114px;
        }
        &[data-brand='glava']{
          max-width: 69px;
        }
        &[data-brand='gyproc']{
          max-width: 100px;
        }

        @media (max-width: 992px) {
          &[data-brand='weber']{
            max-width: 88px;
          }
          &[data-brand='glava']{
            max-width: 61px;
          }
          &[data-brand='gyproc']{
            max-width: 72px;
          }
          margin-right: 16px;
          margin-bottom: 20px;
        }


      }
    }
    &--textArea{
      font-family: $font-regular;
      font-weight: 500;
      font-size: 21px;
      line-height: 34px;
      letter-spacing: 0.24px;
      color: #121212;
      padding-bottom: 20px;
      width: 100%;

      @media (max-width: 992px) {
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 12px;
      }

      &.login{
        max-width: 71%;
        @media (max-width: 992px) {
          max-width: 100%;
        }
      }

      &.list{
        padding-bottom: 80px;
        @media (max-width: 992px) {
          padding-bottom: 20px;
        }
      }

      p{
        margin-bottom: 20px;
        @media (max-width: 992px) {
          margin-bottom: 20px;
        }
      }

    }
  }
  &__loginWrapper{
    &--title{
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
      h3{
        font-family: $font-regular;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.24px;
        color: #333333;
        margin-bottom: 16px;
      }
    }
    &--inputWrapper{
      margin-bottom: 36px;
      position: relative;

      @media (max-width: 992px) {
        margin-bottom: 26px;
      }

      input::-ms-clear {
        display: none;
        height: 0;
        width: 0;
      }

      ::-webkit-input-placeholder {
        color: #3a3c45;
      }
      :-ms-input-placeholder {
        color: #3a3c45;
      }
      ::placeholder {
        color: #3a3c45;
      }

      input {
        background-color: #f7f7f7;
        padding: 20px 24px 20px 24px;
        height: auto;
        width: 100%;
        border: none;
        border-radius: 0 !important;
        border-bottom: 1px solid #3A3C45;
        font-family: $font-regular;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.24px;
        color: #333333;

        &.incorrect{
          border-bottom: 1px solid red;
        }

        @media (max-width: 992px) {
          padding: 18px 16px 18px 16px;
          font-size: 16px;
          line-height: 24px;
          border-bottom: 2px solid #3A3C45;
        }

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

    }
    &--btnWrapper{
      button{
        font-family: $font-regular;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.16px;
        color: #333333;
        border: none;
        padding: 12px 40px;
        background: #FFE66F;
        border-radius: 24px;
        &:active,
        &:focus{
          outline: none;
          border: none;
        }
        @media (max-width: 992px) {
          padding: 8px 28px;
          font-size: 13px;
          line-height: 24px;
        }
      }
      .login-failed-message{
        position: relative;
        p{
          margin-top:10px;
          color: red;
          font-size: 14px;
          line-height: 14px;
          font-family: $font-regular;
          font-weight: 500;
          @media (max-width: 992px) {
            font-size: 13px;
            line-height: 13px;
          }
        }
      }
    }
  }

  &__listWrapper{



    &--brand{

      margin-bottom: 80px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      &:last-child{
        margin-bottom: 0px;
      }

      .title{
        font-family: $font-regular;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #333333;
        padding-bottom: 24px;
        border-bottom: 1px solid #D9DBE3;

        @media (max-width: 992px) {
          font-size: 20px;
          line-height: 28px;
          padding-bottom: 16px;
        }

      }

      .items{

        .item {
          padding: 20px 10px;
          font-family: $font-regular;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.24px;
          color: #333333;
          border-bottom: 1px solid #d9dbe3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          @media (max-width: 992px) {
            padding: 16px 10px;
            display: block;
            justify-content: initial;
            align-items: initial;
            font-size: 14px;
            line-height: 20px;
            padding-left: 33px;
          }
          a {
            color: #4c80a4;
            text-decoration: underline;
            font-size: 16px;
            font-weight: 600;
            padding-left: 25px;
            position: relative;
            @media (max-width: 992px) {
              position: initial;
              font-size: 14px;
              padding-left: 0;
            }
            &:before {
              @include background-image("icons/PDF-icon.svg", 16, 22);
              top: 0;
              left: 0;
              position: absolute;
              @media (max-width: 992px) {
                @include background-image("icons/PDF-icon.svg", 20, 27.5);
                top: 20px;
                left: 0px;
              }
            }
          }
          span {
            color: #333333;
            padding-right: 30px;
            width: 100%;
            max-width: 65%;
            word-break: break-word;
            @media (max-width: 1200px) {
              max-width: 55%;
              padding-right: 20px;
            }
            @media (max-width: 992px) {
              max-width: 100%;
              padding-right: 0px;
            }
          }
          div {
            width: 190px;
            @media (max-width: 992px) {
              max-width: 100%;
            }
            small{
              display: inline-block;

            }
          }
          small {
            font-size: 14px;
            line-height: 32px;
            font-weight: 500;
            margin-left: 8px;
            color: #333333;
            @media (max-width: 992px) {
              font-size: 12px;
              line-height: 16px;
              margin-left: 16px;
            }
          }
        }

      }

    }

  }


}

