.selectAktuelt{
    &__wrapper{
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 10px;
        @media (max-width: 992px) {
          flex-direction: column;
          margin-top: 0;
          margin-bottom: 0;
        }

        .bootstrap-select {
            width: 100% !important;
            max-width: 349px !important;
            margin-top: -10px;
            margin: 0 20px;
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }

            @media (max-width: 992px) {
              width: 100% !important;
              max-width: 100% !important;
              margin: 0;
            }

            > .dropdown-toggle {
              &:after {
                display: none;
              }
              .filter-option-inner-inner {
                color: #333333;
              }
            }
            .dropdown-menu {
              border-radius: 0 !important;
              top: -3px !important;
              .dropdown-item {
                padding: 13px 1.5rem;
                line-height: 100%;
                word-break: break-all;
                white-space: normal;
                &.active,
                &:active {
                    background-color: transparent !important;
                    border-color: transparent !important;
                    color: $glava-black;
                    @include site(weber){
                      color: $weber-black;
                    }

                    @include site(gyproc){
                      color: $gyproc-black;
                    }
                }
              }
            }
            select {
              -moz-appearance: none; /* Firefox */
              -webkit-appearance: none; /* Safari and Chrome */
              appearance: none;
            }
            button {
              background-color: transparent !important;
              border-top: 0;
              border-right: 0;
              border-left: 0;
              border-bottom: 1px solid #c4c4c4;
              border-radius: 0;
              box-shadow: none;
              background-image: url("../images/icons/arrow-down.svg");
              background-repeat: no-repeat;
              background-size: 16px 10px;
              background-position: center right;
              font-weight: 600;
              @include font-size(17);
              line-height: 19px;
              letter-spacing: 0.24px;
              padding: 20px 8px;
              color: #333333;
              &:focus {
                outline: none !important;
              }
              @media (max-width: 992px) {
                @include font-size(14);
                line-height: 16px;
                padding: 15px 8px;
              }
            }
            option {
              font-weight: 600;
              font-size: 17px;
              line-height: 24px;
              letter-spacing: 0.24px;
              color: #333333;
            }
            *:focus,
            *:active {
              outline: 0 !important;
            }

        }
    }
}
