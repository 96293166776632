//-------------------------------//
// OLD FILTERBOX (MICHEL's code)
//-------------------------------//
.filterBox{
  display: flex;
  flex-flow: wrap;
  padding-bottom: 12px;

  @media(max-width: 992px){
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 100%;
  }
}

.checkbox-group{
  position: relative;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .check{
    width: 32px;
    height: 32px;
    display: inline-block;
    border: 1px solid #CCCDCF;
    margin-right: 16px;
  }
  input[type=checkbox]{
    width: 100%;
    height: 32px;
    opacity: 0;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    &:checked{

      &+.check{
        background-image: url($path+"icons/checked.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 10px;
        border: 1px solid #373838;
        &+label{
          font-weight: 600;
        }
      }
    }
  }
  label{
    font-family: $font-regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.16px;
    color: #1F1F1F;
    word-break: break-all;
    flex-basis: calc(100% - 48px);
  }
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  width: auto;
  min-width: 130px;
  margin:0 16px 16px 0;

  @media(max-width: 992px){
    width: 100%;
  }
  select {
    display: none; /*hide original SELECT element: */
  }
  .select-selected {
    color: #2B2B2C;
    padding: 10px 40px 11px 16px;
    cursor: pointer;
    border: 1px solid #BBBBC2;
    background-color: #FFFFFF;
    font-family: $font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAxLjU2ODU4TDEuMDgwOTMgMC41TDYgNS4zNjI4NEwxMC45MTkxIDAuNUwxMiAxLjU2ODU4TDYgNy41TDAgMS41Njg1OFoiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc(100% - 16px) center;
    &:hover, &:focus{
      background-color: #F7F7FA;
      outline: none;
    }

    &.select-arrow-active{
      background-color: #F7F7FA;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FtYWRhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMTIgOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6IzMzMzMzMzt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLDYuNGwtMS4xLDEuMUw2LDIuNkwxLjEsNy41TDAsNi40bDYtNS45TDEyLDYuNHoiLz4NCjwvc3ZnPg0K);
      div{
        color: #ffffff;
        padding: 8px 16px;
        border: 1px solid transparent;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
        cursor: pointer;
      }
    }
  }

  /* Style items (options): */
  .select-items {
    width: 360px;
    position: absolute;
    background-color: #FFFFFF;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    margin-top: 8px;
    height: auto;
    padding: 24px 24px 0 24px;
    background: #FFFFFF;
    border: 1px solid #A7A8AB;
    box-sizing: border-box;
    box-shadow: 0px 12px 20px 5px rgba(0, 0, 0, 0.2);
    @media(max-width: 992px){
      width: 100%;
    }
    &__wrapper{
      max-height: 325px;
      overflow: auto;
      .checkbox-group{
        margin-bottom: 16px;
        .check{
          width: 28px;
          height: 28px;
        }
        label{
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.24px;
          color: #1F1F1F;
          word-break: break-word;
        }
      }
      .same-as-selected{
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    &__buttons{
      display: flex;
      justify-content: space-between;
      height: 87px;
      padding: 20px 0;
      box-sizing: border-box;
      @media(max-width: 992px){
        flex-direction: column;
        height: auto;
      }
      button{
        height: 44px;
        max-width: 148px;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.16px;
        color: #333333;
        @media(max-width: 992px){
          margin-bottom: 10px;
          max-width: 100%;
        }
      }
    }
  }

  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }
}

.filterOptions{
  display: flex;
  align-items: flex-start;

  @media(max-width: 992px){
    margin-bottom: 30px;
    flex-direction: column;
  }

  button.clearFilter{
    margin-bottom: 28px;
    background-color: #F7F7FA;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #1F1F1F;
    width: 135px;
    height: 44px;
    border: 0;
    padding: 10px 20px;
    white-space: nowrap;

    @media(max-width: 992px){
      width: 100%;
    }
  }
}
//------ END OLD FILTER BOX


// NEW FILTERBOX (ANTONIO's code)
.filterOptions{
  justify-content: space-between;

  .filterBox{

    &.active-mobile{
      @media(max-width: 992px){
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        padding: 95px 0 0;
        background: #FFFFFF;
        overflow: auto;
        z-index: 9;
      }
    }

    .wrapper{
      display: flex;
      flex-flow: wrap;

      @media(max-width: 992px){
        padding-bottom: 85px;
      }

      .custom-select{
        @media(max-width: 992px){
          width: 100%;
          margin: 0;
        }

        @media(max-width: 576px){
        }

        &.custom-select--mobile{
          display: none;

          @media (max-width: 992px) {
            display: block;
          }
        }

        .select-selected{
          &.select-arrow-active{
            font-weight: 600;
            letter-spacing: -0.07px;
          }

          @media(max-width: 992px){
            padding: 15px 40px 15px 20px;
            color: #2A2A2A;
            border: none;
            background-position: calc(100% - 23px) center;
            border-bottom: 1px solid #CCCDCF;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.12px;
            font-weight: 600;

            &:hover{
              background-color: #FFFFFF;
            }

            &.select-arrow-active{
              background-color: #FFFFFF;
              border-bottom: none;
            }
          }
        }

        .select-items{
          padding: 24px 10px 0 24px;

          @media(max-width: 992px){
            position: relative;
            top: 0;
            padding: 0 20px 10px;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #CCCDCF;
          }

          .select-items__wrapper{
            @media(max-width: 992px){
              max-height: none;
              overflow: auto;

              .checkbox-group{
                .check{
                  width: 24px;
                  height: 24px;
                }

                input[type=checkbox]{
                  &:checked{
                    &+.check{
                      &+label{
                        font-weight: 500;
                      }
                    }
                  }
                }

                label{
                  font-size: 15px;
                }
              }
            }
          }

          .select-items__buttons{
            margin-left: -24px;
            margin-right: -10px;
            justify-content: center;
            border-top: 1px solid #F0F1F1;

            @media(max-width: 992px){
              display: none;
            }

            button{
              &:first-child{
                margin-right: 16px;
              }
            }
          }
        }
      }

      .filterBox__footer{
        display: none;
        align-items: center;
        justify-content: center;
        height: 87px;
        margin-top: auto;
        padding: 0 20px;
        background-color: #FFFFFF;
        border-top: 1px solid #F0F1F1;

        @media(max-width: 992px){
          display: flex;
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 99;
          left: 0;
        }

        button{
          &:first-child{
            margin-right: 13px;
          }
        }
      }
    }

  }

  .filterOptions__buttons{
    display: flex;
    @media(max-width: 992px){
      width: 100%;
    }

    button.showFilter{
      display: none;
      width: 100%;
      min-width: 135px;
      margin-bottom: 0px;
      padding: 14px 16px;
      background-color: #FFE66F;
      color: #2B2B2C;
      border: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.24px;
      white-space: nowrap;

      @media(max-width: 992px){
        display: block;
      }
    }

    button.clearFilter{
      @media(max-width: 992px){
        display: none;
        width: 40%;
        height: inherit;
        margin-bottom: 0;
        margin-left: 16px;
        padding: 14px 16px;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.24px;
        color: #2B2B2C;
        white-space: normal;
      }
    }

    &.buttons--clear{
      button.showFilter{
        @media (max-width: 768px) {
          width: 60%;
        }

        @media (max-width: 375px) {
          width: 55%;
        }
      }
      button.clearFilter{
        display: block;

        @media (max-width: 768px) {
          width: 40%;
        }

        @media (max-width: 375px) {
          width: 45%;
        }
      }
    }
  }
}

.checkbox-group{
  &.disabled{
    opacity: 0.3;
    pointer-events: none;
  }
}

.filterBox__close-btn{
  position: fixed;
  top: 26px;
  right: 20px;
  padding: 10px 0px 10px 70px;
  background: white;
  z-index: 99;

  button{
    background-color: white;
    border: none;
    @include background-image("./icons/close-pop-up.svg", 18, 16);
  }
}

// Custom scrollbar (Chrome and Safari)
.custom-select .select-items__wrapper{
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #DDDDDD;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cacaca;
  }
}
