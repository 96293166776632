.products-block {

  margin-bottom: 110px;
  & * {
    color: #333333;
    &:first-child > .products-block__item {
      border-top: 1px solid #D9DBE3;
    }
  }
  &__item {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0;
    border-bottom: 1px solid #D9DBE3;
    width: 100%;
    display: flex;
    align-items: center;
      // Alternative to duble lines
    // margin-top: -1px;
    // border-top: 1px solid #D9DBE3;
    &:hover, a:hover {
      color: inherit;
      text-decoration: none;
    }
    .img {
      width: 93px;
      height: 73px;
      margin-right: 32px;
    }
  }
}
