.text-and-media {
  margin: 80px 0;
  @media (max-width: 991px) {
    margin: 46px 0 52px;
  }
  .row +.row {
    margin-top: 56px;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
  &__img {
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 991px) {
      height: unset;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }
  &__content {
    padding: 0 14.28%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100%;
    @media (max-width: 991px) {
      padding: 12px 0 0;
      min-height: unset;
    }
    h2 {
      @include font-size(36);
      line-height: 1.33;
      letter-spacing: normal;
      margin: 0;
      @media (max-width: 991px) {
        @include font-size(22);
        line-height: 1.27;
      }
    }
    p {
      @include font-size(18);
      line-height: 1.89;
      letter-spacing: 0.01em;
      margin-top: 16px;
      color: $gray-800;
      @media (max-width: 991px) {
        @include font-size(17);
        line-height: 1.53;
        margin-top: 6px;
      }
    }
    .button {
      margin-top: 28px;

      @media (max-width: 991px){
        // same as ".button--small"
        font-size: 16px;
        padding: 12px 20px;
      }
    }
  }
  &--vertical {
    .col-lg-6 {
      @media (min-width: 992px) {
        &:not(:nth-child(1)):not(:nth-child(2)) {
          margin-top: 56px;
        }
      }
      @media (max-width: 991px) {
        &:not(:nth-child(1)) {
          margin-top: 40px;
        }
      }
    }
    @media (min-width: 992px) {
      .text-and-media {
        &__img {
          height: auto;
          margin-bottom: 32px;
          padding: 0;
        }
        &__content {
          align-items: center;
          height: auto;
          padding: 0;
          text-align: center;
          min-height: unset;
        }
      }
    }
  }
}
