.insulation__header {
  z-index: 15;
  margin-bottom: 0 !important;
}

.insulation__nav {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  color: black;
  padding: 48px 140px;

  @media (max-width: 1400px) {
    justify-content: flex-start;
    gap: 49px;
    padding: 48px 0 48px 140px;
  }

  @media (max-width: 1200px) {
    padding: 18px 0 0;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fd0;
    gap: 0px;
  }

  &--bg-yellow {
    position: relative;
    background-color: #ffdd00;
  }

  .nav__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  .menu-logo {
    @media (max-width: 1200px) {
      margin-left: 30px;
    }

    img {
      height: 105px;

      @media (max-width: 1200px) {
        height: 35px;
      }
    }
  }

  .navbar {
    @media (max-width: 1200px) {
      margin-right: 25px;
    }
  }

  .navbar-nav {
    display: flex;
    gap: 49px;


    @media (max-width: 1400px) {
      gap: 29px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      padding-top: 55px;
      padding-left: 25px;
      padding-right: 25px;
      gap: 22px;
      height: calc(100dvh - 71px);
      width: 100dvw;
      background-color: #fd0;
    }
  }

  .nav-link {
    position: relative;
    color: #000000;
    font-size: 26px;
    text-decoration: none;
    box-sizing: content-box;

    &:hover {
      color: #000000;
      box-shadow: 0 2px 0 #000000;
    }

    span {

      position: relative;

      // &::before {

      //   @media (max-width: 1200px) {
      //       content: "";
      //       display: block;
      //       position: absolute;
      //       width: 100%;
      //       height: 2px;
      //       background-color: #000;
      //       top: 100%;

      //   }
      // }
    }

    @media (max-width: 1200px) {
      width: fit-content;
      gap: 14px;
      font-size: 34px;
      line-height: 40px;
      font-weight: 600;
      // border-bottom: 2px solid #000000;
      padding: 0;
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 2px;

      &:hover {
        box-shadow: none;
      }
    }

    @media (max-width: 325px) {
      font-size: 28px;
      line-height: 38px;

    }
  }

  .active {
    box-shadow: 0 2px 0 #000000;

    @media (max-width: 1200px) {
      box-shadow: none;
    }
  }

  .insulation__hamburguer {
    width: 21.28px;
    height: 23.28px;
    position: relative;
    display: none;

    @media (max-width: 1200px) {
      width: 16px;
      height: 17px;
      display: inline-block;
      margin-right: 30px
    }

    .insulation__menu {
      z-index: 200;
      position: absolute;
      top: 0;
      right: 0;
      width: 21.28px;
      height: 23.28px;
      cursor: pointer;
    }

    .insulation__bar,
    .insulation__bar:after,
    .insulation__bar:before {
      width: 21.28px;
      height: 2px;
    }

    .insulation__bar {
      position: relative;
      transform: translateY(8px);
      background: rgba(255, 255, 255, 1);
      transition: all 0ms 300ms;

      @media (max-width: 1200px) {
        background-color: #000;
      }
    }

    .insulation__bar.animate {
      background: rgba(255, 255, 255, 0);
    }

    .insulation__bar:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 7px;
      background: rgba(255, 255, 255, 1);
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);

      @media (max-width: 1200px) {
        background-color: #000;
      }
    }

    .insulation__bar:after {
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
      background: rgba(255, 255, 255, 1);
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);

      @media (max-width: 1200px) {
        background-color: #000;
      }
    }

    .insulation__bar.animate:after {
      top: 0;
      transform: rotate(45deg);
      transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      ;
    }

    .insulation__bar.animate:before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      ;
    }
  }
}


// collapse bootstrap

.insulation__collapse {
  @media (max-width: 1200px) {

    &:not(.show) {
      display: none;
    }

    .show {
      display: block;
    }
  }
}
