.product-page{
  &__header{
    .product-image{
      margin-bottom: 60px;

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

      figure{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D2D3D5;
        padding: 65px 132px;

        @media (max-width: 992px) {
            padding: 28px;
        }

        img{
          max-width: 437px;
          max-height: 420px;

          @media (max-width: 992px) {
            max-width: 100%;
            max-height: 270px;
          }
        }
      }
    }

    .product-description{
      margin-bottom: 60px;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      .wrapper{
        border: 1px solid #D2D3D5;
        padding: 52px 60px 68px;

        @media (max-width: 1200px) {
          padding: 30px;
        }

        @media (max-width: 992px) {
          border: none;
          padding: 0;
        }

        .product-name{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 23px;

          @media (max-width: 992px) {
            flex-direction: column-reverse;
            justify-content: flex-start;
            margin-bottom: 28px;
          }

          h1{
            padding-right: 52px;
            font-size: 36px;
            line-height: 48px;
            letter-spacing: -0.44px;
            font-family: $font-regular;
            color: #121212;

            @media (max-width: 1200px) {
              padding-right: 30px;
            }

            @media (max-width: 992px) {
              padding-right: 0;
              font-size: 28px;
              line-height: 32px;
              letter-spacing: -0.005em;
            }
          }

          img{
            min-width: 100px;
            width: 100px;
            height: 48px;
            object-fit: contain;

            @media (max-width: 1200px) {
              min-width: 80px;
              width: 80px;
            }

            @media (max-width: 992px) {
              margin-bottom: 16px;
            }
          }
        }

        .description-dropdown{
          margin-bottom: 24px;

          @media (max-width: 992px) {
            margin-bottom: 20px;
          }

          &:last-child{
            margin-bottom: 0;

            .dropdown-content{
              padding-bottom: 0;
              border-bottom: none;
            }
          }

          &.active{
            .dropdown-title{
              &::after{
                -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
              }
            }
          }

          .dropdown-title{
            position: relative;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            font-size: 18px;
            line-height: 32px;
            font-weight: 600;

            @media (max-width: 992px) {
              font-size: 15px;
              line-height: 24px;
            }

            &::after{
              content: "";
              display: inline-block;
              margin-left: 8px;
              width: 12px;
              height: 12px;
              background-image: url("../images/./icons/arrow-up-squared.svg");
              background-repeat: no-repeat;
              background-size: contain;
              transition: transform 0.2s;
              -webkit-transform: rotateZ(180deg);
              transform: rotateZ(180deg);

              @media (max-width: 992px) {
                width: 10px;
                height: 10px;
              }
            }
          }

          .dropdown-content{
            display: none;
            margin-top: 4px;

            p{
              margin-bottom: 1em;
              font-size: 18px;
              line-height: 32px;

              @media (max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
              }

              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  // product tabs
  .product-tabs{
    &__links{
      display: block;
      position: sticky;
      position: -webkit-sticky;
      top: -1px;
      margin-bottom: 88px;
      z-index: 1;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      .color-background{
        background-color: #F1F2F4;
        transition: transform 300ms ease-in-out;

        @media (max-width: 992px) {
          background-color: transparent;
        }

        .container{
          @media (max-width: 992px) {
            max-width: 100%;
          }

          .row{
            .col-12{
              @media (max-width: 992px) {
                padding: 0;
              }

              .wrapper{
                display: flex;
                flex-direction: row;
                transition: max-height 300ms ease-in-out;

                @media (max-width: 992px) {
                  flex-direction: column;
                  max-height: 61px; // button height
                  overflow: hidden;
                }

                button{
                  flex: 1;
                  padding: 12px;
                  background-color: transparent;
                  border: none;

                  @media (max-width: 992px) {
                    padding: 18px;
                    min-height: 61px;
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    background: #F1F2F4;
                  }

                  &:hover{
                    background-color: #E0E1E4;
                  }

                  &.active{
                    position: relative;
                    background-color: #3A3C45;

                    @media (max-width: 992px) {
                      order: -1;
                      width: calc(100% - 40px);
                      margin: 0 auto;
                    }

                    &::before{
                      @media (max-width: 992px) {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: -20px;
                        width: 100vw;
                        height: 100%;
                        background-color: #F1F2F4;
                        z-index: -1;
                      }
                    }

                    span{
                      color: white;

                      @media (max-width: 992px) {
                        position: relative;

                        &::after{
                          content: "";
                          position: absolute;
                          right: -22px;
                          top: calc(50% - 6px);
                          display: block;
                          width: 12px;
                          height: 12px;
                          background-image: url("../images/./icons/arrowItemMenuDropDown.svg");
                          background-repeat: no-repeat;
                          background-size: contain;
                          background-position: center;
                          -webkit-transform: rotateZ(90deg);
                          transform: rotateZ(90deg);
                          transition: transform 300ms ease-in-out;
                        }
                      }
                    }
                  }

                  span{
                    color: #121212;
                    font-size: 16px;
                    line-height: 28px;
                    font-weight: 600;

                    @media (max-width: 992px) {
                      font-size: 15px;
                      line-height: 24px;
                    }
                  }
                }

                &--show-options{
                  max-height: 360px; // a value bigger than the box will ever get

                  button{
                    &.active{
                      span{
                        &::after{
                          -webkit-transform: rotateZ(-90deg);
                          transform: rotateZ(-90deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.is-sticky{
        z-index: 9;

        &.has-fixed-header{
          pointer-events: none;
          top: -7px;

          .color-background{
            pointer-events: all;
            transform: translate(0px,81px);

            @media (max-width: 992px) {
              transform: translate(0px,78px);
            }
          }

          @media (max-width: 744px) {
            top: -18px;
          }
        }
      }
    }

    &__category-title{
      margin-bottom: 32px;

      h2{
        color: #121212;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.005em;
      }
    }

    &__tabs{
      .tabcontent{
        display: none;
        margin-bottom: 88px;

        @media (max-width: 992px) {
          margin-bottom: 40px;
        }

        &.active{
          display: block;
        }

        &#description-tab{
          .content-block{
            margin-bottom: 60px;
            word-break: break-word;

            @media (max-width: 992px) {
              margin-bottom: 40px;
            }

            h1,h2,h3,h4,h5,h6{
              margin-bottom: 16px;
              color: #121212;

              @media (max-width: 992px) {
                margin-bottom: 12px;
              }
            }

            h1,h2{
              font-size: 36px;
              line-height: 48px;
              letter-spacing: -0.015em;

              @media (max-width: 992px) {
                font-size: 19px;
                line-height: 26px;
                letter-spacing: 0;
              }
            }

            h3{
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.005em;

              @media (max-width: 992px) {
                font-size: 16px;
                line-height: normal;
                letter-spacing: 0;
              }
            }

            h4{
              font-size: 24px;
              line-height: 32px;
              letter-spacing: -0.005em;

              @media (max-width: 992px) {
                font-size: 16px;
                line-height: normal;
                letter-spacing: 0;
              }
            }

            p{
              margin-bottom: 16px;
              color: #212121;
              font-size: 18px;
              line-height: 32px;

              @media (max-width: 992px) {
                margin-bottom: 12px;
                font-size: 15px;
                line-height: 24px;
              }

              &:last-child{
                margin-bottom: 0;
              }
            }

            ul, ol{
              margin-bottom: 16px;
              padding-left: 18px;

              li{
                margin-bottom: 12px;
                padding-left: 6px;
                color: #212121;
                font-size: 18px;
                line-height: 32px;

                @media (max-width: 992px) {
                  padding-left: 0;
                  font-size: 15px;
                  line-height: 24px;
                }

                &:last-child{
                  margin-bottom: 0;
                }
              }
            }

            ol{
              li{
                padding-left: 16px;

                @media (max-width: 992px) {
                  padding-left: 12px;
                }
              }
            }

            a{
              border: none;
              text-decoration: underline;
              color: #22B3D2;
            }
          }
        }

        &#documentation-tab{
          .doc-list{
            list-style: none;

            li{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin-right: 40px;
              margin-bottom: -1px;
              padding: 16px 20px 16px 40px;
              border: 1px solid #D2D3D5;
              border-bottom: none;

              @media(max-width: 992px){
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 20px;
                border-color: #E5E6E8;
              }

              &:last-child{
                border-bottom: 1px solid #D2D3D5;
              }

              h4{
                margin-right: 40px;
                font-size: 17px;
                line-height: 24px;
                font-weight: 600;

                @media(max-width: 992px){
                  margin-right: 0;
                  margin-bottom: 8px;
                  font-size: 13px;
                  line-height: 20px;
                }
              }

              .buttons{
                display: flex;
                flex-direction: row;
                padding: 4px 0;

                @media(max-width: 992px){
                  padding: 0;
                }

                .btn{
                  justify-content: center;
                  padding: 7px 20px;
                  border: 1px solid $glava-black;
                  border-radius: 100px;
                  font-size: 15px;
                  line-height: 24px;
                  font-weight: 600;
                  white-space: nowrap;
                  transition: none;

                  @media(max-width: 992px){
                    width: auto;
                    margin: 0 auto;
                    padding: 4px 12px 5px;
                    font-size: 11px;
                    line-height: 16px;
                    font-weight: 500;
                  }

                  &:first-child{
                    margin-right: 12px;

                    @media(max-width: 992px){
                      margin-right: 6px;
                    }
                  }

                  &--share{
                    background-color: #F3F4F5;
                    border-color: transparent;

                    &:hover{
                      text-decoration: underline;
                    }
                  }

                  &--download{
                    @include site(weber){
                      border: 1px solid $weber-black;
                    }

                    @include site(gyproc){
                      border: 1px solid $gyproc-black;
                    }

                    &:hover{
                      color: $glava-black;
                      box-shadow:0px 0px 0px 1px $glava-black;

                      @include site(weber){
                        box-shadow:0px 0px 0px 1px $weber-black;
                      }

                      @include site(gyproc){
                        box-shadow:0px 0px 0px 1px $gyproc-black;
                      }
                    }
                  }

                  &:focus-visible{
                    outline: 2px solid;
                  }
                }
              }

              > a{
                width: 108px;
                height: 36px;
                border-radius: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.16px;
                color: #333333;
                border-bottom: none;

                @media(max-width: 992px){
                  width: 100%;
                  font-size: 13px;
                }

                &.copy{
                  width: 226px;
                  height: 36px;
                  background: #333333;
                  color: #FFFFFF;
                  border-radius: 0;
                  text-decoration: none;
                  &:hover{
                    text-decoration: none;
                  }
                  @media(max-width: 992px){
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        &#table-tab{
          .card-body{
            padding: 0;
          }
        }

        &#related-products-tab{
          .list-wrapper{
            .product-list{
              &__item{
                width: 25%;

                @media(max-width: 992px){
                  width: 33.33%;
                }

                @media(max-width: 768px){
                  width: 50%;
                }
              }
            }
          }
        }

        &#related-solutions-tab{
          .list-wrapper{
            .solution-list__list{
              .solution-list__item{
                width: 25%;

                @media(max-width: 992px){
                  width: 33.33%;
                }

                @media(max-width: 768px){
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
